import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { Field, useField, Form } from 'react-final-form';
import { handleFirstLogin } from 'redux/actions/login.actions';
import { getLocalStorage, clearLocalStorage } from 'utils/helpers/localStorage';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

// Custom Component
// eslint-disable-next-line react/prop-types
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

// eslint-disable-next-line react/prop-types
function CustomRadioGroup({ name, label, children }) {
  return (
    <Box>
      <Typography variant="body1" component="legend">
        {label}
      </Typography>
      <RadioGroup>{children}</RadioGroup>
      <Field name={name} render={({ meta }) => <FormControl>{showError(meta)}</FormControl>} />
    </Box>
  );
}

function Student() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { schools } = useSelector((state) => state.login, shallowEqual);

  const userLogin = getLocalStorage('userLogin');

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) errors.firstName = 'Wajib diisi.';
    if (!values.gender) errors.gender = 'Wajib diisi.';
    if (!values.school) errors.school = 'Wajib diisi';

    return errors;
  };

  const handleLogout = () => {
    clearLocalStorage();
    navigate('/');
  };

  const onSubmit = async (values) => {
    dispatch(handleFirstLogin(values));
  };

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ my: 6 }}>
              <Paper elevation={1} sx={{ borderRadius: 2, p: 6 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Lengkapi Data Diri</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      defaultValue={userLogin.firstName}
                      render={({ input, meta }) => (
                        <TextField
                          label="First Name"
                          disabled
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="lastName"
                      defaultValue={userLogin.lastName}
                      render={({ input, meta }) => (
                        <TextField
                          label="Last Name"
                          disabled={userLogin?.lastName}
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomRadioGroup name="gender" label="Jenis Kelamin">
                      <CustomRadio name="gender" value="M" label="Laki-Laki" />
                      <CustomRadio name="gender" value="F" label="Perempuan" />
                    </CustomRadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="school"
                      render={({ input }) => (
                        <FormControl fullWidth>
                          <InputLabel id="school-label">Sekolah</InputLabel>
                          <Select
                            labelId="school-label"
                            id="school-select"
                            {...input}
                            label="Sekolah"
                          >
                            {schools?.map((item) => (
                              <MenuItem value={item.school_id} key={item.school_id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="grade"
                      render={({ input, meta }) => (
                        <TextField
                          label="Kelas"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="absentNumber"
                      render={({ input, meta }) => (
                        <TextField
                          label="No. Absen"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={13}>
                    <Field
                      name="address"
                      render={({ input, meta }) => (
                        <TextField
                          label="Alamat"
                          fullWidth
                          multiline
                          minRows={3}
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      size="large"
                      onClick={handleLogout}
                      sx={{ mr: 3 }}
                    >
                      Batal
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                      Kirim
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </form>
        )}
      />
    </Container>
  );
}

export default Student;
