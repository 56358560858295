import petaDiyImage from 'assets/images/img/petaDiy.jpg';
import merapiImage from 'assets/images/img/merapi.jpg';
import tasSiagaImage from 'assets/images/img/tas-siaga.png';

const content = [
  {
    title: 'Pre-Test',
    path: '0',
    content: `
    <p>Sebelum mengakses materi silahkan mengerjakan Pretest dengan menekan link berikut : <a href="https://docs.google.com/forms/d/1nsRGb-vC76ed464WYnnp-Y7XcYsiYmo3yriQXHzu45I/edit" target="blank">Pre-Test</a></p>
    `
  },
  {
    title: 'Pengertian Bencana',
    path: '1',
    content: `<p>Bencana adalah peristiwa atau rangkaian peristiwa yang mengancam dan mengganggu kehidupan dan penghidupan masyarakat yang disebabkan, baik oleh faktor alam dan/atau faktor nonalam maupun faktor manusia sehingga mengakibatkan timbulnya korban jiwa manusia, kerusakan lingkungan, kerugian harta benda, dan dampak psikologis.</p>`,
    attachment: [
      {
        id: 'aso',
        title: 'UU 24 Tahun 2007',
        url: 'https://drive.google.com/file/d/1lESluLyhDwmr_D2n-K-Wz9V6qaQhILux/view?usp=sharing'
      }
    ],
    child: [
      {
        id: 'jabeu',
        subtitle: 'Video 1',
        embedId: 'yAJxZADUtxA'
      },
      {
        id: 'jandu',
        subtitle: 'Video 2',
        embedId: 'qjtEmeOYi30'
      },
      {
        id: 'oaasd',
        subtitle: 'Video 3',
        embedId: '1Ess9QudIEE'
      }
    ]
  },
  {
    title: 'Jenis Bencana',
    path: '2',
    content: `
    <table class="tg">
    <thead>
      <tr>
        <th class="tg-0lax">Definisi</th>
        <th class="tg-0lax">Contoh</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-0lax">Bencana alam adalah bencana yang diakibatkan oleh peristiwa atau serangkaian peristiwa yang disebabkan oleh alam (UU 24 Tahun 2007 Pasal 1)</td>
        <td class="tg-0lax">gempa bumi, tsunami, erupsi gunung api, banjir, kekeringan, angin topan, dan tanah longsor.</td>
      </tr>
      <tr>
        <td class="tg-0lax">Bencana nonalam adalah bencana yang diakibatkan oleh peristiwa atau rangkaian peristiwa nonalam (UU 24 Tahun 2007 Pasal 1)</td>
        <td class="tg-0lax">gagal teknologi, gagal modernisasi, epidemi, dan wabah penyakit.</td>
      </tr>
      <tr>
        <td class="tg-0lax">Bencana sosial adalah bencana yang diakibatkan oleh peristiwa atau serangkaian peristiwa yang diakibatkan oleh manusia  (Pasal 1)</td>
        <td class="tg-0lax">konflik sosial antarkelompok atau antarkomunitas masyarakat, dan teror.</td>
      </tr>
    </tbody>
    </table>
    `,
    attachment: [
      {
        id: 'aso',
        title: 'UU 24 Tahun 2007',
        url: 'https://drive.google.com/file/d/1lESluLyhDwmr_D2n-K-Wz9V6qaQhILux/view?usp=sharing'
      }
    ],
    child: [
      {
        id: 'ahsdjnoiu',
        subtitle: 'Video 1',
        embedId: 'qjtEmeOYi30'
      }
    ]
  },
  {
    title: 'Jenis Ancaman DIY',
    path: '3',
    content: `
      <table class="tg">
        <thead>
        <tr>
          <th class="tg-0pky" rowspan="2">No</th>
          <th class="tg-0pky" colspan="3">Potensi Bencana Daerah Istimewa Yogyakarta</th>
          <tr>
            <th class="tg-0lax">Peraturan Kepala BNPB No. 2 Tahun 2012</th>
            <td class="tg-0lax">Peraturan Gubernur Daerah Istimewa Yogyakarta No. 65 Tahun 2020</td>
            <td class="tg-0lax">Kajian IRBI Daerah Istimewa Yogyakarta Tahun 2021</td>
          </tr>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="tg-0pky">1</td>
          <td class="tg-0pky">Gempa Bumi </td>
          <td class="tg-0lax">Banjir</td>
          <td class="tg-0lax">Tanah Longsor</td>
        </tr>
        <tr>
          <td class="tg-0pky">2</td>
          <td class="tg-0pky">Banjir</td>
          <td class="tg-0lax">Gempa Bumi</td>
          <td class="tg-0lax">Gelombang Pasang/Abrasi</td>
        </tr>
        <tr>
          <td class="tg-0pky">3</td>
          <td class="tg-0pky">Tanah Longsor</td>
          <td class="tg-0lax">Tanah Longsor</td>
          <td class="tg-0lax">Banjir</td>
        </tr>
        <tr>
          <td class="tg-0lax">4</td>
          <td class="tg-0lax">Erupsi Gunung Api</td>
          <td class="tg-0lax">Kekeringan</td>
          <td class="tg-0lax">Gempa Bumi</td>
        </tr>
        <tr>
          <td class="tg-0lax">5</td>
          <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
          <td class="tg-0lax">Cuaca Ekstrim</td>
          <td class="tg-0lax">Kekeringan</td>
        </tr>
        <tr>
          <td class="tg-0lax">6</td>
          <td class="tg-0lax">Cuaca Ekstrim</td>
          <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
          <td class="tg-0lax">Banjir Bandang</td>
        </tr>
        <tr>
          <td class="tg-0lax">7</td>
          <td class="tg-0lax">Kekeringan</td>
          <td class="tg-0lax">Erupsi gunung api</td>
          <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Banjir Bandang</span></td>
        </tr>
        <tr>
          <td class="tg-0lax">8</td>
          <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
          <td class="tg-0lax">Tsunami</td>
          <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Cuaca Ekstrim</span></td>
        </tr>
        <tr>
          <td class="tg-0lax">9</td>
          <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
          <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
          <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Kegagalan Teknologi</span></td>
        </tr>
        <tr>
          <td class="tg-0lax">10</td>
          <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
          <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
          <td class="tg-0lax">Kegagalan Teknologi</td>
        </tr>
        <tr>
          <td class="tg-0lax">11</td>
          <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
          <td class="tg-0lax">Kegagalan Teknologi</td>
          <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
        </tr>
        <tr>
          <td class="tg-0lax">12</td>
          <td class="tg-0lax">Gagal Teknologi</td>
          <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
          <td class="tg-0lax">Pandemi Covid-19</td>
        </tr>
        <tr>
          <td class="tg-0lax">13</td>
          <td class="tg-0lax">Konflik Sosial</td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax">Likuifaksi</td>
        </tr>
        <tr>
          <td class="tg-0lax">14</td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
        </tr>
        <tr>
          <td class="tg-0lax">15</td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
        </tr>
        <tr>
          <td class="tg-0lax">16</td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax"></td>
          <td class="tg-0lax">Konflik Sosial</td>
        </tr>
      </tbody>
      </table>
    `,
    attachment: [
      {
        id: 'apsdj',
        title: 'Data dan Informasi Bencana DIY Tahun 2022',
        url: 'https://drive.google.com/file/d/1E2JlPHnK9D3T5B_v20pqY6La1FHEWqyN/view?usp=sharing'
      }
    ],
    child: [
      {
        id: 'akjdbw',
        subtitle: 'Video 1',
        embedId: 'UXfh_MMP5V4'
      },
      {
        id: 'asdjcw2',
        subtitle: 'Video 2',
        embedId: 'Wo_hIhKCuuU'
      },
      {
        id: 'a89s',
        subtitle: 'Video 3',
        embedId: '3tuZ7Y_TpMw'
      }
    ]
  },
  {
    title: 'Erupsi Gunung Merapi',
    path: '4',
    content: `
    <img src=${merapiImage} src="Peta Jangkauan Merapi di DIY" width="100%" />
    <p>
    Gunung Merapi merupakan gunung api tipe strato, dengan ketinggian 2.980 meter. Secara administratif terletak pada 4 wilayah Kabupaten yaitu Kabupaten Sleman, DIY, dan Kabupaten Magelang, Boyolali, dan Klaten Provinsi Jawa Tengah.
    </p>
    <img src=${petaDiyImage} src="Peta Jangkauan Merapi di DIY" width="100%" />
    `,
    attachment: [
      {
        id: 'das',
        title: 'Pengenalan Gunung Api',
        url: 'https://www.esdm.go.id/assets/media/content/Pengenalan_Gunung_Api.pdf'
      },
      {
        id: 'sdf',
        title: 'Data Dasar Gunung Api - Gunung Merapi',
        url: 'https://vsi.esdm.go.id/index.php/gunungapi/data-dasar-gunungapi/542-g-merapi'
      },
      {
        id: 'wui',
        title: 'Jurnal Erupsi Gunung Merapi',
        url: 'https://drive.google.com/drive/folders/1tSYtP02nsQ_4prnp6jb9jZM-bhCgS6Ot?usp=sharing'
      }
    ],
    child: [
      {
        id: 'asinuw',
        subtitle: 'Video 1',
        embedId: '3tuZ7Y_TpMw'
      },
      {
        id: 'asdui',
        subtitle: 'Video 2',
        embedId: 'GlzpBn0CSOY'
      },
      {
        id: 'nius',
        subtitle: 'Video 3',
        embedId: 'iPZlsZrm5Dw'
      }
    ]
  },
  {
    title: 'Sistem Peringatan Dini',
    path: '5',
    content: `<h6>Pengertian</h6>
    <p>Peringatan dini adalah serangkaian  kegiatan pemberian peringatan sesegera  mungkin kepada masyarakat tentang  kemungkinan terjadinya bencana pada  suatu tempat oleh lembaga yang  berwenang (PP No.21 Tahun 2008)</p>
    <br />
    <h6>Syarat</h6>
    <ul>
      <li>Adanya informasi resmi yang dapat  dipercaya</li>
      <li>Adanya alat dan tanda bahaya yang  disepakati</li>
      <li>Ada cara/mekanisme untuk  menyebarluaskan peringatan tersebut</li>
    </ul>
    `,
    child: [
      {
        id: 'uawyb',
        subtitle: 'Video 1',
        embedId: 'tsiZUgW7PuE'
      },
      {
        id: 'sads',
        subtitle: 'Video 2',
        embedId: '42eATChRzyE'
      },
      {
        id: 'asdh',
        subtitle: 'Video 3',
        embedId: 'mwYAVGGfgHI'
      }
    ]
  },
  {
    title: 'Rencana Evakuasi',
    path: '6',
    content: `<h6>Pengertian</h6>
    <p>Masyarakat di kawasan rawan bencana wajib memiliki rencana evakuasi untuk penyelamatan diri beserta harta bendanya ketempat lebih aman sebelum datang ancaman. Undang-undang PB No 24/2007 pada Pasal 45, ayat 2, butir e berbunyi “Kesiapsiagaan sebagaimana dimaksud pada ayat (1)dilakukan melalui:” e. penyiapan lokasi evakuasi. 
    </p>
    <p>
    Rencana evakuasi di sekolah berhubungan dengan alat yang digunakan untuk evakuasi, apa saja yang perlu diselamatkan, dan bagaimana strategi penyelamatan warga sekolah dan asset penting sekolah.
    </p>
    `,
    child: [
      {
        id: 'wuygq',
        subtitle: 'Video 1',
        embedId: 'C7EOB92A8P4'
      }
    ]
  },
  {
    title: 'Prosedur Tetap',
    path: '7',
    content: `
    <ol>
    <li>Prosedur Tetap adalah serangkaian kegiatan yang terstruktur dan disepakati oleh seluruh pihak terkait tentang siapa berbuat apa pada saat kapan, dimana, mengapa dan bagaimana metode pelaksanaannya.</li>
    <li>Tujuan Prosedur Tetap : (a) Tindakan-tindakan apa yang dilakukan dan oleh siapa, baik sebelum kejadian, di saat kejadian, dan setelah kejadian, (b) Menjamin "Koordinasi siapa melakukan apa; individu atau tim" dan "Komunikasi jalur koordinasi: wewenang dan tangungjawab”, (c ) Prosedur tetap juga membantu untuk mengetahui dengan jelas peran dan fungsi tiap-tiap posisi dalam situasi darurat (termasuk untuk simulasi/ gladi), serta memperjelas alur tugas, wewenang dan tanggung jawab dari petugas terkait.</li>
    </ol>
    <br />
    <p>
    Tas Siaga Bencana (TSB) merupakan tas yang dipersiapkan untuk berjaga-jaga apabila terjadi suatu bencana atau kondisi darurat lain. Tujuan TSB sebagai persiapan untuk bertahan hidup saat bantuan belum datang dan memudahkan kita saat evakuasi menuju tempat aman.
    </p>
    <img src=${tasSiagaImage} alt="Tas Siaga untuk 3 hari" width="100%" />
    `,
    attachment: [
      {
        id: '567j',
        title: 'Materi Kesiapsiagaan',
        url: 'https://drive.google.com/drive/u/5/folders/13CD6bF1RgWZ4Vp6QiwD0e7nYUn_f9hcN'
      }
    ],
    child: [
      {
        id: 'awuf',
        subtitle: 'Video 1',
        embedId: 'toG6KjowMQM'
      },
      {
        id: 'daniu',
        subtitle: 'Video 2',
        embedId: 'IJFVib4YiXA'
      },
      {
        id: 'liwee',
        subtitle: 'Video 3',
        embedId: 'RzHAnIEwpZU'
      },
      {
        id: 'alssj',
        subtitle: 'Video 4',
        embedId: 'aIxvmNAEhZU'
      },
      {
        id: 'asjs',
        subtitle: 'Video 5',
        embedId: 'K_b_LwVpehc'
      },
      {
        id: 'asko',
        subtitle: 'Video 6',
        embedId: 'DdTX2Or_UxY'
      },
      {
        id: 'sdhj',
        subtitle: 'Video 7',
        embedId: 'wGm-imRsioA'
      },
      {
        id: 'sjah',
        subtitle: 'Video 8',
        embedId: '73_zCmA5yWg'
      }
    ]
  },
  {
    title: 'Post-Test',
    path: '8',
    content: `
    <p>
    Setelah mempelajari materi, silahkan Bapak/Ibu melakukan Post-Test : <a href="https://docs.google.com/forms/d/1wkpyt28y-afYifhXNJQgSqTir9EmPvjs6cuX3YycFZE/edit" target="blank">Post-Test SIPENA</a>
    </p>
    `
  }
];

export default content;
