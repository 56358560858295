import { serviceFirstLogin, serviceGetSchools, serviceLogin } from 'utils/api';
import { general } from 'utils/helpers';
import { setLocalStorage, removeLocalStorage } from 'utils/helpers/localStorage';

export const SET_ERROR = 'loginAction/SET_ERROR';
export const SET_LOADING = 'loginAction/SET_LOADING';
export const SET_USER_LOGIN = 'loginAction/SET_USER_LOGIN';
export const SET_IS_USER_LOGIN = 'loginAction/SET_IS_USER_LOGIN';
export const SET_IS_FIRST_LOGIN = 'loginAction/SET_IS_FIRST_LOGIN';
export const SET_SCHOOL_LIST = 'loginAction/SET_SCHOOL_LIST';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
});

export const setUserLogin = (payload) => ({
  type: SET_USER_LOGIN,
  payload
});
export const setIsUserLogin = (payload) => ({
  type: SET_IS_USER_LOGIN,
  payload
});
export const setIsFirstLogin = (payload) => ({
  type: SET_IS_FIRST_LOGIN,
  payload
});
export const setSchoolList = (payload) => ({
  type: SET_SCHOOL_LIST,
  payload
});

export const handleLogin = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { headers, data, status } = await serviceLogin(payload);
    dispatch(setLoading(false));
    if (status === 200) {
      if (headers['access-token']) {
        setLocalStorage('accessToken', headers['access-token']);
        const dataUser = {
          ...data,
          role: general.mappingRoleUser(data.role)
        };
        setLocalStorage('userLogin', dataUser);
        setLocalStorage('firstLogin', data.firstLogin);
        await dispatch(setUserLogin(dataUser));
        await dispatch(setIsUserLogin(true));
      }
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleFirstLogin = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // const user = getLocalStorage('userLogin');
    const { data, status } = await serviceFirstLogin(payload);
    setLocalStorage('users', data);
    // console.warn('Data', { data, status });
    if (status === 200) {
      removeLocalStorage('firstLogin');
      setLocalStorage('firstLogin', false);
      window.location.assign('/home');
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const getSchools = () => async (dispatch) => {
  try {
    const { data, status } = await serviceGetSchools();
    if (status === 200) {
      dispatch(setSchoolList(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};
