import axios from 'axios';

// const env = 'development';
const env = 'production';

const apiUrl =
  env === 'development' ? `http://localhost:3002/api` : process.env.REACT_APP_API_URL_BASE;

// Authentication
export const serviceLogin = (payload) => axios.post(`${apiUrl}/auth/signin`, payload);
export const serviceRegister = (payload) => axios.post(`${apiUrl}/auth/signup`, payload);
export const serviceFirstLogin = (payload) =>
  axios.post(`${apiUrl}/userService/users/create`, payload);

// User Service
export const serviceGetUsers = (payload) => axios.post(`${apiUrl}/userService/users`, payload);
export const serviceGetTotalUsers = (payload) =>
  axios.post(`${apiUrl}/userService/users/total`, payload);
export const serviceGetUserProfile = (payload) => axios.post(`${apiUrl}/userService/user`, payload);
export const serviceUpdateProfile = (payload) =>
  axios.post(`${apiUrl}/userService/user/update`, payload);
export const serviceDeleteUser = (payload) =>
  axios.post(`${apiUrl}/userService/users/delete`, payload);

// School Service
export const serviceGetSchools = (payload) => axios.post(`${apiUrl}/schoolService/`, payload);
export const serviceNewSchools = (payload) => axios.post(`${apiUrl}/schoolService/new`, payload);
export const serviceDeleteSchool = (payload) =>
  axios.post(`${apiUrl}/schoolService/delete`, payload);

// Discussion Service
export const serviceGetTopics = (payload) => axios.post(`${apiUrl}/topicService`, payload);
export const serviceDetailTopic = (payload) =>
  axios.post(`${apiUrl}/topicService/detailTopic`, payload);
export const serviceDeleteTopic = (payload) => axios.post(`${apiUrl}/topicService/remove`, payload);
export const serviceEditTopic = (payload) => axios.post(`${apiUrl}/topicService/edit`, payload);
export const serviceNewTopic = (payload) => axios.post(`${apiUrl}/topicService/addTopic`, payload);
export const serviceTopicsByUser = (payload) =>
  axios.post(`${apiUrl}/topicService/userTopics`, payload);

// Comment Service
export const serviceNewComment = (payload) => axios.post(`${apiUrl}/commentService/new`, payload);

// Quiz Service
export const serviceSubmitQuiz = (payload) => axios.post(`${apiUrl}/quizService/submit`, payload);
export const serviceCheckQuiz = (payload) => axios.post(`${apiUrl}/quizService/checkTest`, payload);
export const serviceGetScores = (payload) => axios.post(`${apiUrl}/quizService/scores`, payload);
