import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from '@mui/material';

function AddSchool({ open, onClose, onSend, onChange, value }) {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box>
        <DialogTitle>Registrasi Sekolah</DialogTitle>
        <DialogContent>
          <TextField
            label="Nama Sekolah"
            placeholder="Tuliskan nama sekolah"
            id="name"
            name="name"
            onChange={onChange}
            value={value?.name}
            fullWidth
            sx={{ mb: 3, mt: 1 }}
          />
          <TextField
            label="Alamat Sekolah"
            placeholder="Tuliskan alamat sekolah"
            onChange={onChange}
            multiline
            minRows={4}
            maxRows={10}
            id="address"
            name="address"
            fullWidth
            value={value?.address}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button variant="text" color="error" size="large" onClick={onClose}>
            Batal
          </Button>
          <Button variant="contained" color="primary" size="large" onClick={() => onSend('ask')}>
            Daftar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

AddSchool.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.object
};

AddSchool.defaultProps = {
  onChange: () => {},
  value: {}
};

export default AddSchool;
