import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, List, ListItemButton, Typography } from '@mui/material';

// Assets
import tasSiagaImage from 'assets/images/img/tas-siaga.png';
import { Colors } from 'utils/theme';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function ProsedurTetap() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Prosedur Tetap
      </Typography>
      {contentId === '22' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <ol>
              <li>
                Prosedur Tetap adalah serangkaian kegiatan yang terstruktur dan disepakati oleh
                seluruh pihak terkait tentang siapa berbuat apa pada saat kapan, dimana, mengapa dan
                bagaimana metode pelaksanaannya.
              </li>
              <li>
                Tujuan Prosedur Tetap : (a) Tindakan-tindakan apa yang dilakukan dan oleh siapa,
                baik sebelum kejadian, di saat kejadian, dan setelah kejadian, (b) Menjamin
                &quot;Koordinasi siapa melakukan apa; individu atau tim&quot; dan &quot;Komunikasi
                jalur koordinasi: wewenang dan tangungjawab”, (c ) Prosedur tetap juga membantu
                untuk mengetahui dengan jelas peran dan fungsi tiap-tiap posisi dalam situasi
                darurat (termasuk untuk simulasi/ gladi), serta memperjelas alur tugas, wewenang dan
                tanggung jawab dari petugas terkait.
              </li>
            </ol>
            <br />
            <p>
              Tas Siaga Bencana (TSB) merupakan tas yang dipersiapkan untuk berjaga-jaga apabila
              terjadi suatu bencana atau kondisi darurat lain. Tujuan TSB sebagai persiapan untuk
              bertahan hidup saat bantuan belum datang dan memudahkan kita saat evakuasi menuju
              tempat aman.
            </p>
            <img src={tasSiagaImage} alt="Tas Siaga untuk 3 hari" width="100%" />
          </Box>
          <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', paddingLeft: '24px' }}>
            <Typography variant="h6">Bahan Pembelajaran</Typography>
            <List>
              {[
                {
                  id: 1,
                  title: 'Materi Kesiapsiagaan',
                  url: 'https://drive.google.com/drive/u/5/folders/13CD6bF1RgWZ4Vp6QiwD0e7nYUn_f9hcN'
                }
              ].map((item) => (
                <Link key={item.id} href={item.url} sx={{ textDecoration: 'none' }}>
                  <ListItemButton
                    sx={{
                      background: Colors.primary.main,
                      color: Colors.white,
                      fontWeight: 800,
                      '&:hover': {
                        background: Colors.primary.dark
                      }
                    }}
                  >
                    {item.title}
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {contentId === '23' && (
        <YouTubeEmbed
          embedId="toG6KjowMQM"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 2."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '24' && (
        <YouTubeEmbed
          embedId="IJFVib4YiXA"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 2 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 3."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '25' && (
        <YouTubeEmbed
          embedId="RzHAnIEwpZU"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 3 berikut ini."
          postVideoText="Setelah melihat video 3, Bapak/Ibu Guru dapat melanjutkan ke Video 4."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '26' && (
        <YouTubeEmbed
          embedId="aIxvmNAEhZU"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 4 berikut ini."
          postVideoText="Setelah melihat video 4, Bapak/Ibu Guru dapat melanjutkan ke Video 5."
          caption="(Sumber: youtube.com. Humas BNPB. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '27' && (
        <YouTubeEmbed
          embedId="K_b_LwVpehc"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 5 berikut ini."
          postVideoText="Setelah melihat video 5, Bapak/Ibu Guru dapat melanjutkan ke Video 6."
          caption="(Sumber: youtube.com. Desa Kebondalem. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '28' && (
        <YouTubeEmbed
          embedId="DdTX2Or_UxY"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 6 berikut ini."
          postVideoText="Setelah melihat video 6, Bapak/Ibu Guru dapat melanjutkan ke Video 7."
          caption="(Sumber: youtube.com. IndonesiaBaikID. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '29' && (
        <YouTubeEmbed
          embedId="wGm-imRsioA"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 7 berikut ini."
          postVideoText="Setelah melihat video 7, Bapak/Ibu Guru dapat melanjutkan ke Video 8."
          caption="(Sumber: youtube.com. INAHEALTH. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '30' && (
        <YouTubeEmbed
          embedId="73_zCmA5yWg"
          preVideoText="Untuk menambah pengetahuan tentang prosedur tetap tanggap darurat bencana erupsi gunung api, Bapak/Ibu Guru dapat melihat video 8 berikut ini."
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah melihat video 1 sampai dengan 8, Bapak/Ibu Guru dapat menyimpulkan:
              <ol>
                <li>Yang harus dilakukan ketika terjadi erupsi gunung api yaitu...</li>
                <li>Barang-barang yang harus ada di tas siaga bencana antara lain</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default ProsedurTetap;
