import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, ImageList, Typography } from '@mui/material';

// Assets
import kentonganImage from 'assets/images/img/kentongan.jpg';
import sirineImage from 'assets/images/img/sirine.jpg';
import loncengImage from 'assets/images/img/lonceng.jpg';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function SistemPeringatanDini() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Sistem Peringatan Dini
      </Typography>
      {contentId === '17' && (
        <Box sx={{ maxWidth: '70%' }}>
          <Typography variant="body1" component="p">
            Peringatan dini adalah serangkaian kegiatan pemberian peringatan sesegera mungkin kepada
            masyarakat tentang kemungkinan terjadinya bencana pada suatu tempat oleh lembaga yang
            berwenang (BNPB, 2022).
          </Typography>
          <br />
          <Typography variant="h6" component="h2">
            Kriteria:
          </Typography>
          <ol
            style={{
              fontSize: '16px',
              color: '#000',
              listStylePosition: 'outside',
              marginLeft: '32px'
            }}
          >
            <li>
              Adanya alat yang dapat mengerluarkan bunyi sebagai tanda akan terjadinya bencana di
              Sekolah. Contohnya : Sirine, bel, megaphone, kentongan.
            </li>
            <li>
              Ada yang mengoperasikan/ membunyikan alat tanda bahaya. Contohnya : Bapak/Ibu Guru.
            </li>
            <li>
              Ada informasi bunyi yang disepakati oleh warga sekolah. Contohnya : bel dibunyikan
              secara terus menerus.
            </li>
          </ol>
          <br />
          <ImageList cols={3}>
            <img src={kentonganImage} alt="Gambar Kentongan" width="100%" />
            <img src={sirineImage} alt="Gambar Sirine" width="100%" />
            <img src={loncengImage} alt="Gambar Lonceng" width="100%" />
          </ImageList>
          <br />
          <br />
        </Box>
      )}

      {contentId === '18' && (
        <YouTubeEmbed
          embedId="tsiZUgW7PuE"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 1 sistem peringatan dini, baca baik-baik materi berikut ini
                ya!
              </Typography>
              <Typography variant="body1" component="p">
                Sistem peringatan dini (early warning system) merupakan bagian penting dalam
                mekanisme kesiapsiagaan masyarakat dalam pengurangan risiko bencana.
              </Typography>
              <Typography variant="body1" component="p">
                Fungsi Sistem Peringatan Dini :
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Merespon secara cepat dan tepat ancaman bencana.</li>
                  <li>Membantu evakuasi penyelamatan penduduk lebih cepat.</li>
                  <li>Mencegah dan mengurangi dampak bencana.</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Alat yang dapat digunakan untuk sistem peringatan dini antara lain:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Sirine</li>
                  <li>Pengeras suara/ lonceng</li>
                  <li>Kentongan</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 1, adik-adik dapat melanjutkan melihat video 2."
          caption="(Sumber: youtube.com. IndonesiaBaikID. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '19' && (
        <YouTubeEmbed
          embedId="42eATChRzyE"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 2 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 2 sistem peringatan dini, baca baik-baik materi berikut ini
                ya!
              </Typography>
              <Typography variant="body1" component="p">
                Tanda-tanda akan terjadinya erupsi Gunung Api:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>
                    Temperatur meningkat secara drastis. Hal ini disebabkan oleh pengaruh hawa panas
                    yang dikeluarkan gunung, akibat aktivitas magma yang meningkat di lapisan bawah
                    kawah. Jarak antara magma dan permukaan bumi semakin dekat dan menjadi penyebab
                    kenapa suhu lereng dan sekitar kawah naik melewati ambang batas normal.
                  </li>
                  <li>
                    Sumber mata air mengering karena panas yang dihasilkan oleh Magma yang letaknya
                    sangat dekat dengan permukaan tanah{' '}
                  </li>
                  <li>
                    Sering terjadi gempa vulkanik penyebabnya karena peningkatan aktivitas magma
                    yang terdorong ke segala arah akibat tekanan endogen yang begitu besar akibatnya
                    lapisan batuan tertekan sehingga menimbulkan Getaran yang dikenal sebagai gempa.
                  </li>
                  <li>
                    Banyak hewan turun gunung. Hutan sekitar lereng gunung memiliki banyak satwa
                    liar. Peningkatan suhu dan aktivitas vulkanik menyebabkan hewan-hewan tersebut
                    merasa tidak nyaman sehingga turun menuju kaki gunung dan tak jarang masuk ke
                    pemukiman.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Status Erupsi Gunung Api
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Normal</li>
                  <li>Waspada</li>
                  <li>Siaga</li>
                  <li>Awas</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 2 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 2, adik-adik dapat melanjutkan melihat video 3."
          caption="(Sumber: youtube.com. Halo Edukasi. diinput dalam website SIPENA
            November 2022)"
        />
      )}

      {contentId === '20' && (
        <YouTubeEmbed
          embedId="mwYAVGGfgHI"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 3 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 3 sistem peringatan dini, baca baik-baik materi berikut ini
                ya!
              </Typography>
              <Typography variant="body1" component="p">
                Indonesia merupakan negara yang memiliki Gunung Api terbanyak yakni 127 Gunung Api
                Aktif.
              </Typography>
              <Typography variant="body1" component="p">
                4 tingkat Status Erupsi Gunung Api antara lain:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Normal</li>
                  <li>Waspada</li>
                  <li>Siaga</li>
                  <li>Awas</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 3 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah selesai melihat, mengamati, dan memperhatikan video 1,2,3 Sistem Peringatan
              Dini, tuliskan kesimpulan pada buku catatanmu!
              <ol>
                <li>Sistem Peringatan Dini adalah...</li>
                <li>Tanda-tanda akan terjadinya erupsi gunung api yaitu...</li>
                <li>4 tingkat status gunung api yaitu</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. KOMPASTV. diinput dalam website
            SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default SistemPeringatanDini;
