import {
  SET_LOADING,
  SET_QUESTIONS,
  SET_COMMENTS,
  SET_NEW_QUESTION,
  SET_QUESTION_DETAILS,
  SET_SELECTED_MENU,
  SET_SELECTED_QUESTION,
  SET_QUESTION_POPUP,
  SET_IS_EDIT
} from '../actions/discussion.actions';

const initialState = {
  isLoading: false,
  questions: [],
  newQuestion: {},
  questionDetails: {},
  comments: [],
  selectedMenu: 0,
  selectedQuestion: {},
  openQuestionPopup: false,
  isEdit: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questions: payload
      };
    case SET_QUESTION_DETAILS:
      return {
        ...state,
        questionDetails: payload
      };
    case SET_NEW_QUESTION:
      return {
        ...state,
        newQuestion: payload
      };
    case SET_SELECTED_QUESTION:
      return {
        ...state,
        selectedQuestion: payload
      };
    case SET_QUESTION_POPUP:
      return {
        ...state,
        openQuestionPopup: payload
      };
    case SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: payload
      };
    case SET_IS_EDIT:
      return {
        ...state,
        isEdit: payload
      };
    case SET_COMMENTS:
      return {
        ...state,
        comments: [...state.comments, payload]
      };
    default:
      return state;
  }
};
