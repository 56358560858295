import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridPrintExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleGetScores } from 'redux/actions/quiz.actions';
import dataGridLocalizationID from 'utils/localization';

const columns = [
  { field: 'id', headerName: '#', type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'firstName', headerName: 'Nama Depan', width: 150 },
  { field: 'lastName', headerName: 'Nama Belakang', width: 150 },
  {
    field: 'fullName',
    headerName: 'Nama Lengkap',
    width: 300,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
  },
  {
    field: 'role',
    type: 'singleSelect',
    valueOptions: ['Guru', 'Siswa'],
    headerName: 'Role',
    width: 70
  },
  {
    field: 'testName',
    headerName: 'Nama Tes/Kuis',
    width: 300
  },
  {
    field: 'score',
    type: 'number',
    headerName: 'Nilai',
    headerAlign: 'left',
    align: 'left'
  },
  {
    field: 'attempt',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    headerName: 'Total Pengulangan',
    width: 150
  }
];

const csvOptions = { delimiter: ';', fileName: 'SIPENA - Data Nilai' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function Scores() {
  const dispatch = useDispatch();

  const { scores } = useSelector((state) => state.quizzes, shallowEqual);

  const [rows, setRows] = useState([]);

  const fetchRef = React.useRef(null);

  React.useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    dispatch(handleGetScores());
  }, []);

  React.useEffect(() => {
    if (scores.length !== 0) {
      setRows(scores);
    }
  }, [scores]);

  return (
    <Paper sx={{ mb: 4, p: 3, borderRadius: 2 }}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            columns: {
              columnVisibilityModel: {
                fullName: false
              }
            }
          }}
          localeText={dataGridLocalizationID}
          slots={{
            toolbar: CustomToolbar
          }}
        />
      </Box>
    </Paper>
  );
}

export default Scores;
