const Typography = {
  h1: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '100px',
    lineHeight: '115px',
    letterSpacing: '-1.5px'
  },
  h2: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '62px',
    lineHeight: '77px',
    letterSpacing: '-0.5px'
  },
  h3: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '50px',
    lineHeight: '64px'
  },
  h4: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '35px',
    lineHeight: '50px',
    letterSpacing: '0.25px'
  },
  h5: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '25px',
    lineHeight: '40px'
  },
  h6: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '21px',
    lineHeight: '36px',
    letterSpacing: '0.15px'
  },
  subtitle1: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '27px',
    letterSpacing: '0.15px'
  },
  subtitle2: {
    fontFamily: 'Signika',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '27px',
    letterSpacing: '0.1px'
  },
  body1: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '25px',
    letterSpacing: '0.5px'
  },
  body2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.25px'
  },
  button: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '1.25px',
    textTransform: 'uppercase'
  },
  caption: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '0.4px'
    // textTransform: 'uppercase'
  },
  caption2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '15px',
    letterSpacing: '0.4px'
  },
  overline: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  }
};

export default Typography;
