import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getLocalStorage } from 'utils/helpers/localStorage';
// import SidebarContent from './components/Sidebar';
import teacherContent from './components/teacherContent';
import studentContent from './components/studentContent';
import MainContent from './components/MainContent';
import CourseSidebar from './components/Sidebar';
import CourseContent from './components/Main';

function ClassContent() {
  const user = getLocalStorage('userLogin');
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (user?.role === 'guru') {
      setContent(teacherContent);
    } else if (user?.role === 'siswa') {
      setContent(studentContent);
    }
  }, [user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CourseSidebar />
      <CourseContent />
    </Box>
  );
}

export default ClassContent;
