import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { Colors } from 'utils/theme';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer({ isPublic }) {
  const otherResources = [
    {
      id: 'res1',
      label: 'Seknas SPAB',
      url: 'https://spab.kemdikbud.go.id/'
    },
    {
      id: 'res2',
      label: 'InaRISK',
      url: 'https://inarisk.bnpb.go.id/'
    },
    {
      id: 'res3',
      label: 'Portal Literasi Sejarah Bencana',
      url: 'https://sejarah.dibi.bnpb.go.id/'
    },
    {
      id: 'res4',
      label: 'Sirenkon BNPB',
      url: 'https://siaga.bnpb.go.id/renkon/'
    },
    {
      id: 'res5',
      label: 'Data dan Informasi Bencana Indonesia',
      url: 'https://dibi.bnpb.go.id/'
    },
    {
      id: 'res6',
      label: 'Perpustakaan BNPB',
      url: 'https://perpustakaan.bnpb.go.id/bulian/'
    },
    {
      id: 'res7',
      label: 'Katalog Kesiapsiagaan BNPB',
      url: 'https://katalogkesiapsiagaan.bnpb.go.id/'
    },
    {
      id: 'res8',
      label: 'Cerdas Antisipasi Risiko Bencana',
      url: 'https://caribencana.id/'
    },
    {
      id: 'res9',
      label: 'Think Hazard',
      url: 'https://thinkhazard.org/'
    },
    {
      id: 'res10',
      label: 'InaWare',
      url: 'https://inaware.bnpb.go.id/inaware/'
    }
  ];
  return (
    <Box
      sx={{
        background: isPublic ? Colors.primary.main : Colors.black,
        height: 'auto',
        color: Colors.white,
        py: 6
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Box display="flex" flexDirection="column">
              <Typography variant="h4" component="h1" color="white">
                SIPENA
              </Typography>
              <Typography variant="subtitle1" component="h2" color="white">
                Siaga Pendidikan Bencana
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" component="p">
                Kontak Kami
              </Typography>
              <Typography variant="body" component="p">
                sipena.id1@gmail.com
              </Typography>
              <Typography variant="body" component="p">
                Telepon 087738408187
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography variant="h6" component="p">
                Ikuti Kami
              </Typography>
              <Link
                href="https://www.facebook.com/profile.php?id=100089816620586&mibextid=LQQJ4d"
                color="white"
                underline="none"
                target="_blank"
              >
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <FacebookIcon />
                  <Typography variant="body1">Facebook</Typography>
                </Box>
              </Link>
              <Link
                href="https://instagram.com/sipena_siagapendidikanbencana"
                color="white"
                underline="none"
                target="_blank"
              >
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <InstagramIcon />
                  <Typography variant="body1">Instagram</Typography>
                </Box>
              </Link>
              <Link
                href="https://youtube.com/@sipenaid"
                color="white"
                underline="none"
                target="_blank"
              >
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <YouTubeIcon />
                  <Typography variant="body1">YouTube</Typography>
                </Box>{' '}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="p">
              Resource lainnya
            </Typography>
            <Box display="flex" flexDirection="column">
              <ul style={{ marginLeft: 4 }}>
                {otherResources.map((item) => (
                  <Link key={item.id} href={item.url} underline="hover" color={Colors.white}>
                    <li>{item.label}</li>
                  </Link>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  isPublic: PropTypes.bool
};

Footer.defaultProps = {
  isPublic: false
};

export default Footer;
