import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { Colors } from 'utils/theme';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleLogin } from 'redux/actions/login.actions';

function LoginForm() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getLocalStorage('userLogin');

  const { isUserLogin } = useSelector((state) => state.login, shallowEqual);

  const [userInput, setUserInput] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (user) {
      if (user?.role !== 'admin') {
        if (user?.firstLogin) {
          navigate('/first-login');
        } else {
          navigate('/home');
        }
      } else {
        navigate('/admin');
      }
    }
  }, [user]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setUserInput({
      ...userInput,
      [name]: value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(handleLogin(userInput));
    if (isUserLogin) {
      navigate('/home', { replace: true });
    }
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
      width={isMobile ? '90%' : '500px'}
      display="flex"
      flexDirection="column"
      gap="16px"
      border={`1px solid ${Colors.disabled.main}`}
      borderRadius="10px"
      padding={5}
    >
      <Typography variant="h6" textAlign="center">
        Login
      </Typography>
      <TextField label="Email" name="email" fullWidth onChange={handleChange} />
      <TextField
        label="Kata Sandi"
        name="password"
        fullWidth
        type="password"
        onChange={handleChange}
      />
      <Button
        variant="contained"
        sx={{ color: Colors.white, width: '100%' }}
        size="large"
        type="submit"
      >
        Login
      </Button>
      <Button
        variant="text"
        disableRipple
        sx={{ textTransform: 'inherit', '&:hover': { background: 'none' } }}
        onClick={() => navigate('/register')}
      >
        <Typography variant="body" sx={{ fontSize: 12 }}>
          Belum Punya Akun? Daftar Sekarang.
        </Typography>
      </Button>
    </Box>
  );
}

export default LoginForm;
