import { combineReducers } from 'redux';
import { login, register, classes, discussion, forum, users, quizzes } from './reducers';

export default () =>
  combineReducers({
    login,
    register,
    classes,
    forum,
    discussion,
    users,
    quizzes
  });
