import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material';

function LabelItem({ label, value }) {
  return (
    <Box>
      <Typography variant="subtitle2" component="p" style={{ color: '#aaa' }}>
        {label}
      </Typography>
      <Typography variant="body1" component="p">
        {value}
      </Typography>
    </Box>
  );
}

LabelItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

LabelItem.defaultProps = {
  label: '',
  value: ''
};

function TeacherProfile() {
  const { profile } = useSelector((state) => state.users, shallowEqual);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LabelItem
          label="Nama Lengkap"
          value={`${profile?.firstName} ${
            profile?.lastName === null || profile?.lastName === undefined ? '' : profile?.lastName
          }`}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelItem label="NIK" value={`${profile?.nik || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem
          label="Jenis Kelamin"
          value={`${profile?.gender === 'M' ? 'Laki-Laki' : 'Perempuan'}`}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelItem label="Usia" value={`${profile?.age || '-'}`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelItem label="Nama Sekolah" value={`${profile?.school || '_'}`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelItem label="Guru Kelas" value={`${profile?.teach || '-'}`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelItem label="Pendidikan Terakhir" value={`${profile?.lastEducation || '-'}`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabelItem label="No Telepon/WA" value={`${profile?.phone || '-'}`} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Email" value={`${profile?.email || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Username" value={`${profile?.username || '-'}`} />
      </Grid>
    </Grid>
  );
}

export default TeacherProfile;
