import { SET_SELECTED_MENU, SET_TEST_SCORES } from '../actions/class.actions';

const initialState = {
  selectedMenu: '1',
  testScore: {}
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: payload
      };
    case SET_TEST_SCORES:
      return {
        ...state,
        testScore: payload
      };
    default:
      return state;
  }
};
