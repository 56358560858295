import { SET_ERROR, SET_LOADING, SET_QUIZ_SCORES } from '../actions/quiz.actions';

const initialState = {
  error: { isError: false, message: '' },
  isLoading: false,
  scores: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        error: payload
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_QUIZ_SCORES:
      return {
        ...state,
        scores: payload
      };

    default:
      return state;
  }
};
