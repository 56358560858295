import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function Text({ text, children, ...props }) {
  return <Typography {...props}>{text || children}</Typography>;
}

Text.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node
};

Text.defaultProps = {
  text: '',
  children: null
};

export default Text;
