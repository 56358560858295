import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography } from '@mui/material';
import studentIcon from 'assets/images/icons/StudentIcon.svg';
import teacherIcon from 'assets/images/icons/TeacherIcon.svg';

function CardFeature({ title, icon, description }) {
  return (
    <Box
      sx={{
        border: '1px solid #bbb',
        borderRadius: '8px',
        p: 3,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px'
      }}
    >
      <Typography variant="h4" component="h3">
        {title}
      </Typography>
      <img src={icon} alt="Teacher icon" width="64px" style={{ margin: '0 auto' }} />
      <Typography variant="body1" component="p" textAlign="left">
        {description}
      </Typography>
      {/* <Button variant="contained" color="primary" size="large">
        Daftar Sekarang
      </Button> */}
    </Box>
  );
}

CardFeature.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

function Features() {
  return (
    <Box my={6}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" textAlign="center" mb={3}>
          Fitur SIPENA
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={5}>
            <CardFeature
              title="SIPENA Guru"
              icon={teacherIcon}
              description="SIPENA GURU adalah portal pembelajaran integrasi pendidikan bencana yang menyediakan bahan belajar serta fasilitas komunikasi yang mendukung interaksi antara guru dan siswa SD. SIPENA Guru berisi tentang materi yang terdiri dari : Kurikulum, Materi, Evaluasi, Saran dan Komunikasi dalam pembelajaran integrasi Pendidikan Bencana."
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <CardFeature
              title="SIPENA Siswa"
              icon={studentIcon}
              description="SIPENA Siswa adalah portal pembelajaran integrasi bencana yang menyediakan bahan belajar serta fasilitas komunikasi yang mendukung interaksi guru dan siswa SD dalam pembelajaran integrasi pendidikan bencana. SIPENA Siswa berisi tentang materi yang terdiri dari : Materi dan Evaluasi pembelajaran integrasi Pendidikan Bencana.
"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Features;
