import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// components
import { Box, Button, Divider, Typography } from '@mui/material';

import { handleCheckTest } from 'redux/actions/class.actions';

// Assets
import tasSiagaImage from 'assets/images/img/tas-siaga.png';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';
import KuisTasSiaga from './KuisTasSiaga';

function ProsedurTetap() {
  const { contentId } = useParams();
  const dispatch = useDispatch();

  const { testScore } = useSelector((state) => state.classes, shallowEqual);

  const [startQuiz, setStartQuiz] = useState(false);

  const checkTestRef = useRef(false);

  useEffect(() => {
    if (checkTestRef.current) return;
    checkTestRef.current = true;
    dispatch(handleCheckTest({ quizId: 'dea45ced-dee5-4a90-bf72-dd744d4fd0a3' }));
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Prosedur Tetap
      </Typography>
      {contentId === '23' && (
        <React.Fragment>
          {!startQuiz ? (
            <Box sx={{ maxWidth: '70%' }}>
              <p>
                Apa yang harus dilakukan <strong>JIKA TERJADI ERUPSI GUNUNGAPI DI SEKOLAH!</strong>
              </p>
              <p>
                Ketika sedang di kelas dan mendengar suara peringatan dini tanda bahaya yang
                dilakukan adalah tetap tenang dan mengikuti instruksi/ petunjuk bapak/ibu guru untuk
                melakukan evakuasi menuju ke lokasi yang aman/ titik kumpul.
              </p>
              <p>
                Kegiatan Pembelajaran &rarr; peringatan dini &rarr; tetap tenang dan evakuasi &rarr;
                titik kumpul
              </p>
              <div>
                <p>Tanda-Tanda:</p>
                <ol>
                  <li>Ada peringatan dini dari pemerintah.</li>
                  <li>Keluar asap yang tinggi dari gunung.</li>
                  <li>Adanya suara letusan.</li>
                  <li>Ada getaran karena letusan.</li>
                  <li>Muncul lahar dari puncak gunung.</li>
                  <li>Keluarnya awan panas.</li>
                  <li>Hewan-hewan turun ke pemukiman penduduk.</li>
                </ol>
              </div>
              <br />
              <div>
                <h6>Apa yang harus dilakukan pada saat:</h6>
                <p>Sebelum Terjadinya Bencana Erupsi</p>
                <ol>
                  <li>Perhatikan arahan dari PVMBG dan perkembangan aktivitas gunungapi.</li>
                  <li>Siapkan masker dan kacamata pelindung untuk mengatasi debu vulkanik.</li>
                  <li>
                    Mengetahui jalur evakuasi dan shelter yang telah disiapkan oleh pihak berwenang.
                  </li>
                  <li>
                    Menyiapkan skenario evakuasi lain jika dampak letusan meluas di luar prediksi
                    ahli.
                  </li>
                  <li>
                    Siapkan dukungan logistik, antara lain makanan siap saji, lampu senter dan
                    baterai cadangan, uang tunai yang cukup serta obat-obatan khusus sesuai pemakai.
                  </li>
                  <li>
                    Jika mendengar peringatan tanda bahaya erupsi gunung api, segera melakukan
                    evakuasi ke lokasi yang aman.
                  </li>
                </ol>
                <br />
                <p>Saat Terjadinya Bencana Erupsi Gunung Api</p>
                <ol>
                  <li>Tidak berada di lokasi yang direkomendasikan untuk dikosongkan.</li>
                  <li>Tidak berada di lembah atau daerah aliran sungai.</li>
                  <li>Hindari tempat terbuka. Lindungi diri dari abu letusan gunungapi.</li>
                  <li>Gunakan kacamata pelindung.</li>
                  <li>Jangan memakai lensa kontak.</li>
                  <li>Gunakan masker atau kain basah untuk menutup mulut dan hidung.</li>
                  <li>
                    Kenakan pakaian tertutup yang melindungi tubuh seperti, baju lengan panjang,
                    celana panjang, dan topi.
                  </li>
                </ol>
                <br />
                <p>Setelah Terjadinya Bencana Erupsi Gunung Api</p>
                <ol>
                  <li>Kurangi terpapar dari abu vulkanik.</li>
                  <li>
                    Hindari mengendarai mobil di daerah yang terkena hujan abu vulkanik sebab bisa
                    merusak mesin kendaraan.
                  </li>
                  <li>
                    Bersihkan atap dari timbunan debu vulkanik karena beratnya bisa merobohkan dan
                    merusak atap rumah atau bangunan.
                  </li>
                  <li>
                    Waspadai wilayah aliran sungai yang berpotensi terlanda bahaya lahar pada musim
                    hujan.
                  </li>
                </ol>
              </div>
              <br />
              <p>
                Tas Siaga Bencana (TSB) merupakan tas yang dipersiapkan untuk berjaga-jaga apabila
                terjadi suatu bencana atau kondisi darurat lain. Tujuan TSB sebagai persiapan untuk
                bertahan hidup saat bantuan belum datang dan memudahkan kita saat evakuasi menuju
                tempat aman (BNPB, 2019).
              </p>
              <br />
              <img src={tasSiagaImage} alt="Tas Siaga untuk 3 Hari" width="100%" />
              <br />
              <h6>
                Pilihlah dengan cermat dan masukkanlah 10 benda yang menurutmu penting kedalam tas
                siagamu!
              </h6>
              <Divider sx={{ mb: 3 }} />
              {testScore?.test_code === 'KPTSIS' && testScore?.completed ? (
                <React.Fragment>
                  <Typography variant="body1" component="p" sx={{ mb: 3 }}>
                    Kamu telah menyelesaikan latihan ini sebanyak {testScore?.attempt} dengan nilai{' '}
                    {testScore?.test_score} poin
                  </Typography>
                  <Button variant="contained" size="large" onClick={() => setStartQuiz(true)}>
                    Ulangi
                  </Button>
                </React.Fragment>
              ) : (
                <Button variant="contained" size="large" onClick={() => setStartQuiz(true)}>
                  Latihan Tas Siaga Bencana
                </Button>
              )}
              {/* <Link
                href="https://docs.google.com/forms/d/e/1FAIpQLSfHZDQGXeTfSP9wugM5M7Umm0Klb_6sdxpJnV5FaB_kxEni5w/viewform"
                target="_blank"
              >
                Latihan tas siaga bencana
              </Link> */}
            </Box>
          ) : (
            <KuisTasSiaga />
          )}
        </React.Fragment>
      )}

      {contentId === '24' && (
        <YouTubeEmbed
          embedId="K_b_LwVpehc"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 1 prosedur tetap, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Tas Siaga Bencana adalah tas yang harus disiapkan untuk mengantisipasi terjadinya
                bencana atau kondisi darurat lainnya. Tass siaga bencana berisikan:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>
                    Dokumen penting. Dokumen penting ini meliputi akta kelahiran, ijazah, KTP, surat
                    asuransi, dan kartu keluarga.
                  </li>
                  <li>
                    Perbekalan harus disiapkan yaitu 100gr daging dan makanan siap saji, selimut dan
                    pakaian ganti, senter, kotak P3K.
                  </li>
                  <li>
                    Kebutuhan lainnya : uang tunai, Perlengkapan mandi, handphone, jas hujan, peluit
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari segera siapkan tas siaga bencana karena tas siaga bencana berguna untuk
                menampung kebutuhan atau peralatan penunjang hidup selama 72 jam atau 3 hari pertama
                bencana sambil menunggu bantuan.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 1, adik-adik dapat melanjutkan melihat video 2."
          caption="(Sumber: youtube.com. Desa Kebondalem. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '25' && (
        <YouTubeEmbed
          embedId="DdTX2Or_UxY"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 2 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 2 prosedur tetap, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Tas Siaga Bencana berupa tas ransel yang mudah dibawa. Tas Siaga Bencana berisi
                Dokumen Penting, Perbekalan, dan kebutuhan lainnya.
              </Typography>

              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 2 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 2, adik-adik dapat melanjutkan melihat video 3."
          caption="(Sumber: youtube.com. IndonesiaBaikID. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '26' && (
        <YouTubeEmbed
          embedId="wGm-imRsioA"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 3 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 3 prosedur tetap, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Indonesia termasuk negara yang sering mengalami bencana alam mulai dari banjir,
                gempa bumi, sampai Erupsi Gunung Api. Hal ini terjadi karena Indonesia berlokasi di
                Ring of Fire yang dikelilingi oleh deretan gunung berapi aktif dari Barat hingga
                Timur. Kita harus menyiapkan tas siaga bencana terutama bagi yang tinggal di daerah
                rawan bencana. Seperti apa tas siaga bencana?.
              </Typography>
              <Typography variant="body1" component="p">
                Tas siaga bencana adalah tas biasa yang isinya tidak biasa. Isi tas siaga bencana
                ini bisa dipersiapkan agar kita bisa bertahan kurang lebih 72 jam atau 3 hari.
              </Typography>
              <Typography variant="body1" component="p">
                Isi Tas Siaga Bencana:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>P3K agar bisa mengobati diri sendiri sebelum pertolongan datang.</li>
                  <li>
                    Telepon seluler, powerbank, dan charger digunakan untuk mencari pertolongan.
                    Pastikan ada sinyal dan baterai.
                  </li>
                  <li>
                    Peluit adalah barang kecil tapi besar kegunaannya. Ketika telepon seluler sudah
                    tidak berfungsi, peluit bisa digunakan untuk memberi tanda bagi tim penyelamat
                    agar lokasi kita dapat dengan mudah dan cepat ditemukan.
                  </li>
                  <li>
                    Makanan cepat saji siapkan makanan yang tidak mudah membusuk untuk tiga hingga
                    tujuh hari.
                  </li>
                  <li>
                    Air minum kemasan botol jangan sampai tertinggal karena kita bisa bertahan tanpa
                    makanan tapi tidak tanpa air, jadi minimal siapkan tiga liter air.
                  </li>
                  <li>
                    Radio analog agar bisa selalu mengikuti perkembangan kebencanaan yang sedang
                    kita alami.
                  </li>
                  <li>Senter agar digunakan jika arus listrik padam.</li>
                  <li>
                    Pakaian ganti untuk mengganti pakaian jika basah agar terhindar dari sakit yang
                    bisa memperparah kondisi.
                  </li>
                  <li>
                    Dokumen penting. Pastikan dokumen-dokumen penting tidak tertinggal, dan simpan
                    dokumen penting ditempat anti air seperti kantong plastik.
                  </li>
                  <li>Uang darurat akan digunakan jika ada yang harus segera dibeli</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Itu tadi isi dari tas siaga bencana yang perlu kita siapkan sebelum terjadi bencana.
                Kalau sudah disiapkan, letakkan tas siaga bencana di tempat yang mudah dijangkau
                ya!.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 3 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 3, adik-adik dapat melanjutkan melihat video 4."
          caption="(Sumber: youtube.com. INAHEALTH. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '27' && (
        <YouTubeEmbed
          embedId="73_zCmA5yWg"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 4 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 4 prosedur tetap, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Sahabat Tangguh !<br />
                Ada istilah Sedia payung sebelum hujan. Nah, pepatah ini tentu saja berlaku agar
                kita dapat bersiap sebelum bencana terjadi bencana. Perlengkapan yang harus
                tersedia: alat P3K, peluit,lampu senter, dokumen penting, pakaian, minuman dan
                makanan tahan lama, power bank.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 4 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 4, adik-adik dapat melanjutkan melihat video 5."
          // postVideoText={
          //   <Typography variant="body1" component="p">
          //     Setelah selesai melihat, mengamati, dan memperhatikan video 1,2,3, 4 Prosedur Tetap,
          //     tuliskan kesimpulan pada buku catatanmu!
          //     <ol>
          //       <li>Yang harus dilakukan ketika terjadi erupsi gunung api yaitu...</li>
          //       <li>Barang-barang yang harus ada di tas siaga bencana antara lain</li>
          //     </ol>
          //   </Typography>
          // }
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '28' && (
        <YouTubeEmbed
          embedId="aIxvmNAEhZU"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 4 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 5 prosedur tetap, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Negara Indonesia dilewati oleh jalur Gunung Api, itulah sebabnya Indonesia memiliki
                banyak Gunung Api aktif. Erupsi Gunung Api yang mengakibatkan dampak bagi dunia
                adalah erupsi Gunung Tambora pada tahun 1815, dan Gunung Krakatau pada tahun 1883.
                Erupsi gunung api merupakan bencana yang berbahaya. Erupsi Gunung Api mengakibatkan
                gempa vulkanik, aliran lava, awan panas, gas beracun, dan abu vulkanik. Oleh karena
                itu, kita harus mengetahui apa yang harus dilakukan ketika terjadi erupsi.
              </Typography>
              <Typography variant="body1" component="p">
                -
              </Typography>
              <Typography variant="body1" component="p">
                Siap siaga sebelum terjadi bencana:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Mengetahui dan mengamati tanda peringatan.</li>
                  <li>Mengenali jalur evakuasi.</li>
                  <li>Membuat rencana evakuasi keluarga.</li>
                  <li>Menyiapkan barang-barang penting yang harus dibawa.</li>
                  <li>
                    Bagi orang yang sedang berkunjung ke wilayah dekat Gunung Api, menanyakan tanda
                    peringatan yang digunakan dan status Gunung Api.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Ketika Terjadi Erupsi :
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Tetap tenang, jangan panik.</li>
                  <li>Masuk ke dalam rumah</li>
                  <li>
                    Tutup semua pintu dan jendela kecuali jika mendapatkan instruksi ke lokasi yang
                    aman.
                  </li>
                  <li>Mencari informasi melalui radio.</li>
                  <li>Ikuti instruksi Lembaga terkait.</li>
                  <li>
                    Jika tidak menemukan tempat berlindung, pergilah ke tempat yang tinggi, jauhi
                    jurang, lereng gunung, dan aliran lahar.
                  </li>
                  <li>
                    Di tempat terbuka: lindungi diri dari abu vulkanik, kenakan topi, kacamata
                    pelindung, baju lengan panjang, celana panjang, dan sepatu.
                  </li>
                  <li>
                    Waspadai gas beracun dengan memakai alat bantu pernafasan, masker atau kain
                    lemba.
                  </li>
                  <li>Jangan berada lebih rendah dari tanah.</li>
                  <li>Jangan memakai lensa kontak.</li>
                  <li>Jangan menyebrangi wilayah yang tertutup lahar.</li>
                  <li>Tetap tenang dan jangan panik.</li>
                  <li>Dengarkan informasi dari radio atau televisi.</li>
                  <li>Segera mengungsi jika mendapatkan instruksi dari Lembaga terkait.</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Setelah terjadi Erupsi Gunung Api:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Tetaplah di dalam rumah hingga terdengar tanda aman.</li>
                  <li>
                    Dengarkan informasi dari radio atau televisi, jangan terpengaruh kabar bohong.
                  </li>
                  <li>Minumlah air kemasan sampai ada informasi air keran aman dikonsumsi.</li>
                  <li>Jauhi wilayah yang terjadi hujan abu.</li>
                  <li>Hindari berkendara saat hujan abu.</li>
                  <li>Jika sudah aman, bersihkan atap dari timbunan debu vulkanik.</li>
                  <li>Segera obati luka saat terjadi erupsi</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Erupsi Gunung Api tidak perlu ditakuti tapi harus diwaspadai.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 5 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah selesai melihat, mengamati, dan memperhatikan video 1,2,3,4, dan 5 Prosedur
              Tetap, tuliskan kesimpulan pada buku catatanmu!
              <ol>
                <li>Yang harus dilakukan ketika terjadi erupsi gunung api yaitu...</li>
                <li>Barang-barang yang harus ada di tas siaga bencana antara lain</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. HUMAS BNPB. diinput dalam website SIPENA Februari 2023)"
        />
      )}
    </Box>
  );
}

export default ProsedurTetap;
