import React from 'react';
import {
  // Box,
  Container,
  Grid,
  Typography,
  // Button as MuiButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Colors } from 'utils/theme';

import kidsImage from 'assets/images/img/Student.svg';

// import { getLocalStorage } from 'utils/helpers/localStorage';
// import { useNavigate } from 'react-router-dom';
// import { Button } from '../LandingStyledComponents';
import { BannerContainer } from './StyledComponent';

function Banner() {
  const theme = useTheme();
  // const user = getLocalStorage('userLogin');
  // const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  // const handleButton = (path) => {
  //   navigate(path);
  // };

  return (
    <BannerContainer>
      <Container maxWidth="xl">
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item md={6} display="flex" flexDirection="column" gap="70px">
            <div>
              <Typography variant="h1" sx={{ color: Colors.primary.main }}>
                SIPENA
              </Typography>
              <Typography variant="h3" sx={{ color: Colors.black }}>
                Siaga Pendidikan Bencana
              </Typography>
              <Typography variant="body1">
                Belajar mengenal kebencanaan secara praktis kapanpun dan dimanapun.
              </Typography>
            </div>
            {/* <Box display={matches ? 'none' : 'block'}>
              {!user ? (
                <MuiButton
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ fontSize: 25, textTransform: 'capitalize', letterSpacing: '0' }}
                  style={{ padding: '16px 32px' }}
                  onClick={() => handleButton('login')}
                >
                  Coba Sekarang
                </MuiButton>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ fontSize: 25 }}
                  style={{ padding: '16px 64px' }}
                  onClick={() => handleButton('home')}
                >
                  Go to Home
                </Button>
              )}
            </Box> */}
          </Grid>
          <Grid item md={4}>
            <img src={kidsImage} alt="Kids with book" height={matches ? 315 : 500} />
          </Grid>
        </Grid>
      </Container>
    </BannerContainer>
  );
}

export default Banner;
