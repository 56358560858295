import { Box, Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Loader from 'components/molecules/Loader';
import Toast from 'components/atomics/Toast';
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleRegisterUser } from 'redux/actions/register.actions';

function RegisterForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [toast, setToast] = useState({
    open: false,
    message: 'Pendaftaran akun telah berhasil. Anda akan diarahkan ke halaman login.'
  });
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    role: null,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: ''
  });

  const handleRole = (role) => {
    setPayload({ ...payload, role });
  };

  const handleTextfield = (e) => {
    const { target } = e;
    const { name, value } = target;
    setPayload({
      ...payload,
      [name]: value
    });
  };

  const handleClose = () => {
    setToast({ ...toast, open: false });
    navigate('/login');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setToast({ ...toast, open: true });
    dispatch(handleRegisterUser(payload));
  };
  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      onSubmit={handleSubmit}
      width={isMobile ? '90%' : '500px'}
      border="1px solid #999"
      borderRadius="8px"
      p={3}
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Typography sx={{ color: '#000' }} variant="h6" textAlign="center">
        Daftar
      </Typography>

      {payload.role === null ? (
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleRole(20)}
            >
              Guru
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              type="button"
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleRole(30)}
            >
              Siswa
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body1">
              Anda mendaftar sebagai <strong>{payload?.role === 20 ? 'Guru' : 'Siswa'}</strong>.
            </Typography>
            <Button
              variant="text"
              disableRipple
              color="primary"
              onClick={() => setPayload({ ...payload, role: null })}
              sx={{
                textTransform: 'inherit',
                display: 'inline-block',
                fontSize: '16px',
                '&:hover': { background: 'none', textDecoration: 'underline' }
              }}
            >
              Ganti?
            </Button>
          </Box>

          <TextField
            label="Nama Depan"
            name="firstName"
            required
            fullWidth
            onChange={handleTextfield}
          />
          <TextField label="Nama Belakang" name="lastName" fullWidth onChange={handleTextfield} />
          <TextField label="Email" name="email" required fullWidth onChange={handleTextfield} />
          <TextField
            label="Username"
            name="username"
            required
            fullWidth
            onChange={handleTextfield}
          />
          <TextField
            label="Kata Sandi"
            type="password"
            name="password"
            fullWidth
            required
            onChange={handleTextfield}
          />
          <Button type="submit" variant="contained" size="large">
            Daftar
          </Button>
        </>
      )}
      <Button
        variant="text"
        disableRipple
        sx={{ textTransform: 'inherit', '&:hover': { background: 'none' } }}
        onClick={() => navigate('/login')}
      >
        <Typography variant="body">Sudah punya akun? Login</Typography>
      </Button>
      <Loader isLoading={loading} />
      <Toast open={toast.open} onClose={handleClose} message={toast.message} />
    </Box>
  );
}

export default RegisterForm;
