/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Typography
} from '@mui/material';

import tasSiagaImage from 'assets/images/img/tas-siaga.png';
import { Field, Form } from 'react-final-form';
import { handleTestScore } from 'redux/actions/class.actions';

function KuisTasSiaga() {
  const dispatch = useDispatch();
  const options = [
    {
      optionNo: 1,
      label: 'A. AIR MINUM',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 2,
      label: 'B. BISKUIT',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 3,
      label: 'C. OBAT-OBATAN',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 4,
      label: 'D. MASKER',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 5,
      label: 'E. ALAT MANDI',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 6,
      label: 'F. TISU',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 7,
      label: 'G. SANDAL',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 8,
      label: 'H. RADIO',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 9,
      label: 'I. GITAR',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 10,
      label: 'J. HEWAN PELIHARAAN',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 11,
      label: 'K. TOPI',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 12,
      label: 'L. SARUNG',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 13,
      label: 'M. SARDEN',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 14,
      label: 'N. KOREK',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 15,
      label: 'O. DONAT',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 16,
      label: 'P. UANG',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 17,
      label: 'Q. SEPATU RODA',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 18,
      label: 'R. PELUIT',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 19,
      label: 'S. MAINAN',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 20,
      label: 'T. SELIMUT',
      correctAnswer: false,
      poin: 10
    },
    {
      optionNo: 21,
      label: 'U. PAKAIAN GANTI',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 22,
      label: 'V. SENTER DAN BATERAI',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 23,
      label: 'W. SURAT PENTING',
      correctAnswer: true,
      poin: 10
    },
    {
      optionNo: 24,
      label: 'X. TELEPON GENGGAM',
      correctAnswer: true,
      poin: 10
    }
  ];

  const validate = (values) => {
    const errors = {};

    if (!values.tasSiaga) {
      errors.tasSiaga = 'Wajib dipilih';
    } else if (values.tasSiaga.length === 0) {
      errors.tasSiaga = 'Wajib dipilih';
    } else if (values.tasSiaga.length < 10 || values.tasSiaga.length > 15) {
      errors.tasSiaga = 'Pilih minimal 10 dan maksimal 15';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    let totalPoin = 0;
    const answers = values.tasSiaga;

    answers?.forEach((answer) => {
      options?.forEach((option) => {
        if (answer === option.optionNo) {
          // console.warn('Inside', { answer }, { option });
          if (option.correctAnswer) totalPoin += option.poin;
          else totalPoin -= option.poin;
        }
      });
    });

    await dispatch(
      handleTestScore({
        quizId: 'dea45ced-dee5-4a90-bf72-dd744d4fd0a3',
        score: totalPoin < 0 ? 0 : Math.round(totalPoin)
      })
    );

    window.location.reload();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Paper
              sx={{
                border: '1px solid #00000003',
                p: 4,
                minWidth: '480px',
                maxWidth: '1080px',
                m: '0px auto'
              }}
            >
              <Typography variant="body1">
                Pilihlah dengan cermat dan masukkanlah 10 benda yang menurutmu penting ke dalam tas
                siagamu!{' '}
              </Typography>
              <img src={tasSiagaImage} alt="Item Tas Siaga" width="100%" />
              <Box>
                <Field
                  name="tasSiaga"
                  type="checkbox"
                  render={({ meta }) => (
                    <React.Fragment>
                      {options.map((item) => (
                        <Field
                          key={item.optionNo}
                          name="tasSiaga"
                          type="checkbox"
                          value={item.optionNo}
                          render={({ input }) => (
                            <FormGroup {...input}>
                              <FormControlLabel control={<Checkbox />} label={item.label} />
                            </FormGroup>
                          )}
                        />
                      ))}
                      {meta.error && meta.touched && (
                        <span style={{ color: 'red' }}>{meta.error}</span>
                      )}
                    </React.Fragment>
                  )}
                />
              </Box>
              <Button type="submit" variant="contained" size="large">
                Submit
              </Button>
            </Paper>
          </form>
        )}
      />
    </Box>
  );
}

export default KuisTasSiaga;
