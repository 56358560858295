import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getLocalStorage } from 'utils/helpers/localStorage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import {
  handleDeleteQuestions,
  setIsEdit,
  setQuestionPopup,
  setSelectedQuestion
} from 'redux/actions/discussion.actions';

export function DiscussionCard({ dataset, handleClick }) {
  const dispatch = useDispatch();
  const user = getLocalStorage('userLogin');
  const userFullname = `${user.firstName} ${user.lastName}`;
  const showMenu = user.userId === dataset.userId;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleMenuItem = (item) => {
    if (item === 'edit') {
      dispatch(setSelectedQuestion(dataset));
      dispatch(setIsEdit(true));
      dispatch(setQuestionPopup(true));
    } else {
      dispatch(handleDeleteQuestions({ topicId: dataset.topicId }));
    }
  };

  return (
    <Paper variant="outlined">
      <Grid container sx={{ p: 3 }}>
        <Grid item md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link
              to={dataset?.url}
              onClick={handleClick}
              style={{
                textDecoration: 'none'
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {dataset?.title}
              </Typography>
            </Link>
            {showMenu ? (
              <IconButton
                id="more-button"
                onClick={handleOpenMenu}
                aria-controls={open ? 'more-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreHorizIcon />
              </IconButton>
            ) : null}
            <Menu
              id="more-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuItem('edit');
                  handleCloseMenu();
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuItem('delete');
                  handleCloseMenu();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="body2"
            style={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {dataset?.description}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <Box
            sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar sx={{ width: 32, height: 32 }} />
              <Box display="flex" gap="4px">
                <Typography variant="body2" mr={0}>
                  Dibuat oleh
                </Typography>
                <Typography variant="body2" color="primary">
                  {userFullname === dataset?.fullname
                    ? `${dataset?.fullname} (Kamu)`
                    : dataset?.fullname}
                </Typography>
              </Box>
              <Typography variant="body2">{moment(dataset?.createdAt).fromNow()}</Typography>
            </Box>
            <Box display="flex" gap="4px">
              <QuestionAnswerOutlinedIcon />
              <Typography>{dataset?.totalComments || 0}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

DiscussionCard.propTypes = {
  dataset: PropTypes.object.isRequired,
  handleClick: PropTypes.func
};

DiscussionCard.defaultProps = {
  handleClick: () => {}
};

export function AskQuestion({ open, onClose, onSend, onChange, isEdit, value }) {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box>
        <DialogTitle>{isEdit ? 'Edit Pertanyaan' : 'Buat Pertanyaan'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Pertanyaan"
            placeholder="Tuliskan hal yang ingin Anda tanyakan."
            id="title"
            name="title"
            onChange={onChange}
            value={value?.title}
            fullWidth
            sx={{ mb: 3, mt: 1 }}
          />
          <TextField
            label="Rincian Pertanyaan"
            placeholder="Jelaskan lebih rinci tentang masalah atau kendala Anda dalam memahami materi."
            onChange={onChange}
            multiline
            minRows={4}
            maxRows={10}
            id="description"
            name="description"
            fullWidth
            value={value?.description}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button variant="text" color="error" size="large" onClick={onClose}>
            Batal
          </Button>
          {isEdit ? (
            <Button variant="contained" color="primary" size="large" onClick={() => onSend('edit')}>
              Edit
            </Button>
          ) : (
            <Button variant="contained" color="primary" size="large" onClick={() => onSend('ask')}>
              Tanyakan
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
}

AskQuestion.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isEdit: PropTypes.bool,
  value: PropTypes.object
};

AskQuestion.defaultProps = {
  onChange: () => {},
  isEdit: false,
  value: {}
};
