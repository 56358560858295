/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getLocalStorage } from 'utils/helpers/localStorage';

export const requestInterceptors = async () => {
  await axios.interceptors.request.use(
    (config) => {
      config.headers['Content-Type'] = 'application/json';

      const accessToken = getLocalStorage('accessToken');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );
};

export const responseInterceptors = async () => {
  await axios.interceptors.response.use(
    (response) => {
      const isHtml = response.headers['Content-Type'] === 'text/html;charset=utf-8';

      return response;
    },
    (error) => error.response
  );
};
