import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Colors } from 'utils/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const faqList = [
    {
      id: 'faq1',
      question: 'Bagaimana Mendaftar di SIPENA?',
      answer:
        'Silahkan klik tombol daftar dan lengkapi data sesuai dengan tampilan yang ada di halaman daftar.'
    },
    {
      id: 'faq2',
      question: 'Berapa jumlah minimal karakter password?',
      answer: 'Jumlah minimal karakter password adalah 8 karakter dengan kombinasi huruf dan angka.'
    },
    {
      id: 'faq3',
      question: 'Apakah user name sama dengan email?',
      answer: 'User name sama dengan email yang digunakan pada saat mendaftar.'
    },
    {
      id: 'faq4',
      question: 'Apakah setelah mendaftar mendapatkan konfirmasi email?',
      answer: 'Setelah menyelesaikan pendaftaran maka anda akan menerima email konfirmasi.'
    },
    {
      id: 'faq5',
      question: 'Bagaimana jika sekolah belum terdaftar?',
      answer:
        'Silahkan klik pada laman pendaftaran guru, dibawah nama sekolah “Sekolah belum terdaftar? Klik disini” agar anda dapat mendaftarkan sekolah anda.'
    }
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container maxWidth="xl">
      <Box
        bgcolor={Colors.primary.main}
        height="150px"
        display="flex"
        alignItems="center"
        p={6}
        sx={{ color: Colors.white }}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'} color="inherit">
          Frequently Asked Questions (FAQs)
        </Typography>
      </Box>
      <Box my={6}>
        <Container maxWidth="md">
          {faqList.map((item) => (
            <Accordion
              key={item.id}
              expanded={expanded === item.id}
              onChange={handleChange(item.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={item.id}
                id={item.id}
              >
                {item.question}
              </AccordionSummary>
              <AccordionDetails>{item.answer}</AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Box>
    </Container>
  );
}

export default FAQ;
