import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Typography } from '@mui/material';

// Assets
import jalurEvakuasiImage from 'assets/images/img/jalur-evakuasi.png';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function RencanaEvakuasi() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Rencana Evakuasi
      </Typography>
      {contentId === '21' && (
        <Box sx={{ maxWidth: '70%' }}>
          <Typography variant="h6" component="h2">
            Pengertian
          </Typography>
          <Typography variant="body1" component="p">
            Rencana evakuasi di sekolah berhubungan dengan alat yang digunakan untuk evakuasi, apa
            saja yang perlu diselamatkan, dan bagaimana strategi penyelamatan warga sekolah dan
            asset penting sekolah.
            <ol
              style={{
                fontSize: '16px',
                color: '#000',
                listStylePosition: 'outside',
                marginLeft: '32px'
              }}
            >
              <li>Contoh alat yang digunakan untuk evakuasi - tandu, motor, mobil, truk, dll</li>
              <li>
                Contoh yang perlu diselamatkan - Siswa, Guru, Kepala Sekolah, Penjaga Sekolah,
                dokumen penting, asset penting sekolah (laptop).
              </li>
              <li>
                Contoh strategi penyelamatan - Ketika ada tanda-tanda bahaya/ bencana, guru
                menginstruksikan siswa untuk tetap tenang dan mengikuti arah jalur evakuasi menuju
                ke titik kumpul dan lokasi yang aman.
              </li>
            </ol>
          </Typography>
          <br />
          <img src={jalurEvakuasiImage} alt="Gambar Jalur Evalkuasi" width="100%" />
        </Box>
      )}

      {contentId === '22' && (
        <YouTubeEmbed
          embedId="C7EOB92A8P4"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 1 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 1 rencana evakuasi, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Rambu Bencana menurut Peraturan Kepala Badan Nasional Penanggulangan Bencana Nomor 7
                Tahun 2015 tentang rambu dan papan informasi bencana bencana adalah keterangan yang
                dipasang di kawasan rawan bencana berupa lambang huruf angka kalimat untuk memberi
                petunjuk peringatan dan larangan bagi setiap orang yang berada di kawasan rawan
                bencana. Contohnya rambu daerah rawan longsor akan memberikan peringatan longsoran
                batu tanah atau batu yang berjatuhan. Rambu petunjuk bencana terdiri dari rambu
                tempat titik kumpul sementara, rambut arah jalur evakuasi.
              </Typography>
              <Typography variant="body1" component="p">
                Evakuasi menurut Peraturan Kepala Badan Nasional penanggulangan bencana nomor 13
                tahun 2010 tentang pedoman pencarian dan evakuasi evakuasi adalah memindahkan korban
                bencana dari lokasi bencana ke tempat yang aman dan atau penampungan pertama untuk
                mendapatkan tindakan penanganan lebih lanjut.
              </Typography>
              <Typography variant="body1" component="p">
                Prosedur Evakuasi:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>
                    Tinggalkan gedung atau rumah bertingkat sesuai dengan petunjuk tim evakuasi
                    tanggap darurat.
                  </li>
                  <li>
                    Tetap tenang, jangan panik, saling membantu dan ikuti jalur evakuasi atau pintu
                    keluar disekitar.
                  </li>
                  <li>
                    Berkumpul di titik kumpul sambil menunggu instruksi selanjutnya dari tim
                    evakuasi.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah selesai melihat, mengamati, dan memperhatikan video 1 Rencana Evakuasi,
              tuliskan kesimpulan pada buku catatanmu!
              <ol>
                <li>Evakuasi yaitu...</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. KKN Salaman Magelang 2021. diinput dalam
            website SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default RencanaEvakuasi;
