import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, List, ListItemButton, Typography } from '@mui/material';
import '../../../MainContent.scss';

// Assets
import { Markup } from 'interweave';
import { Colors } from 'utils/theme';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function JenisBencana() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Jenis Bencana
      </Typography>
      {contentId === '6' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <Markup
              content={`
          <table class="tg">
            <thead>
              <tr>
                <th class="tg-0lax">Definisi</th>
                <th class="tg-0lax">Contoh</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="tg-0lax">Bencana alam adalah bencana yang diakibatkan oleh peristiwa atau serangkaian peristiwa yang disebabkan oleh alam (UU 24 Tahun 2007 Pasal 1)</td>
                <td class="tg-0lax">gempa bumi, tsunami, erupsi gunung api, banjir, kekeringan, angin topan, dan tanah longsor.</td>
              </tr>
              <tr>
                <td class="tg-0lax">Bencana nonalam adalah bencana yang diakibatkan oleh peristiwa atau rangkaian peristiwa nonalam (UU 24 Tahun 2007 Pasal 1)</td>
                <td class="tg-0lax">gagal teknologi, gagal modernisasi, epidemi, dan wabah penyakit.</td>
              </tr>
              <tr>
                <td class="tg-0lax">Bencana sosial adalah bencana yang diakibatkan oleh peristiwa atau serangkaian peristiwa yang diakibatkan oleh manusia  (Pasal 1)</td>
                <td class="tg-0lax">konflik sosial antarkelompok atau antarkomunitas masyarakat, dan teror.</td>
              </tr>
            </tbody>
          </table>
          `}
            />
          </Box>
          <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', paddingLeft: '24px' }}>
            <Typography variant="h6">Bahan Pembelajaran</Typography>
            <List>
              {[
                {
                  id: 1,
                  title: 'UU 24 Tahun 2007',
                  url: 'https://drive.google.com/file/d/1lESluLyhDwmr_D2n-K-Wz9V6qaQhILux/view?usp=sharing'
                }
              ].map((item) => (
                <Link key={item.id} href={item.url} sx={{ textDecoration: 'none' }}>
                  <ListItemButton
                    sx={{
                      background: Colors.primary.main,
                      color: Colors.white,
                      fontWeight: 800,
                      '&:hover': {
                        background: Colors.primary.dark
                      }
                    }}
                  >
                    {item.title}
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {contentId === '7' && (
        <YouTubeEmbed
          embedId="qjtEmeOYi30"
          preVideoText="Untuk memperdalam pengetahuan tentang jenis bencana, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah melihat video 1 Bapak/Ibu Guru dapat menyimpulkan :
              <ol>
                <li>Bencana alam adalah..., dan contohnya ...</li>
                <li>Bencana non alam adalah ..., dan contohnya...</li>
                <li>Bencana sosial adalah ..., dan contohnya...</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. Geography Channel. diinput dalam website
SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default JenisBencana;
