import styled from '@emotion/styled';
import { Container, Button as MuiButton } from '@mui/material';
import { Colors } from 'utils/theme';

export const AppbarContainer = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '75px',
  padding: '12px'
}));

export const Button = styled(MuiButton)(() => ({
  borderRadius: '12px 12px 0 12px',
  color: Colors.white,
  padding: '8px 24px'
}));
