// eslint-disable-next-line no-unused-vars
import { Box, Button, Pagination, Stack, Typography } from '@mui/material';
import Loader from 'components/molecules/Loader';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllQuestions, getTopicsByUser } from 'redux/actions/discussion.actions';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { DiscussionCard } from './StyledComponent';

function MyQuestions() {
  const dispatch = useDispatch();
  const user = getLocalStorage('userLogin');

  const { questions, isLoading } = useSelector((state) => state.discussion, shallowEqual);

  const [activeButton, setActiveButton] = useState(0);

  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(getTopicsByUser({ userId: user.userId }));
  }, []);

  const handleFilter = (index) => () => {
    setActiveButton(index);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
        <Loader isLoading={isLoading} />
        <Typography variant="body1" sx={{ color: '#00000055' }}>
          Total {questions?.totalQuestions || 0} Pertanyaan
        </Typography>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
          <Button
            variant={activeButton === 0 ? 'contained' : 'outlined'}
            sx={{ borderRadius: 4, px: 2, textTransform: 'inherit' }}
            size="small"
            onClick={handleFilter(0)}
          >
            Terbaru
          </Button>
          <Button
            variant={activeButton === 1 ? 'contained' : 'outlined'}
            sx={{ borderRadius: 4, px: 2, textTransform: 'inherit' }}
            size="small"
            onClick={handleFilter(1)}
          >
            Populer
          </Button>
          <Button
            variant={activeButton === 2 ? 'contained' : 'outlined'}
            sx={{ borderRadius: 4, px: 2, textTransform: 'inherit' }}
            size="small"
            onClick={handleFilter(2)}
          >
            Belum Dijawab
          </Button>
        </Box> */}
      </Box>
      {questions?.questions?.map((item) => (
        <DiscussionCard key={item.url} dataset={item} />
      ))}
      {/* <Stack spacing={2}>
        <Pagination count={10} showFirstButton showLastButton />
      </Stack> */}
    </>
  );
}

export default MyQuestions;
