import React from 'react';
import { useParams } from 'react-router-dom';

import PrePostTest from './PrePostTest';
import PengertianBencana from './PengertianBencana';
import JenisBencana from './JenisBencana';
import JenisAncamanDIY from './JenisAncamanDIY';
import ErupsiGunungMerapi from './ErupsiGunungMerapi';
import SistemPeringatanDini from './SistemPeringatanDini';
import RencanaEvakuasi from './RencanaEvakuasi';
import ProsedurTetap from './ProsedurTetap';

function Guru() {
  const { contentId } = useParams();

  return (
    <React.Fragment>
      {contentId === '1' ? <PrePostTest /> : null}
      {['2', '3', '4', '5'].includes(contentId) ? <PengertianBencana /> : null}

      {['6', '7'].includes(contentId) ? <JenisBencana /> : null}
      {['8', '9', '10', '11'].includes(contentId) ? <JenisAncamanDIY /> : null}
      {['12', '13', '14', '15'].includes(contentId) ? <ErupsiGunungMerapi /> : null}
      {['16', '17', '18', '19'].includes(contentId) ? <SistemPeringatanDini /> : null}
      {['20', '21'].includes(contentId) ? <RencanaEvakuasi /> : null}
      {['22', '23', '24', '25', '26', '27', '28', '29', '30'].includes(contentId) ? (
        <ProsedurTetap />
      ) : null}
      {contentId === '31' ? <PrePostTest isPost /> : null}
    </React.Fragment>
  );
}

export default Guru;
