import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, List, ListItem, ListItemButton, Typography } from '@mui/material';

// Assets
import petaDiyImage from 'assets/images/img/petaDiy.jpg';
import merapiImage from 'assets/images/img/merapi.jpg';
import { Colors } from 'utils/theme';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function ErupsiGunungMerapi() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Erupsi Gunung Api
      </Typography>
      {contentId === '12' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <img src={merapiImage} alt="Peta Jangkauan Merapi di DIY" width="100%" />
            <p>
              Gunung Merapi merupakan gunung api tipe strato, dengan ketinggian 2.980 meter. Secara
              administratif terletak pada 4 wilayah Kabupaten yaitu Kabupaten Sleman, DIY, dan
              Kabupaten Magelang, Boyolali, dan Klaten Provinsi Jawa Tengah.
            </p>
            <img src={petaDiyImage} alt="Peta Jangkauan Merapi di DIY" width="100%" />
          </Box>
          <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', paddingLeft: '24px' }}>
            <Typography variant="h6">Bahan Pembelajaran</Typography>
            <List>
              {[
                {
                  id: 1,
                  title: 'Pengenalan Gunung Api',
                  url: 'https://www.esdm.go.id/assets/media/content/Pengenalan_Gunung_Api.pdf'
                },
                {
                  id: 2,
                  title: 'Data Dasar Gunung Api - Gunung Merapi',
                  url: 'https://vsi.esdm.go.id/index.php/gunungapi/data-dasar-gunungapi/542-g-merapi'
                },
                {
                  id: 3,
                  title: 'Jurnal Erupsi Gunung Merapi',
                  url: 'https://drive.google.com/drive/folders/1tSYtP02nsQ_4prnp6jb9jZM-bhCgS6Ot?usp=sharing'
                }
              ].map((item) => (
                <Link
                  key={item.id}
                  href={item.url}
                  sx={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px'
                  }}
                >
                  <ListItem>
                    <ListItemButton
                      sx={{
                        background: Colors.primary.main,
                        color: Colors.white,
                        fontWeight: 800,
                        '&:hover': {
                          background: Colors.primary.dark
                        }
                      }}
                    >
                      {item.title}
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {contentId === '13' && (
        <YouTubeEmbed
          embedId="3tuZ7Y_TpMw"
          preVideoText="Untuk menambah pengetahuan tentang erupsi gunung merapi, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 2."
          caption="(Sumber: youtube.com. Kementrian ESDM. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '14' && (
        <YouTubeEmbed
          embedId="GlzpBn0CSOY"
          preVideoText="Untuk menambah pengetahuan tentang erupsi gunung merapi, Bapak/Ibu Guru dapat melihat video 2 berikut ini."
          postVideoText="Setelah melihat video 2, Bapak/Ibu Guru dapat melanjutkan ke Video 3."
          caption="(Sumber: youtube.com. Pusat Krisis Kesehatan Kemenkes. diinput
            dalam website SIPENA November 2022)"
        />
      )}

      {contentId === '15' && (
        <YouTubeEmbed
          embedId="iPZlsZrm5Dw"
          preVideoText="Untuk menambah pengetahuan tentang erupsi gunung merapi, Bapak/Ibu Guru dapat melihat video 3 berikut ini."
          postVideoText="Setelah melihat video 1, 2 dan 3, Bapak/Ibu Guru dapat menyimpulkan erupsi gunung api adalah..."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default ErupsiGunungMerapi;
