import {
  SET_ERROR,
  SET_IS_USER_LOGIN,
  SET_LOADING,
  SET_USER_LOGIN,
  SET_SCHOOL_LIST
} from '../actions/login.actions';

const initialState = {
  error: { isError: false, message: '' },
  isLoading: false,
  isUserLogin: false,
  userLogin: {},
  schools: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        error: payload
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SCHOOL_LIST:
      return {
        ...state,
        schools: payload
      };
    case SET_USER_LOGIN: {
      return {
        ...state,
        userLogin: payload
      };
    }
    case SET_IS_USER_LOGIN: {
      return {
        ...state,
        isUserLogin: payload
      };
    }
    default:
      return state;
  }
};
