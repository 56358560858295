import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Colors } from 'utils/theme';

import kurikulumTextHeader from 'assets/images/img/kurikulum-text-header.png';

function Curriculum() {
  return (
    <Box>
      <Box
        sx={{
          background: Colors.primary.main,
          height: '400px'
        }}
      >
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" height="400px">
            <Grid container spacing={3} py={2} alignItems="stretch">
              <Grid item md={6}>
                <img src={kurikulumTextHeader} alt="Sipena text logo" width="100%" />
              </Grid>
              <Grid
                item
                md={6}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
              >
                <Typography variant="body1" sx={{ color: Colors.white }}>
                  Pembelajaran integrasi pendidikan bencana memerlukan kurikulum yang diintegrasikan
                  dengan pendidikan bencana. Contoh kurikulum integrasi bencana dapat di downlod
                  sesuai dengan jenis kurikulum 2013 atau Kurikulum Merdeka Belajar. Inovasi sangat
                  diperlukan dalam pembelajaran integrasi pendidikan bencana.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box display="flex" flexDirection="column" gap="64px" py={4}>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Kurikulum 2013</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <Link
                    href="https://drive.google.com/drive/u/5/folders/1eBHXLoGobn2xjzMumpUU7mdK__TTqD1H"
                    target="_blank"
                  >
                    <ListItemButton>
                      <ListItemText primary="Silabus" />
                    </ListItemButton>
                  </Link>
                  <Link
                    href="https://drive.google.com/drive/u/5/folders/1Y0USQTKqGD9ChqpCj8ArL9MfhNF9Di0C"
                    target="_blank"
                  >
                    <ListItemButton>
                      <ListItemText primary="Rencana Pelaksanaan Pembelajaran (RPP)" />
                    </ListItemButton>
                  </Link>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>Kurikulum Merdeka Belajar</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  <Link
                    href="https://drive.google.com/drive/u/5/folders/1XpVqVlzGHJKpk0ho8xkZTtm8wLN5FWXU"
                    target="_blank"
                  >
                    <ListItemButton>
                      <ListItemText primary="Alur Tujuan Pembelajaran (ATP)" />
                    </ListItemButton>
                  </Link>
                  <Link
                    href="https://drive.google.com/drive/u/5/folders/1wTPk8XVo0d-c7ip_v1Y_meTZdgyEbdZw"
                    target="_blank"
                  >
                    <ListItemButton>
                      <ListItemText primary="Capaian Pembelajaran (CP)" />
                    </ListItemButton>
                  </Link>
                  <Link
                    href="https://drive.google.com/drive/u/5/folders/1CBaC9xmxO0RIHOUBdGIvwhZNlI_xbEwg"
                    target="_blank"
                  >
                    <ListItemButton>
                      <ListItemText primary="Modul Ajar" />
                    </ListItemButton>
                  </Link>
                </List>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Container>
    </Box>
  );
}

export default Curriculum;
