import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Typography } from '@mui/material';

// Assets
import landMapImage from 'assets/images/img/land.png';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';
import './style.scss';

function PengertianBencana() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Pengertian Bencana
      </Typography>
      {contentId === '3' && (
        <Box sx={{ maxWidth: '70%' }}>
          <Typography variant="body1" component="p">
            <strong>Indonesia merupakan negara yang rawan bencana.</strong>
            <br />
            Hal ini disebabkan oleh letak geografis Indonesia Yang berada dalam jalur ring of fire
            alias cincin api.
          </Typography>
          <br />
          <img src={landMapImage} alt="Gambar Peta" width="100%" />
          <br />
          <br />
          <Typography variant="body1" component="p">
            Ring of fire adalah deretan gunung berapi di kawasan Pasifik.
            <br />
            <br />
            <strong>
              Bahkan dari 500 gunung api di seluruh dunia, 129 di antaranya berada di Indonesia.
            </strong>
            <br />
            <br />
            Sedangkan menurut risiko bencananya, Indonesia ada di peringkat 1 untuk risiko tsunami
            dan tanah longsor, dan peringkat 3 untuk risiko gempa.
            <br />
            <br />
            Itu berarti, kita yang berada di Indonesia akan terus berhadapan dengan risiko bencana
            (Save The Children, 2019).
            <br />
            <br />
            Oleh sebab itu, penting bagi kita untuk mengetahui apa yang harus dilakukan sebelum,
            saat , dan setelah terjadi bencana.
          </Typography>
        </Box>
      )}

      {contentId === '4' && (
        <YouTubeEmbed
          embedId="yAJxZADUtxA"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 1 pengertian bencana, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Bencana adalah peristiwa atau rangkaian peristiwa yang mengancam dan mengganggu
                kehidupan dan penghidupan masyarakat yang disebabkan baik oleh faktor alam dan atau
                non alam maupun faktor manusia sehingga mengakibatkan timbulnya korban jiwa manusia,
                kerusakan lingkungan, kerugian harta benda dan dampak psikologis (UU Nomor 24 tahun
                2007.
              </Typography>
              <Typography variant="body1" component="p">
                Bencana dibagi menjadi 3 : Bencana Alam, Bencana Non Alam, dan Bencana Sosial.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 1, adik-adik dapat melanjutkan melihat video 2."
          // postVideoText={<Typography variant="body1">Tes</Typography>}
          caption="(Sumber: youtube.com. Kedaruratan Logistik. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '5' && (
        <YouTubeEmbed
          embedId="qjtEmeOYi30"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 2 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 2 pengertian bencana, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Pengertian bencana menurut undang-undang nomor 24 tahun 2007 tentang penanggulangan
                bencana, Bencana adalah peristiwa atau rangkaian peristiwa yang mengancam dan
                mengganggu kehidupan dan penghidupan masyarakat yang disebabkan baik oleh faktor
                alam dan atau non alam maupun faktor manusia sehingga mengakibatkan timbulnya korban
                jiwa manusia, kerusakan lingkungan, kerugian harta benda dan dampak psikologis.
              </Typography>
              <Typography variant="body1" component="p">
                Bencana dibagi menjadi 3 yaitu:
              </Typography>
              <table className="pengertian">
                <thead>
                  <tr>
                    <th>Definisi</th>
                    <th>Contoh</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Bencana alam adalah bencana yang diakibatkan oleh peristiwa atau serangkaian
                      peristiwa yang disebabkan oleh alam (UU 24 Tahun 2007 Pasal 1)
                    </td>
                    <td>
                      Gempa bumi, tsunami, erupsi gunung api, banjir, kekeringan, angin topan, dan
                      tanah longsor.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Bencana nonalam adalah bencana yang diakibatkan oleh peristiwa atau rangkaian
                      peristiwa nonalam (UU 24 Tahun 2007 Pasal 1)
                    </td>
                    <td>Gagal teknologi, gagal modernisasi, epidemi, dan wabah penyakit.</td>
                  </tr>
                  <tr>
                    <td>
                      Bencana sosial adalah bencana yang diakibatkan oleh peristiwa atau serangkaian
                      peristiwa yang diakibatkan oleh manusia (Pasal 1)
                    </td>
                    <td>
                      Konflik sosial antar kelompok atau antar komunitas masyarakat, dan teror.
                    </td>
                  </tr>
                </tbody>
              </table>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 2 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 2, adik-adik dapat melanjutkan melihat video 3."
          caption="(Sumber: youtube.com. Geography Channel. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '6' && (
        <YouTubeEmbed
          embedId="1Ess9QudIEE"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 3 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 3 pengertian bencana, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Siklus Penanggulangan Bencana yaitu :
                <ol style={{ listStylePosition: 'outside', marginLeft: '32px' }}>
                  <li>
                    Prabencana - Sebelum terjadinya bencana.
                    <br />
                    Contohnya : simulasi bencana, pelatihan bencana.
                  </li>
                  <li>
                    Tanggap Darurat - Pada saat terjadinya bencana.
                    <br />
                    Contohnya : penyelamatan dan evakuasi.
                  </li>
                  <li>
                    Pasca Bencana - Setelah terjadinya bencana
                    <br />
                    Contohnya : pembangunan kembali gedung sekolah yang rusak.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 3 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah selesai melihat, mengamati, dan memperhatikan video 1,2,3 Pengertian Bencana,
              tuliskan kesimpulan pada buku catatanmu!
              <ol>
                <li>Bencana yaitu...</li>
                <li>Bencana dibagi menjadi 3 yaitu bencana ..., ..., dan ...</li>
                <li>Siklus penanggulangan bencana yaitu...</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. Direktorat Mitigasi Bencana. diinput dalam
            website SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default PengertianBencana;
