import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { Field, useField, Form } from 'react-final-form';
import { handleFirstLogin } from 'redux/actions/login.actions';
import { clearLocalStorage, getLocalStorage } from 'utils/helpers/localStorage';
import { acceptNumberOnly } from 'utils/helpers/general';
import { handleNewSchool } from 'redux/actions/schools.action';
import AddSchool from './StyledComponents';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

// Custom Component
// eslint-disable-next-line react/prop-types
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

// eslint-disable-next-line react/prop-types
function CustomRadioGroup({ name, label, children }) {
  return (
    <Box>
      <Typography variant="body1" component="legend">
        {label}
      </Typography>
      <RadioGroup sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>{children}</RadioGroup>
      <Field name={name} render={({ meta }) => <FormControl>{showError(meta)}</FormControl>} />
    </Box>
  );
}

function Student() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { schools } = useSelector((state) => state.login, shallowEqual);

  const userLogin = getLocalStorage('userLogin');
  const isTeacher = userLogin?.role === 'guru';

  const [addSchoolPopup, setAddSchoolPopup] = React.useState(false);
  const [schoolValue, setSchoolValue] = React.useState({
    name: '',
    address: ''
  });

  const validate = (values) => {
    const errors = {};

    if (isTeacher) {
      if (!values.nik) errors.nik = 'Wajib diisi';
      else if (values.nik.length !== 16) errors.nik = 'NIK berisi 16 digit';

      if (!values.school) errors.school = 'Wajib diisi';
      if (!values.lastEducation) errors.lastEducation = 'Wajib diisi';
    }

    if (!values.firstName) errors.firstName = 'Wajib diisi.';
    if (!values.gender) errors.gender = 'Wajib diisi.';
    if (!values.age) errors.age = 'Wajib diisi.';
    if (!values.teach) errors.teach = 'Wajib diisi.';
    if (!values.phone) errors.phone = 'Wajib diisi.';

    return errors;
  };

  const handleLogout = () => {
    clearLocalStorage();
    navigate('/');
  };

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setSchoolValue({ ...schoolValue, [name]: value });
  };

  const closePopup = () => {
    setAddSchoolPopup(false);
  };

  const sendSchool = async () => {
    await dispatch(handleNewSchool(schoolValue));
    navigate(0);
  };

  const onSubmit = async (values) => {
    // console.warn('Values', values);
    if (userLogin.role === 'guru') {
      dispatch(handleFirstLogin({ ...values, employmentStatus: Number(values.employmentStatus) }));
    } else {
      dispatch(handleFirstLogin(values));
    }
  };

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ my: 6 }}>
              <Paper elevation={1} sx={{ borderRadius: 2, p: 6 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Lengkapi Data Diri</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      defaultValue={userLogin.firstName}
                      render={({ input, meta }) => (
                        <TextField
                          label="Nama Depan"
                          disabled
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      defaultValue={userLogin.lastName}
                      name="lastName"
                      render={({ input, meta }) => (
                        <TextField
                          label="Nama Belakang"
                          disabled={userLogin?.lastName}
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="nik"
                      format={(value) => acceptNumberOnly(value)}
                      render={({ input, meta }) => (
                        <TextField
                          label="NIK"
                          fullWidth
                          sx={{ width: 'calc(100% - 12px)' }}
                          {...input}
                          type="text"
                          inputProps={{ maxLength: 16 }}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      defaultValue={userLogin.age}
                      name="age"
                      render={({ input, meta }) => (
                        <TextField
                          label="Usia"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      defaultValue={userLogin.phone}
                      name="phone"
                      render={({ input, meta }) => (
                        <TextField
                          label="No Telepon/WA"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomRadioGroup name="gender" label="Jenis Kelamin">
                      <CustomRadio name="gender" value="M" label="Laki-Laki" />
                      <CustomRadio name="gender" value="F" label="Perempuan" />
                    </CustomRadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="school"
                      render={({ input }) => (
                        <FormControl fullWidth>
                          <InputLabel id="school-select-label">Nama Sekolah</InputLabel>
                          <Select
                            labelId="school-select-label"
                            id="school-select"
                            {...input}
                            label="Nama Sekolah"
                          >
                            {schools?.map((item) => (
                              <MenuItem value={item.school_id} key={item.school_id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Typography
                      variant="body1"
                      role="button"
                      color="primary"
                      sx={{
                        cursor: 'pointer',
                        mt: 1,
                        ml: 1,
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => setAddSchoolPopup(true)}
                    >
                      Sekolah belum terdaftar? Klik disini
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      defaultValue={userLogin.teach}
                      name="teach"
                      render={({ input, meta }) => (
                        <TextField
                          label="Guru Kelas"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="lastEducation"
                      render={({ input }) => (
                        <FormControl fullWidth>
                          <InputLabel id="last-education-select-label">
                            Pendidikan Terakhir
                          </InputLabel>
                          <Select
                            labelId="last-education-select-label"
                            id="last-education-select"
                            {...input}
                            label="Pendidikan Terakhir"
                          >
                            <MenuItem value="D3">D3</MenuItem>
                            <MenuItem value="S1">S1</MenuItem>
                            <MenuItem value="S2">S2</MenuItem>
                            <MenuItem value="S3">S3</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={13}>
                    <Field
                      name="address"
                      render={({ input, meta }) => (
                        <TextField
                          label="Alamat"
                          fullWidth
                          multiline
                          minRows={3}
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="outlined"
                      size="large"
                      sx={{ mr: 3 }}
                      onClick={handleLogout}
                    >
                      Batal
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                      Kirim
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </form>
        )}
      />
      <AddSchool
        open={addSchoolPopup}
        onClose={closePopup}
        value={schoolValue}
        onChange={handleChange}
        onSend={sendSchool}
      />
    </Container>
  );
}

export default Student;
