import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { Box, CssBaseline, Toolbar } from '@mui/material';
import Appbar from 'components/organisms/Appbar';
import Footer from 'components/organisms/Footer';
import { clearLocalStorage, getLocalStorage } from 'utils/helpers/localStorage';
import { IdleTimer } from 'components/atomics';
import moment from 'moment';

moment.updateLocale('id', {
  relativeTime: {
    future: 'pada %s',
    past: '%s lalu',
    s: 'beberapa detik',
    ss: '%d detik',
    m: '%d menit',
    mm: '%d menit',
    h: '%d jam',
    hh: '%d jam',
    d: '%d hari',
    dd: '%d hari',
    M: '%d bulan',
    MM: '%d bulan',
    y: '%d tahun',
    yy: '%d tahun'
  }
});

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  const idleRef = useRef(null);

  const timeout = 1000 * 60 * 20;

  const token = getLocalStorage('accessToken');
  const user = getLocalStorage('userLogin');
  const isFirstLogin = getLocalStorage('firstLogin');

  useEffect(() => {
    document.title = 'SIPENA - Siaga Pendidikan Bencana';
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (user) {
      if (isFirstLogin) {
        navigate('/first-login');
      }
    } else {
      navigate('/login');
    }
  }, [user]);

  const handleLogout = () => {
    clearLocalStorage();
    navigate('/');
  };

  useEffect(() => {
    const decoded = jwtDecode(token);
    if (decoded.exp < (new Date().getTime() + 1) / 1000) handleLogout();
  }, [token]);

  const onIdle = () => {
    handleLogout();
  };

  return (
    <Box style={{ overflow: 'hidden' }}>
      <CssBaseline />
      <Appbar onLogout={handleLogout} />
      <Toolbar />
      <Box minHeight={640}>
        <Outlet />
      </Box>
      <IdleTimer ref={idleRef} timeout={timeout} onIdle={onIdle} />
      {!location.pathname.includes('/class') && <Footer />}
    </Box>
  );
}

export default Layout;
