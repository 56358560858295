/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSchools } from 'redux/actions/login.actions';

import { getLocalStorage } from 'utils/helpers/localStorage';
import Student from './components/Student';
import Teachers from './components/Teachers';

function FirstLogin() {
  const userLogin = getLocalStorage('userLogin');
  const isFirstLogin = getLocalStorage('firstLogin');

  const disptach = useDispatch();
  const navigate = useNavigate();

  const fetchRef = React.useRef();

  useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    if (!isFirstLogin) {
      navigate('/home');
    }
    disptach(getSchools());
  }, []);

  return (
    <div>
      {userLogin?.role === 'siswa' ? <Student /> : userLogin?.role === 'guru' ? <Teachers /> : null}
    </div>
  );
}

export default FirstLogin;
