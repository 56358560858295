import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSchools } from 'redux/actions/login.actions';
import { handleGetProfile } from 'redux/actions/users.actions';

import { getLocalStorage } from 'utils/helpers/localStorage';
import Student from './components/Student';
import Teachers from './components/Teachers';

function Settings() {
  const userLogin = getLocalStorage('userLogin');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchRef = React.useRef();

  useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    // if (!isFirstLogin) {
    //   navigate('/home');
    // }
    dispatch(handleGetProfile());
    dispatch(getSchools());
  }, []);

  return <div>{userLogin.role === 'siswa' ? <Student /> : <Teachers />}</div>;
}

export default Settings;
