import { Box, Container, Grid, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { Colors } from 'utils/theme';

// Assets
import sipenaTextHeader from 'assets/images/img/sipena-text-header.png';

// Local component
import NextClass from './components/NextClass';

function Home() {
  const user = getLocalStorage('userLogin');
  const [greetings, setGreetings] = useState({
    message1: '',
    message2: ''
  });

  useEffect(() => {
    if (user.role === 'guru') {
      setGreetings({
        message1: 'Selamat Datang Bapak/ Ibu Guru Agen Pendidikan Bencana.',
        message2: 'Ayo siapkan siswamu menjadi siswa yang TANGGUH BENCANA!'
      });
    } else {
      setGreetings({
        message1: 'Selamat Datang Siswa Tangguh Bencana',
        message2: 'Mau jadi Siswa Tangguh Bencana??? Ayo belajar bersama dengan SIPENA SISWA!!!'
      });
    }
  }, [user]);

  return (
    <Box sx={{ background: '#00000005' }}>
      <Box
        sx={{
          background: Colors.primary.main,
          height: '400px'
        }}
      >
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center" height="400px">
            <Grid container spacing={3} py={2} alignItems="stretch">
              <Grid item md={6}>
                <img src={sipenaTextHeader} alt="Sipena text logo" width="100%" />
              </Grid>
              <Grid
                item
                md={6}
                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
              >
                <Typography variant="h4" sx={{ color: Colors.white }}>
                  {greetings.message1}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: Colors.white, opacity: 0.9 }}>
                  {greetings.message2}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Toolbar sx={{ background: '#fff', borderBottom: '1px solid #00000019' }} />
      <Container
        maxWidth="lg"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 80
        }}
      >
        <Box px={6} sx={{ display: 'flex', flexDirection: 'column', gap: '120px', py: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
            <NextClass
              title="Gunung Api"
              course={{ title: 'Gunung Api', creator: 'Zela Septikasari, M.Sc.,M.Pd.' }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
