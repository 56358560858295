import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button as MuiButton,
  Menu,
  MenuItem,
  Typography,
  Link as MuiLink
} from '@mui/material';
import { Colors } from 'utils/theme';
import { Link } from 'react-router-dom';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { AppbarContainer, Button } from './StyledComponent';

function AppBarDesktop({ clickMenu }) {
  const user = getLocalStorage('userLogin');

  const [offset, setOffset] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      component="nav"
      sx={{ background: offset > 100 ? 'white' : 'transparent' }}
      elevation={0}
    >
      <AppbarContainer maxWidth="xl">
        <Typography variant="h4" sx={{ color: Colors.primary.main }}>
          SIPENA
        </Typography>
        <Box display="flex" gap={3}>
          <MuiButton
            sx={{
              color: offset > 100 ? Colors.primary.main : Colors.black,
              fontWeight: 600
            }}
            variant="text"
            onClick={clickMenu}
          >
            Tentang
          </MuiButton>
          <MuiButton
            sx={{
              color: offset > 100 ? Colors.primary.main : Colors.black,
              fontWeight: 600
            }}
            variant="text"
            onClick={handleClick}
          >
            Panduan
          </MuiButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              dense
              sx={{
                fontWeight: 600,
                '&:hover': {
                  background: Colors.primary.main,
                  color: Colors.white,
                  '& 	.MuiLink-root': {
                    color: Colors.white
                  }
                }
              }}
            >
              <MuiLink
                href="https://drive.google.com/drive/u/5/folders/1oTG4eLiLr9t6mEF4cm4Ttu0do-djhybf"
                target="_blank"
                sx={{
                  textDecoration: 'none'
                }}
              >
                Panduan SIPENA Guru
              </MuiLink>
            </MenuItem>
            <MenuItem
              dense
              sx={{
                fontWeight: 600,
                '&:hover': {
                  background: Colors.primary.main,
                  color: Colors.white,
                  '& 	.MuiLink-root': {
                    color: Colors.white
                  }
                }
              }}
            >
              <MuiLink
                href="https://drive.google.com/drive/u/5/folders/13OkNuM2GkvJ9RoRtj9M4KMtzwImJa8Ti"
                target="_blank"
                sx={{
                  textDecoration: 'none'
                }}
              >
                Panduan SIPENA Siswa
              </MuiLink>
            </MenuItem>
            <MenuItem
              dense
              sx={{
                fontWeight: 600,
                '&:hover': {
                  background: Colors.primary.main,
                  color: Colors.white,
                  '& 	.MuiLink-root': {
                    color: Colors.white
                  }
                }
              }}
            >
              <MuiLink
                href="https://youtu.be/mVX0yOZ1ctc"
                target="_blank"
                sx={{
                  textDecoration: 'none'
                }}
              >
                Panduan SIPENA (Video)
              </MuiLink>
            </MenuItem>
          </Menu>
          {!user ? (
            <Box display="flex" gap="16px">
              <Link to="/login" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                  Login
                </Button>
              </Link>
              <Link to="/register" style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                  Daftar
                </Button>
              </Link>
            </Box>
          ) : (
            <React.Fragment>
              {user?.role === 'admin' ? (
                <Link to="/admin" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary">
                    Dashboard
                  </Button>
                </Link>
              ) : (
                <Link to="/home" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary">
                    Beranda
                  </Button>
                </Link>
              )}
            </React.Fragment>
          )}
        </Box>
      </AppbarContainer>
    </AppBar>
  );
}

AppBarDesktop.propTypes = {
  clickMenu: PropTypes.func.isRequired
};

export default AppBarDesktop;
