import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { requestInterceptors, responseInterceptors } from 'utils/middleware/interceptors';
import rootReducer from './rootReducer';

requestInterceptors();
responseInterceptors();

export const history = createBrowserHistory({
  basename: ''
});

const initialState = {};
const enhancers = [];
const middleware = [thunk];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composeEnhancer = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer(), initialState, composeEnhancer);

history.listen(() => {
  window.scrollTo(0, 0);
});

export default store;
