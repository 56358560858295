/* eslint-disable react/prop-types */
import React from 'react';

import { Box, Typography } from '@mui/material';

function YouTubeEmbed({ preVideoText, postVideoText, embedId, caption }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '80%'
      }}
    >
      {typeof preVideoText === 'string' ? (
        <Typography variant="body1" component="p">
          {preVideoText}
        </Typography>
      ) : (
        <React.Fragment>{preVideoText}</React.Fragment>
      )}
      <figure style={{ margin: '24px 0', fontSize: '12px' }}>
        <iframe
          width="80%"
          style={{
            width: '100%',
            aspectRatio: '16/9'
          }}
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        <figcaption>{caption}</figcaption>
      </figure>
      {typeof postVideoText === 'string' ? (
        <Typography variant="body1" component="p">
          {postVideoText}
        </Typography>
      ) : (
        <React.Fragment>{postVideoText}</React.Fragment>
      )}
    </Box>
  );
}

export default YouTubeEmbed;
