import { serviceCheckQuiz, serviceSubmitQuiz } from 'utils/api';

export const SET_SELECTED_MENU = 'classAction/SET_SELECTED_MENU';
export const SET_TEST_SCORES = 'classAction/SET_TEST_SCORE';

export const setSelectedMenu = (payload) => ({
  type: SET_SELECTED_MENU,
  payload
});

export const setTestScore = (payload) => ({
  type: SET_TEST_SCORES,
  payload
});

export const handleTestScore = (payload) => async (dispatch) => {
  try {
    const { data, status } = await serviceSubmitQuiz(payload);

    if (status === 201) {
      dispatch(setTestScore(data.detail));
    } else {
      dispatch(setTestScore(data.detail));
    }
  } catch (error) {
    // console.log(error);
  }
};

export const handleCheckTest = (payload) => async (dispatch) => {
  try {
    const { data, status } = await serviceCheckQuiz(payload);

    if (status === 200) {
      dispatch(setTestScore(data));
    } else {
      dispatch(setTestScore({}));
    }
  } catch (error) {
    // console.log(error);
  }
};
