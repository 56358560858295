import secureLocalStorage from 'react-secure-storage';

const getLocalStorage = (key) => secureLocalStorage.getItem(key);

const setLocalStorage = (key, value) => secureLocalStorage.setItem(key, value);

const removeLocalStorage = (key) => secureLocalStorage.removeItem(key);

const clearLocalStorage = () => secureLocalStorage.clear();

export { getLocalStorage, setLocalStorage, removeLocalStorage, clearLocalStorage };
