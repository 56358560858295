import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { handleTestScore, handleCheckTest } from 'redux/actions/class.actions';

import { Box, Button, Paper, Typography } from '@mui/material';
import { Form } from 'react-final-form';

import {
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirdteen,
  Fourteen,
  Fifteen,
  Sixteen,
  Seventeen,
  Eighteen,
  Nineteen,
  Twenty
} from './questions';

function PrePostTest({ isPost }) {
  const dispatch = useDispatch();

  const { testScore } = useSelector((state) => state.classes, shallowEqual);

  const [activeStep, setActiveStep] = useState(0);

  const qna = [
    {
      questionNo: 1,
      correctAnswer: '2',
      poin: 5
    },
    {
      questionNo: 2,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 3,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 4,
      correctAnswer: '4',
      poin: 5
    },
    {
      questionNo: 5,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 6,
      correctAnswer: '1',
      poin: 5
    },
    {
      questionNo: 7,
      correctAnswer: '2',
      poin: 5
    },
    {
      questionNo: 8,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 9,
      correctAnswer: '4',
      poin: 5
    },
    {
      questionNo: 10,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 11,
      correctAnswer: '4',
      poin: 5
    },
    {
      questionNo: 12,
      correctAnswer: '1',
      poin: 5
    },
    {
      questionNo: 13,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 14,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 15,
      correctAnswer: '4',
      poin: 5
    },
    {
      questionNo: 16,
      correctAnswer: '4',
      poin: 5
    },
    {
      questionNo: 17,
      correctAnswer: '2',
      poin: 5
    },
    {
      questionNo: 18,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 19,
      correctAnswer: '3',
      poin: 5
    },
    {
      questionNo: 20,
      correctAnswer: '4',
      poin: 5
    }
  ];

  const checkTestRef = useRef(false);

  useEffect(() => {
    if (checkTestRef.current) return;
    checkTestRef.current = true;
    if (!isPost) {
      dispatch(handleCheckTest({ quizId: 'fdd4ed2f-0b45-4c48-a8b6-9d14bd58da92' }));
    } else {
      dispatch(handleCheckTest({ quizId: 'c30b63b6-77e1-4467-9bff-275ba138d40e' }));
    }
  }, [isPost]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handlePrevButton = () => {
    if (activeStep !== 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleNextButton = () => {
    if (activeStep < 20) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const onSubmit = async (values) => {
    const map = await new Map(Object.entries(values));
    const theArray = Array.from(map);
    let totalPoin = 0;
    if (activeStep < 20) {
      setActiveStep((prev) => prev + 1);
    } else {
      theArray.forEach((answer) => {
        qna.forEach((item) => {
          if (answer[0] === `q${item.questionNo}`) {
            // console.warn("It's true", answer[1], item.correctAnswer);
            if (answer[1] === item.correctAnswer) {
              totalPoin += item.poin;
            }
          }
        });
      });

      if (!isPost) {
        await dispatch(
          handleTestScore({ quizId: 'fdd4ed2f-0b45-4c48-a8b6-9d14bd58da92', score: totalPoin })
        );
        window.location.reload();
      } else {
        await dispatch(
          handleTestScore({ quizId: 'c30b63b6-77e1-4467-9bff-275ba138d40e', score: totalPoin })
        );
        window.location.reload();
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Typography variant="h4" component="h1">
        {!isPost ? 'Pre-Test' : 'Post-Test'}
      </Typography>
      {activeStep === 0 && (
        <Box sx={{ display: 'flex' }}>
          {!isPost ? (
            <React.Fragment>
              {/* <Typography variant="body1" component="p">
                Sebelum mengakses materi silahkan mengerjakan Pretest dengan menekan link berikut :{' '}
              </Typography>
              <Link
                href="https://docs.google.com/forms/d/1kk4SeeaHd46c-Fm7NdEdBzvG3NbzM7xuSjl50Ka53dY/edit#"
                target="_blank"
                sx={{ ml: '5px' }}
              >
                <Typography variant="body1" component="p">
                  Pre-Test
                </Typography>
              </Link> */}

              {testScore?.test_code === 'PRTSIS' && testScore?.completed ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '25px',
                    width: '100%'
                  }}
                >
                  <Paper
                    sx={{
                      border: '1px solid #00000003',
                      p: 4,
                      m: '0 auto',
                      minWidth: '480px',
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="body1">
                      Nilai Pre Test: {testScore?.test_score} poin
                    </Typography>
                    <Typography variant="body1">Pada percobaan ke: {testScore?.attempt}</Typography>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => setActiveStep(1)}
                      sx={{ mt: 3 }}
                    >
                      Ulangi
                    </Button>
                  </Paper>
                </Box>
              ) : (
                <Box>
                  <Typography variant="body1">
                    Sebelum mengakses materi silahkan mengerjakan Pretest dengan menekan tombol
                    berikut
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ mt: 3 }}
                    onClick={handleNextButton}
                  >
                    Mulai Pretest
                  </Button>
                </Box>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography variant="body1" component="p">
                Setelah mempelajari materi SIPENA sekarang kamu bisa cek pengetahuan
                kesiapsiagaanmu! Klik link
              </Typography>
              <Link
                href="https://docs.google.com/forms/d/1ItV_MGZwWf-ylBGxQeXhCa48v3pQBXXWPu_s_m2sK9U/edit"
                target="_blank"
                sx={{ ml: '5px' }}
              >
                <Typography variant="body1" component="p">
                  Post-Test
                </Typography>
              </Link> */}
              {testScore?.test_code === 'POTSIS' && testScore?.completed ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '25px',
                    width: '100%'
                  }}
                >
                  <Paper
                    sx={{
                      border: '1px solid #00000003',
                      p: 4,
                      m: '0 auto',
                      minWidth: '480px',
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="body1">
                      Nilai Post Test: {testScore?.test_score} poin
                    </Typography>
                    <Typography variant="body1">Pada percobaan ke: {testScore?.attempt}</Typography>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => setActiveStep(1)}
                      sx={{ mt: 3 }}
                    >
                      Ulangi
                    </Button>
                  </Paper>
                </Box>
              ) : (
                <Box>
                  <Typography variant="body1">
                    Setelah mempelajari materi SIPENA sekarang kamu bisa cek pengetahuan
                    kesiapsiagaanmu!
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ mt: 3 }}
                    onClick={handleNextButton}
                  >
                    Mulai Post-test
                  </Button>
                </Box>
              )}
            </React.Fragment>
          )}
        </Box>
      )}

      {activeStep !== 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '25px'
          }}
        >
          <Typography variant="body1">{activeStep}/20</Typography>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Paper
                  sx={{
                    border: '1px solid #00000003',
                    p: 4,
                    minWidth: '480px',
                    maxWidth: '1080px',
                    m: '0px auto'
                  }}
                >
                  {activeStep === 1 && <One />}
                  {activeStep === 2 && <Two />}
                  {activeStep === 3 && <Three />}
                  {activeStep === 4 && <Four />}
                  {activeStep === 5 && <Five />}
                  {activeStep === 6 && <Six />}
                  {activeStep === 7 && <Seven />}
                  {activeStep === 8 && <Eight />}
                  {activeStep === 9 && <Nine />}
                  {activeStep === 10 && <Ten />}
                  {activeStep === 11 && <Eleven />}
                  {activeStep === 12 && <Twelve />}
                  {activeStep === 13 && <Thirdteen />}
                  {activeStep === 14 && <Fourteen />}
                  {activeStep === 15 && <Fifteen />}
                  {activeStep === 16 && <Sixteen />}
                  {activeStep === 17 && <Seventeen />}
                  {activeStep === 18 && <Eighteen />}
                  {activeStep === 19 && <Nineteen />}
                  {activeStep === 20 && <Twenty />}
                </Paper>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    justifyContent: 'center',
                    alignItems: 'center',
                    my: 3
                  }}
                >
                  <Button variant="outlined" onClick={handlePrevButton} disabled={activeStep === 1}>
                    Sebelumnya
                  </Button>
                  {activeStep !== 20 ? (
                    <Button type="submit" variant="contained">
                      Selanjutnya
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  )}
                </Box>
              </form>
            )}
          />
        </Box>
      )}
    </Box>
  );
}

PrePostTest.propTypes = {
  isPost: PropTypes.bool
};

PrePostTest.defaultProps = {
  isPost: false
};

export default PrePostTest;
