import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, List, ListItemButton, Typography } from '@mui/material';
import '../../../MainContent.scss';

// Assets
import { Markup } from 'interweave';
import { Colors } from 'utils/theme';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function JenisAncamanDIY() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Jenis Bencana
      </Typography>
      {contentId === '8' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <Markup
              content={`
              <table class="tg">
              <thead>
              <tr>
                <th class="tg-0pky" rowspan="2">No</th>
                <th class="tg-0pky" colspan="3">Potensi Bencana Daerah Istimewa Yogyakarta</th>
                <tr>
                  <th class="tg-0lax">Peraturan Kepala BNPB No. 2 Tahun 2012</th>
                  <td class="tg-0lax">Peraturan Gubernur Daerah Istimewa Yogyakarta No. 65 Tahun 2020</td>
                  <td class="tg-0lax">Kajian IRBI Daerah Istimewa Yogyakarta Tahun 2021</td>
                </tr>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="tg-0pky">1</td>
                <td class="tg-0pky">Gempa Bumi </td>
                <td class="tg-0lax">Banjir</td>
                <td class="tg-0lax">Tanah Longsor</td>
              </tr>
              <tr>
                <td class="tg-0pky">2</td>
                <td class="tg-0pky">Banjir</td>
                <td class="tg-0lax">Gempa Bumi</td>
                <td class="tg-0lax">Gelombang Pasang/Abrasi</td>
              </tr>
              <tr>
                <td class="tg-0pky">3</td>
                <td class="tg-0pky">Tanah Longsor</td>
                <td class="tg-0lax">Tanah Longsor</td>
                <td class="tg-0lax">Banjir</td>
              </tr>
              <tr>
                <td class="tg-0lax">4</td>
                <td class="tg-0lax">Erupsi Gunung Api</td>
                <td class="tg-0lax">Kekeringan</td>
                <td class="tg-0lax">Gempa Bumi</td>
              </tr>
              <tr>
                <td class="tg-0lax">5</td>
                <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
                <td class="tg-0lax">Cuaca Ekstrim</td>
                <td class="tg-0lax">Kekeringan</td>
              </tr>
              <tr>
                <td class="tg-0lax">6</td>
                <td class="tg-0lax">Cuaca Ekstrim</td>
                <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
                <td class="tg-0lax">Banjir Bandang</td>
              </tr>
              <tr>
                <td class="tg-0lax">7</td>
                <td class="tg-0lax">Kekeringan</td>
                <td class="tg-0lax">Erupsi gunung api</td>
                <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Banjir Bandang</span></td>
              </tr>
              <tr>
                <td class="tg-0lax">8</td>
                <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
                <td class="tg-0lax">Tsunami</td>
                <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Cuaca Ekstrim</span></td>
              </tr>
              <tr>
                <td class="tg-0lax">9</td>
                <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
                <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
                <td class="tg-0lax"><span style="font-weight:400;font-style:normal">Kegagalan Teknologi</span></td>
              </tr>
              <tr>
                <td class="tg-0lax">10</td>
                <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
                <td class="tg-0lax">Gelombang Ekstrim dan Abrasi</td>
                <td class="tg-0lax">Kegagalan Teknologi</td>
              </tr>
              <tr>
                <td class="tg-0lax">11</td>
                <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
                <td class="tg-0lax">Kegagalan Teknologi</td>
                <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
              </tr>
              <tr>
                <td class="tg-0lax">12</td>
                <td class="tg-0lax">Gagal Teknologi</td>
                <td class="tg-0lax">Epidemi dan Wabah Penyakit</td>
                <td class="tg-0lax">Pandemi Covid-19</td>
              </tr>
              <tr>
                <td class="tg-0lax">13</td>
                <td class="tg-0lax">Konflik Sosial</td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax">Likuifaksi</td>
              </tr>
              <tr>
                <td class="tg-0lax">14</td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax">Kebakaran Hutan dan Lahan</td>
              </tr>
              <tr>
                <td class="tg-0lax">15</td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax">Kebakaran Gedung dan Permukiman</td>
              </tr>
              <tr>
                <td class="tg-0lax">16</td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax"></td>
                <td class="tg-0lax">Konflik Sosial</td>
              </tr>
            </tbody>
            </table>
              `}
            />
            <Typography variant="body1" component="p">
              (DIBI DIY, 2022)
            </Typography>
          </Box>
          <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', paddingLeft: '24px' }}>
            <Typography variant="h6">Bahan Pembelajaran</Typography>
            <List>
              {[
                {
                  id: 1,
                  title: 'Data dan Informasi Bencana DIY Tahun 2022',
                  url: 'https://drive.google.com/file/d/1E2JlPHnK9D3T5B_v20pqY6La1FHEWqyN/view?usp=sharing'
                }
              ].map((item) => (
                <Link key={item.id} href={item.url} sx={{ textDecoration: 'none' }}>
                  <ListItemButton
                    sx={{
                      background: Colors.primary.main,
                      color: Colors.white,
                      fontWeight: 800,
                      '&:hover': {
                        background: Colors.primary.dark
                      }
                    }}
                  >
                    {item.title}
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {contentId === '9' && (
        <YouTubeEmbed
          embedId="UXfh_MMP5V4"
          preVideoText="Untuk menambah pengetahuan tentang jenis ancaman bencana DIY, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 2."
          caption="(Sumber: youtube.com. Humas BNPB. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '10' && (
        <YouTubeEmbed
          embedId="Wo_hIhKCuuU"
          preVideoText="Untuk menambah pengetahuan tentang jenis ancaman bencana DIY, Bapak/Ibu Guru dapat melihat video 2 berikut ini."
          postVideoText="Setelah melihat video 2, Bapak/Ibu Guru dapat melanjutkan ke Video 3."
          caption="(Sumber: youtube.com. Humas BNPB. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '11' && (
        <YouTubeEmbed
          embedId="3tuZ7Y_TpMw"
          preVideoText="Untuk memperdalam pengetahuan tentang jenis ancaman bencana DIY, Bapak/Ibu Guru dapat melihat video 3 berikut ini."
          postVideoText="Setelah melihat video 1,2, dan 3, Bapak/Ibu Guru dapat menyimpulkan  jenis ancaman bencana DIY antara lain : ..."
          caption="(Sumber: youtube.com. Kementrian ESDM. diinput dalam website
SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default JenisAncamanDIY;
