import React from 'react';
import { Avatar, Box, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Colors } from 'utils/theme';
import teacherKidsImage from 'assets/images/img/guru-siswa.svg';
import teacherIcon from 'assets/images/icons/TeacherIcon.svg';
import studentIcon from 'assets/images/icons/StudentIcon.svg';
import zelaImage from 'assets/images/img/zela.jpeg';
import profOne from 'assets/images/img/prof1.jpg';
import profTwo from 'assets/images/img/prof2.jpg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleGetTotalUsers } from 'redux/actions/users.actions';
import { formatAmountDot } from 'utils/helpers/general';

function AboutSection() {
  const dispatch = useDispatch();

  const { totalUsers } = useSelector((state) => state.users, shallowEqual);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const fetchRef = React.useRef(null);

  React.useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    dispatch(handleGetTotalUsers());
  }, []);

  return (
    <Box>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item md={4} position="relative">
          <img
            loading="lazy"
            src={teacherKidsImage}
            alt="teacher and kid"
            width={matches ? '70%' : '100%'}
            style={{ margin: '0 auto' }}
          />
        </Grid>
        <Grid item md={6} alignItems="flex-end">
          <Box>
            <Typography variant="h3">Apa itu SIPENA?</Typography>
            <Typography variant="caption" style={{ color: Colors.disabled.light }}>
              Ketahui lebih banyak tentang SIPENA
            </Typography>
            <Typography variant="body1" marginTop="24px">
              SIPENA adalah portal pembelajaran pendidikan bencana yang menyediakan bahan belajar
              serta fasilitas komunikasi yang mendukung interaksi guru dan siswa SD. SIPENA hadir
              sebagai bentuk inovasi pembelajaran pendidikan bencana di era industri 5.0 yang dapat
              dimanfaatkan oleh siswa dan guru SD dalam pembelajaran integrasi pendidikan bencana.
              Dengan menggunakan SIPENA, kita dapat belajar dimana saja, kapan saja dengan siapa
              saja. Seluruh konten yang ada di SIPENA dapat diakses dan dimanfaatkan secara gratis.
            </Typography>
            <Divider sx={{ mt: 3 }} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap={2}
              marginTop="16px"
            >
              <Typography variant="body1" component="p">
                “Kesiapsiagaan bencana dimulai dari diri sendiri. Ayo persiapkan kesiapsiagan
                bencana mulai dari sekarang.”
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  src={zelaImage}
                  alt="Author"
                  sx={{
                    width: 64,
                    height: 64,
                    '& img': {
                      objectPosition: 'top'
                    }
                  }}
                />
                <Box marginLeft={3} alignItems="center">
                  <Typography variant="caption1" component="p">
                    Zela Septikasari, M.Sc.,M.Pd.
                  </Typography>
                  <Typography variant="overline" component="p" sx={{ color: '#999' }}>
                    Fasilitator Satuan Pendidikan Aman Bencana
                  </Typography>
                  <Typography variant="overline" component="p" sx={{ color: '#999' }}>
                    S3-PENDIDIKAN DASAR UNIVERSITAS NEGERI YOGYAKARTA
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar src={profOne} alt="Author" sx={{ width: 48, height: 48 }} />
                  <Box>
                    <Typography variant="caption1" component="p">
                      Prof. Dr. Heri Retnowati, M.Pd.
                    </Typography>
                    <Typography variant="overline" component="p" sx={{ color: '#999' }}>
                      UNIVERSITAS NEGERI YOGYAKARTA
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar src={profTwo} alt="Author" sx={{ width: 48, height: 48 }} />
                  <Box>
                    <Typography variant="caption1" component="p">
                      Prof. Dr. Insih Wilujeng, M.Pd.
                    </Typography>
                    <Typography variant="overline" component="p" sx={{ color: '#999' }}>
                      UNIVERSITAS NEGERI YOGYAKARTA
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mt: 3 }} />
          <Box marginTop="32px" display="flex" gap="64px" alignItems="flex-end">
            <Box display="flex" gap="8px" alignItems="flex-end">
              <img src={teacherIcon} alt="teacher icon" width={40} loading="lazy" />
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1">
                  {formatAmountDot(totalUsers?.teacher)} Orang
                </Typography>
                <Typography variant="caption">Guru telah terdaftar</Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px" alignItems="flex-end">
              <img src={studentIcon} alt="teacher icon" width={40} loading="lazy" />
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle1">
                  {formatAmountDot(totalUsers?.student)} Orang
                </Typography>
                <Typography variant="caption">Siswa telah terdaftar</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutSection;
