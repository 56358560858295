import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

function ContentIndex() {
  const { contentId } = useParams();
  const [activeMenu, setActiveMenu] = useState(0);

  return <div>d</div>;
}

export default ContentIndex;
