import React, { useState } from 'react';
import PropTypes from 'prop-types';

// components
import { Box, Tabs, Tab, Typography } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 3, py: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

function LaguKebencanaan() {
  const [activeTab, setActiveTab] = useState(0);

  const handleActiveTab = (event, selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Lagu-Lagu Kebencanaan
      </Typography>
      <Box sx={{ maxWidth: '90%' }}>
        <Box>
          <Typography variant="body1" component="p">
            Halloo Adik-adik! <br /> <br />
            Untuk menambah semangat belajar, mari kita menyanyikan lagu dan Yel-yel Kebencanaan. Ada
            2 lagu yaitu Lagu SIPENA dan Lagu Siaga Bencara, serta 1 yel-yel yaitu Tepuk SPAB.{' '}
            <br /> <br />
            Ayo baca liriknya, hafalkan, cek videonya dan praktekkan menyanyi bersama teman-temanmu!
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 6, borderTop: 1, borderColor: 'divider' }}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleActiveTab}
            sx={{ width: '30%', borderRight: 1, borderColor: 'divider', textAlign: 'left' }}
          >
            <Tab label="LAGU SIPENA" {...a11yProps(0)} sx={{ alignItems: 'flex-start' }} />
            <Tab label="LAGU SIAGA BENCANA" {...a11yProps(1)} sx={{ alignItems: 'flex-start' }} />
            <Tab label="TEPUK SIPENA" {...a11yProps(2)} sx={{ alignItems: 'flex-start' }} />
            <Tab label="YEL-YEL TEPUK SPAB" {...a11yProps(3)} sx={{ alignItems: 'flex-start' }} />
          </Tabs>
          <TabPanel value={activeTab} index={0} style={{ width: '100%' }}>
            <Box>
              <Typography variant="body1" component="p">
                Judul Lagu: SIPENA <br /> Ciptaan : Zela Septikasari <br /> Nada Lagu Satu-satu Aku
                Sayang Ibu
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 6 }}>
                SATU-SATU BELAJAR SIPENA <br />
                DUA-DUA PAHAM MATERINYA
                <br />
                TIGA-TIGA SIAGA BENCANA
                <br />
                SATU DUA TIGA TANGGUH BENCANA
                <br />
              </Typography>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/vSpVX_dDN68"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Januari 2023)
                </figcaption>
              </figure>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/E4WYEoEQDm4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/K4pHMuFtJdo"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>
            </Box>
            <Typography variant="body1" component="p">
              Setelah menyanyikan bersama Lagu SIPENA, ayo kita menyanyi bersama Lagu Siaga Bencana.
              Klik menu berikutnya ya pada menu Lagu Siaga Bencana.
            </Typography>
          </TabPanel>
          <TabPanel value={activeTab} index={1} style={{ width: '100%' }}>
            <Box>
              <Typography variant="body1" component="p">
                Judul Lagu: SIAGA BENCANA <br /> Ciptaan : Zela Septikasari &amp; Mirna Dwi Nur
                Zulita
                <br /> Nada : POTONG BEBEK ANGSA
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 6 }}>
                SATUAN PENDIDIKAN AMAN BENCANA <br />
                AKU HARUS SIAGA SIAGA BENCANA <br />
                AYO BELAJAR BELAJAR BENCANA <br />
                AYO SIAGA BELAJAR BENCANA <br />
              </Typography>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/wRMJE94cMCw"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Januari 2023)
                </figcaption>
              </figure>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/MyzH6rhtqyg"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/67Q786ZIrgI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>
              <Typography variant="body1" component="p">
                Setelah menyanyikan bersama Lagu Siaga Bencana , ayo kita praktekkan yel-yel Tepuk
                SIPENA. Klik pada menu berikutnya ya, menu yel-yel Tepuk SIPENA.
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={2} style={{ width: '100%' }}>
            <Box>
              <Typography variant="body1" component="p">
                Tepuk SIPENA <br />
                Ciptaan : Zela Septikasari
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 6 }}>
                Aku (Tepuk 3x) <br />
                Belajar (Tepuk 3x) <br />
                SIPENA (Tepuk 3x) <br />
                <br />
                Tangguh.. Tangguh…Tangguh! <br />
                Yes…! <br />
              </Typography>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/A_67wIt1k-o"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/xZvMBMLYRn4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Februari
                  2023)
                </figcaption>
              </figure>

              <Typography variant="body1" component="p">
                Setelah mempraktekkan bersama yel-yel Tepuk SIPENA, Ayo kita coba praktekkan bersama
                Tepuk SPAB. Klik pada menu berikutnya ya, menu yel-yel Tepuk SPAB.
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel value={activeTab} index={3} style={{ width: '100%' }}>
            <Box>
              <Typography variant="body1" component="p">
                Tepuk SPAB <br />
                Ciptaan : Zela Septikasari &amp; Mirna Dwi Nur Zulita
              </Typography>
              <Typography variant="body1" component="p" sx={{ mt: 6 }}>
                AKU (TEPUK 3X) <br />
                SIAGA (TEPUK 3X) <br />
                BENCANA (TEPUK 3X) <br />
                SPAB TANGGUH TANGGUH TANGGUH <br />
                YESSS
              </Typography>
              <figure style={{ margin: '24px 0', fontSize: '12px' }}>
                <iframe
                  width="80%"
                  style={{
                    width: '100%',
                    aspectRatio: '16/9'
                  }}
                  src="https://www.youtube.com/embed/OWBNYtq5kKY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
                <figcaption>
                  (Sumber: youtube.com. Zela Septikasari. diinput dalam website SIPENA Januari 2023)
                </figcaption>
              </figure>

              <Typography variant="body1" component="p">
                Sudah Semangat ya!! Sekarang kita lanjut materi Pengertian Bencana pada menu
                selanjutnya.
              </Typography>
            </Box>
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

export default LaguKebencanaan;
