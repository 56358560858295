import { serviceRegister } from 'utils/api';

export const SET_LOADING = 'registerAction/SET_LOADING';
export const SET_ERROR = 'registerAction/SET_ERROR';
export const SET_REGISTER_USER = 'registerAction/SET_REGISTER_USER';
export const SET_USER_IS_REGISTERED = 'registerAction/SET_USER_IS_REGISTERED';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
});

export const setRegisterUser = (payload) => ({
  type: SET_REGISTER_USER,
  payload
});

export const setUserIsRegistered = (payload) => ({
  type: SET_USER_IS_REGISTERED,
  payload
});

export const handleRegisterUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status, data } = await serviceRegister(payload);
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setRegisterUser(data));
      dispatch(setUserIsRegistered(true));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setError({ isError: true, message: error }));
  }
};
