/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, useTheme } from '@mui/material';
import AppBarDesktop from './Desktop';
import AppBarMobile from './Mobile';

function AppBar({ clickMenu }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return isMobile ? (
    <AppBarMobile clickMenu={clickMenu} />
  ) : (
    <AppBarDesktop clickMenu={clickMenu} />
  );
}

AppBar.propTypes = {
  clickMenu: PropTypes.func.isRequired
};

export default AppBar;
