import { createBrowserRouter } from 'react-router-dom';

import publicRoute from './public.routes';
import protectedRoute from './protected.routes';

const router = createBrowserRouter([
  ...publicRoute,
  ...protectedRoute,
  {
    path: '*',
    element: (
      <div
        style={{
          height: '100Vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <h1>Page Not Found</h1>
      </div>
    )
  }
]);

export default router;
