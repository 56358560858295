import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { Field, useField, Form } from 'react-final-form';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { acceptNumberOnly } from 'utils/helpers/general';
import { handleUpdateProfile } from 'redux/actions/users.actions';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

// Custom Component
// eslint-disable-next-line react/prop-types
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

// eslint-disable-next-line react/prop-types
function CustomRadioGroup({ name, label, children }) {
  return (
    <Box>
      <Typography variant="body1" component="legend">
        {label}
      </Typography>
      <RadioGroup sx={{ display: 'flex', gap: 2, flexDirection: 'row' }}>{children}</RadioGroup>
      <Field name={name} render={({ meta }) => <FormControl>{showError(meta)}</FormControl>} />
    </Box>
  );
}

function Student() {
  const dispatch = useDispatch();
  const { schools, profile } = useSelector(
    (state) => ({ schools: state.login.schools, profile: state.users.profile }),
    shallowEqual
  );

  const userLogin = getLocalStorage('userLogin');
  const isTeacher = userLogin?.role === 'guru';

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    nik: '',
    gender: '',
    school: '',
    lastEducation: '',
    age: '',
    teach: '',
    phone: ''
  });

  useEffect(() => {
    if (profile) {
      setInitialValues({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        nik: profile?.nik,
        gender: profile?.gender,
        school: profile?.schoolId,
        address: profile?.address,
        lastEducation: profile?.lastEducation,
        age: profile?.age,
        teach: profile?.teach,
        phone: profile?.phone
      });
    }
  }, [profile]);

  const validate = (values) => {
    const errors = {};

    if (isTeacher) {
      if (!values.nik) errors.nik = 'Wajib diisi';
      else if (values.nik.length !== 16) errors.nik = 'NIK berisi 16 digit';

      // if (!values.school) errors.school = 'Wajib diisi';
      if (!values.lastEducation) errors.lastEducation = 'Wajib diisi';
      if (!values.employmentStatus) errors.employmentStatus = 'Wajib diisi';
    }

    if (!values.firstName) errors.firstName = 'Wajib diisi.';
    if (!values.gender) errors.gender = 'Wajib diisi.';
    if (!values.religion) errors.religion = 'Wajib diisi.';
    if (!values.age) errors.age = 'Wajib diisi.';
    if (!values.teach) errors.teach = 'Wajib diisi.';
    if (!values.phone) errors.phone = 'Wajib diisi.';

    return errors;
  };

  const onSubmit = async (values) => {
    dispatch(handleUpdateProfile(values));
  };

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        // validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ my: 6 }}>
              <Paper elevation={1} sx={{ borderRadius: 2, p: 6 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Pengaturan</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item container spacing={3} xs={12}>
                    <Grid item xs={6}>
                      <Field
                        name="firstName"
                        render={({ input, meta }) => (
                          <TextField
                            label="Nama Depan"
                            fullWidth
                            {...input}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="lastName"
                        render={({ input, meta }) => (
                          <TextField
                            label="Nama Belakang"
                            fullWidth
                            {...input}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="nik"
                        format={(value) => acceptNumberOnly(value)}
                        render={({ input, meta }) => (
                          <TextField
                            label="NIK"
                            fullWidth
                            sx={{ width: 'calc(100% - 12px)' }}
                            {...input}
                            type="text"
                            inputProps={{ maxLength: 16 }}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="age"
                        format={(value) => acceptNumberOnly(value)}
                        render={({ input, meta }) => (
                          <TextField
                            label="Usia"
                            fullWidth
                            sx={{ width: 'calc(100% - 12px)' }}
                            {...input}
                            type="text"
                            inputProps={{ maxLength: 16 }}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Field
                        name="phone"
                        format={(value) => acceptNumberOnly(value)}
                        render={({ input, meta }) => (
                          <TextField
                            label="No Telepon/WA"
                            fullWidth
                            sx={{ width: 'calc(100% - 12px)' }}
                            {...input}
                            type="text"
                            inputProps={{ maxLength: 16 }}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <CustomRadioGroup name="gender" label="Jenis Kelamin">
                        <CustomRadio name="gender" value="M" label="Laki-Laki" />
                        <CustomRadio name="gender" value="F" label="Perempuan" />
                      </CustomRadioGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="school"
                        render={({ input }) => (
                          <FormControl fullWidth>
                            <InputLabel id="school-select-label">Nama Sekolah</InputLabel>
                            <Select
                              labelId="school-select-label"
                              disabled
                              id="school-select"
                              {...input}
                              label="Nama Sekolah"
                            >
                              {schools?.map((item) => (
                                <MenuItem value={item.school_id} key={item.school_id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="teach"
                        format={(value) => acceptNumberOnly(value)}
                        render={({ input, meta }) => (
                          <TextField
                            label="Guru Kelas"
                            fullWidth
                            sx={{ width: 'calc(100% - 12px)' }}
                            {...input}
                            type="text"
                            inputProps={{ maxLength: 16 }}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="lastEducation"
                        render={({ input }) => (
                          <FormControl fullWidth>
                            <InputLabel id="last-education-select-label">
                              Pendidikan Terakhir
                            </InputLabel>
                            <Select
                              labelId="last-education-select-label"
                              id="last-education-select"
                              {...input}
                              label="Pendidikan Terakhir"
                            >
                              <MenuItem value="D3">D3</MenuItem>
                              <MenuItem value="S1">S1</MenuItem>
                              <MenuItem value="S2">S2</MenuItem>
                              <MenuItem value="S3">S3</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={13}>
                      <Field
                        name="address"
                        render={({ input, meta }) => (
                          <TextField
                            label="Alamat"
                            fullWidth
                            multiline
                            minRows={3}
                            {...input}
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" size="large">
                        Ubah
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </form>
        )}
      />
    </Container>
  );
}

export default Student;
