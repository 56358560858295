const Colors = {
  primary: {
    light: '#68D995',
    main: '#26CC68',
    dark: '#1E9F51'
  },
  secondary: {
    light: '#6BDBF4',
    main: '#26BBDC',
    dark: '#22A6C3'
  },
  error: {
    light: '#fd7f7d',
    main: '#ff4e4b',
    dark: '#e7312e'
  },
  warning: {
    light: '#FBD074',
    main: '#F3B530',
    dark: '#E09B06'
  },
  disabled: {
    light: '#C5A78B',
    main: '#E4D7CA',
    dark: '#D8C4B1'
  },
  white: '#ffffff',
  black: '#111210'
};

export default Colors;
