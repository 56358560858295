import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Slide, Snackbar } from '@mui/material';

function Transition({ direction, ...props }) {
  return <Slide {...props} direction={direction} />;
}

Transition.propTypes = {
  direction: PropTypes.string
};

Transition.defaultProps = {
  direction: 'left'
};

function Toast({ open, onClose, message, type, duration }) {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={duration}>
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
}

Toast.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.string,
  duration: PropTypes.number
};

Toast.defaultProps = {
  open: false,
  onClose: () => {},
  message: 'New Toaster',
  type: 'success',
  duration: 1000
};

export default Toast;
