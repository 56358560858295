import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Typography,
  Radio,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import { Field, useField, Form } from 'react-final-form';
import { handleUpdateProfile } from 'redux/actions/users.actions';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

// Custom Component
// eslint-disable-next-line react/prop-types
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

// eslint-disable-next-line react/prop-types
function CustomRadioGroup({ name, label, children }) {
  return (
    <Box>
      <Typography variant="body1" component="legend">
        {label}
      </Typography>
      <RadioGroup>{children}</RadioGroup>
      <Field name={name} render={({ meta }) => <FormControl>{showError(meta)}</FormControl>} />
    </Box>
  );
}

function Student() {
  const dispatch = useDispatch();

  const { schools, profile } = useSelector(
    (state) => ({ schools: state.login.schools, profile: state.users.profile }),
    shallowEqual
  );

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    school: '',
    grade: '',
    absentNumber: '',
    address: ''
  });

  useEffect(() => {
    if (profile) {
      setInitialValues({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        gender: profile?.gender,
        school: profile?.schoolId,
        grade: profile?.grade,
        absentNumber: profile?.absentNumber,
        address: profile?.address
      });
    }
  }, [profile]);

  const validate = (values) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Wajib diisi.';
    }
    if (!values.gender) {
      errors.gender = 'Wajib diisi.';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    dispatch(handleUpdateProfile(values));
  };

  return (
    <Container>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ my: 6 }}>
              <Paper elevation={1} sx={{ borderRadius: 2, p: 6 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Pengaturan</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      render={({ input, meta }) => (
                        <TextField
                          label="Nama Depan"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="lastName"
                      render={({ input, meta }) => (
                        <TextField
                          label="Nama Belakang"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomRadioGroup name="gender" label="Jenis Kelamin">
                      <CustomRadio name="gender" value="M" label="Laki-Laki" />
                      <CustomRadio name="gender" value="F" label="Perempuan" />
                    </CustomRadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="school"
                      render={({ input }) => (
                        <FormControl fullWidth>
                          <InputLabel id="school-label">Sekolah</InputLabel>
                          <Select
                            labelId="school-label"
                            disabled
                            id="school-select"
                            {...input}
                            label="Sekolah"
                          >
                            {schools?.map((item) => (
                              <MenuItem value={item.school_id} key={item.school_id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="grade"
                      render={({ input, meta }) => (
                        <TextField
                          label="Kelas"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="absentNumber"
                      render={({ input, meta }) => (
                        <TextField
                          label="No. Absen"
                          fullWidth
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={13}>
                    <Field
                      name="address"
                      render={({ input, meta }) => (
                        <TextField
                          label="Alamat"
                          fullWidth
                          multiline
                          minRows={3}
                          {...input}
                          error={meta.touched && meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" size="large">
                      Ubah
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </form>
        )}
      />
    </Container>
  );
}

export default Student;
