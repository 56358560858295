import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  handleEditQuestions,
  handleNewQuestion,
  setIsEdit,
  setQuestionPopup,
  setSelectedMenu
} from 'redux/actions/discussion.actions';

// Local component
import { getLocalStorage } from 'utils/helpers/localStorage';
import Landing from './components/Landing';
import MyQuestions from './components/MyQuestions';
import { AskQuestion } from './components/StyledComponent';

function Discussions() {
  const menu = [
    {
      id: 'home',
      icon: <HomeOutlinedIcon />,
      label: 'Home'
    },
    {
      id: 'my-topics',
      icon: <HelpOutlineOutlinedIcon />,
      label: 'Topik Saya'
    }
  ];

  const dispatch = useDispatch();

  const user = getLocalStorage('userLogin');

  const { selectedMenu, selectedQuestion, openQuestionPopup, isEdit } = useSelector(
    (state) => state.discussion,
    shallowEqual
  );

  const [payload, setPayload] = useState({});

  useEffect(() => {
    setPayload({
      topicId: selectedQuestion.topicId,
      title: selectedQuestion.title,
      description: selectedQuestion.description
    });
  }, [selectedQuestion]);

  const handleClickMenuItem = (event, index) => {
    dispatch(setSelectedMenu(index));
  };

  const handleAddQuestion = () => {
    setPayload({ userId: user?.userId });
    dispatch(setQuestionPopup(true));
  };

  const handleChangeQuestion = (event) => {
    const { name, value } = event.target;
    setPayload({
      ...payload,
      [name]: value
    });
  };

  const handleSendQuestion = async (type) => {
    if (type === 'ask') {
      await dispatch(handleNewQuestion(payload));
    } else {
      await dispatch(handleEditQuestions(payload));
    }
    window.location.reload();
    dispatch(setQuestionPopup(false));
  };

  const handleCancelAddQuestion = () => {
    dispatch(setQuestionPopup(false));
    setPayload({});
    setTimeout(() => {
      dispatch(setIsEdit(false));
    }, 500);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3} sx={{ py: 6 }}>
        <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Paper variant="outlined">
            <Typography variant="h5" sx={{ py: 2, px: 2 }}>
              Menu
            </Typography>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <List>
                {menu.map((item, index) => (
                  <ListItemButton
                    key={item.id}
                    selected={selectedMenu === index}
                    onClick={(event) => handleClickMenuItem(event, index)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h4">
              {selectedMenu === 0 ? 'Semua Diskusi' : 'Topik Saya'}
            </Typography>
            <Button
              variant="contained"
              size="large"
              startIcon={<AddIcon style={{ width: '24px' }} />}
              onClick={handleAddQuestion}
            >
              Buat Pertanyaan
            </Button>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {selectedMenu === 0 ? <Landing setPayload={setPayload} /> : null}
            {selectedMenu === 1 ? <MyQuestions /> : null}
            {/* {selectedMenu === 2 ? <MyAnswers /> : null} */}
          </Box>
        </Grid>
      </Grid>
      <AskQuestion
        open={openQuestionPopup}
        onClose={handleCancelAddQuestion}
        onSend={handleSendQuestion}
        onChange={handleChangeQuestion}
        value={payload}
        isEdit={isEdit}
      />
    </Container>
  );
}

export default Discussions;
