import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import cardImage from 'assets/images/img/gunung-api.jpg';
import { useNavigate } from 'react-router-dom';

function StyledClassCard({ handleClickCard, title, creator }) {
  return (
    <Card sx={{ maxWidth: '100%', border: `1px solid #00000011`, borderRadius: 2 }} elevation={0}>
      <CardActionArea
        onClick={handleClickCard}
        sx={{ display: 'flex', justifyContent: 'flex-start' }}
      >
        <Box sx={{ width: 250, overflow: 'hidden' }}>
          <CardMedia
            component="img"
            sx={{
              width: 250,
              overflow: 'hidden',
              transition: 'all 0.5s ease-in-out',
              filter: 'grayscale(60%)',
              '&:hover': {
                filter: 'grayscale(0%)',
                transform: 'scale(1.2)'
              }
            }}
            image={cardImage}
            alt="Gunung Api"
          />
        </Box>
        <CardContent
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <Typography variant="h6" mb={1}>
            {title}
          </Typography>
          <Typography variant="overline">{creator}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function NextClass({ title, course }) {
  const navigate = useNavigate();

  const handleClickCard = () => {
    navigate('/class/gunung-api/1');
  };

  return (
    <Box>
      <Typography variant="h4" mb={3}>
        {title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <StyledClassCard
            handleClickCard={handleClickCard}
            title={course.title}
            creator={course.creator}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

StyledClassCard.propTypes = {
  handleClickCard: PropTypes.func,
  title: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired
};

StyledClassCard.defaultProps = {
  handleClickCard: () => {}
};

NextClass.propTypes = {
  title: PropTypes.string,
  course: PropTypes.object.isRequired
};

NextClass.defaultProps = {
  title: ''
};

export default NextClass;
