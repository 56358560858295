import Layout from 'components/templates';
import {
  FirstLogin,
  Dashboard,
  ClassContent,
  Profile,
  Setting,
  MyClass,
  Discussions,
  Curriculum,
  DiscussionDetails,
  Admin
} from 'pages';
import AdminDashboard from 'pages/Admin/Dashboard';
import Schools from 'pages/Admin/Schools';
import Scores from 'pages/Admin/Scores';
import UserList from 'pages/Admin/Users';
import CourseContent from 'pages/Class/components/Main';
// import MainContent from 'pages/Class/components/MainContent';
// import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

const router = [
  {
    path: 'first-login',
    element: <FirstLogin />
  },
  {
    path: 'admin',
    element: <Admin />,
    children: [
      { path: '', element: <AdminDashboard /> },
      { path: 'schools', element: <Schools /> },
      { path: 'scores', element: <Scores /> },
      { path: 'users', element: <UserList /> }
    ]
  },
  {
    element: <Layout />,
    children: [
      {
        path: 'home',
        element: <Dashboard />
      },
      {
        path: 'class/:courseId',
        element: <ClassContent />,
        children: [
          {
            path: ':contentId',
            element: <CourseContent />
          }
        ]
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'settings',
        element: <Setting />
      },
      {
        path: 'my-class',
        element: <MyClass />
      },
      {
        path: 'discussion',
        element: <Discussions />
      },
      {
        path: 'discussion/:questionId',
        element: <DiscussionDetails />
      },
      {
        path: 'curriculum',
        element: <Curriculum />
      }
    ]
  }
];

export default router;
