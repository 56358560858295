import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

function AdminDashboard() {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 240
            }}
          >
            <Typography variant="h3">Selamat datang di halaman Admin SIPENA</Typography>
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        {/* <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240
            }}
          >
            <Deposits />
            Deposit
          </Paper>
        </Grid> */}
        {/* Recent Orders */}
        {/* <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Orders />
            Order
          </Paper>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}

export default AdminDashboard;
