import React from 'react';
import { Container } from '@mui/material';
import AboutSection from './AboutSection';

function About() {
  return (
    <Container maxWidth="xl" style={{ padding: '100px 24px 120px' }}>
      <AboutSection />
    </Container>
  );
}

export default About;
