import { createTheme } from '@mui/material';
import Colors from './colors';
import Typography from './typography';

const theme = createTheme({
  palette: {
    ...Colors
  },
  typography: {
    ...Typography
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        disableElevation: true,
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              background: Colors.primary.main,
              color: Colors.white
            }),
          ...(ownerState.size === 'large' && {
            height: '40px'
          })
        })
      }
    }
  }
});

export default theme;
