import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link, List, ListItem, ListItemButton } from '@mui/material';
import { Text } from 'components/atomics';
import { useParams } from 'react-router-dom';
import { Markup } from 'interweave';
import { getLocalStorage } from 'utils/helpers/localStorage';
// import ContentFooter from './ContentFooter';
import './MainContent.scss';
import { Colors } from 'utils/theme';
import YouTubeEmbed from './YouTubeEmbed';
import ContentIndex from './Content';

function MainContent({ contentList }) {
  const user = getLocalStorage('userLogin');
  const { embedId, sectionId } = useParams();
  const [sectionTitle, setSectionTitle] = useState('');
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const selectedItem = contentList.filter((item) => item.path === sectionId)[0];
    setSectionTitle(selectedItem.title);
    setSelected(selectedItem);
  }, [sectionId]);

  return (
    <Box
      component="main"
      sx={{
        marginLeft: '300px',
        marginBottom: '70px',
        p: 6,
        width: '100%'
      }}
    >
      <Box component="article" sx={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <Box sx={{ flexGrow: 0 }}>
          <Text variant="h4" component="h1">
            {sectionTitle}
          </Text>
        </Box>
        <ContentIndex />
        {/* <Box sx={{ flexGrow: 1 }}>
          {embedId ? (
            <YouTubeEmbed />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={8} pr={3}>
                {'content' in selected && <Markup content={selected.content} />}
              </Grid>
              {user?.role === 'guru' && 'attachment' in selected && (
                <Grid item xs={4} sx={{ borderLeft: '1px solid #ccc' }}>
                  <Text variant="h6">Bahan Pembelajaran</Text>
                  <List>
                    {selected?.attachment.map((item) => (
                      <Link key={item.id} href={item.url} sx={{ textDecoration: 'none' }}>
                        <ListItem>
                          <ListItemButton
                            sx={{
                              background: Colors.primary.main,
                              color: Colors.white,
                              fontWeight: 800,
                              '&:hover': {
                                background: Colors.primary.dark
                              }
                            }}
                          >
                            {item.title}
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </Grid>
              )}
            </Grid>
          )}
        </Box> */}
      </Box>
      {/* <ContentFooter /> */}
    </Box>
  );
}

MainContent.propTypes = {
  contentList: PropTypes.array
};

MainContent.defaultProps = {
  contentList: []
};

export default MainContent;
