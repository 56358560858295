/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { handleCheckTest, handleTestScore } from 'redux/actions/class.actions';

import { Field, Form, useField } from 'react-final-form';

// components
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Button
} from '@mui/material';

// Assets
import pa from 'assets/images/img/pa.jpg';
import pb from 'assets/images/img/pb.jpg';
import pc from 'assets/images/img/pc.jpg';
import pd from 'assets/images/img/pd.jpg';
import pe from 'assets/images/img/pe.jpg';
import pf from 'assets/images/img/pf.jpg';
import j1 from 'assets/images/img/j1.jpg';
import j2 from 'assets/images/img/j2.jpg';
import j3 from 'assets/images/img/j3.jpg';
import j4 from 'assets/images/img/j4.jpg';
import j5 from 'assets/images/img/j5.png';
import j6 from 'assets/images/img/j6.jpg';

// Validation
const required = (value) =>
  value ? undefined : 'Pilih salah satu opsi diatas sebelum beralih ke pertanyaan selanjutnya';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

function JenisBencana() {
  const dispatch = useDispatch();

  const { testScore } = useSelector((state) => state.classes, shallowEqual);

  const [activeStep, setActiveStep] = useState(0);

  const qna = [
    {
      questionNo: 1,
      correctAnswer: '4',
      poin: 50
    },
    {
      questionNo: 2,
      correctAnswer: '2',
      poin: 50
    }
  ];

  const checkTestRef = useRef(false);

  useEffect(() => {
    if (checkTestRef.current) return;
    checkTestRef.current = true;
    dispatch(handleCheckTest({ quizId: '64fd268f-1f6d-49d2-a3e7-a5a81f7f1ddc' }));
  }, []);

  const handlePrevButton = () => {
    setActiveStep((prev) => prev - 1);
  };

  const onSubmit = async (values) => {
    const map = await new Map(Object.entries(values));
    const theArray = Array.from(map);
    let totalPoin = 0;
    if (activeStep < 2) {
      setActiveStep((prev) => prev + 1);
    } else {
      theArray.forEach((answer) => {
        qna.forEach((item) => {
          if (answer[0] === `q${item.questionNo}`) {
            // console.warn("It's true", answer[1], item.correctAnswer);
            if (answer[1] === item.correctAnswer) {
              totalPoin += item.poin;
            }
          }
        });
      });

      await dispatch(
        handleTestScore({ quizId: '64fd268f-1f6d-49d2-a3e7-a5a81f7f1ddc', score: totalPoin })
      );
      window.location.reload();
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Jenis Bencana
      </Typography>
      {/* <Box sx={{ maxWidth: '70%' }}>
        <Typography variant="body1" component="p">
          Ayo bermain Penyebab dan Jenis bencana! Yuk..cocokan jenis penyebab dan bencananya dibawah
          ini!
        </Typography>
        <Link
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6uR6Kp_Wy-K26ekozDQDXj-ZsdJJyYRV4SiFaQGKdFdQklQ/viewform"
          target="_blank"
        >
          <Typography variant="body1" component="p">
            Klik disini untuk mulai bermain
          </Typography>
        </Link>
      </Box> */}
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, reset }) => (
          <form
            onSubmit={(event) => {
              handleSubmit(event).then(reset);
            }}
          >
            {activeStep === 0 ? (
              <React.Fragment>
                {testScore?.test_code === 'KJBSIS' && testScore?.completed ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '25px',
                      width: '100%'
                    }}
                  >
                    <Paper
                      sx={{
                        border: '1px solid #00000003',
                        p: 4,
                        m: '0 auto',
                        minWidth: '480px',
                        textAlign: 'center'
                      }}
                    >
                      <Typography variant="body1">Score: {testScore?.test_score} poin</Typography>
                      <Typography variant="body1">
                        Pada percobaan ke: {testScore?.attempt}
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        onClick={() => setActiveStep(1)}
                        sx={{ mt: 3 }}
                      >
                        Ulangi Game
                      </Button>
                    </Paper>
                  </Box>
                ) : (
                  <React.Fragment>
                    <Typography variant="body1" component="p" mb={3}>
                      Ayo bermain Penyebab dan Jenis bencana! Yuk..cocokan jenis penyebab dan
                      bencananya dibawah ini!
                    </Typography>
                    <Button variant="contained" size="large" type="submit">
                      Mulai Game
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', p: 4, m: '0 auto', width: '90%' }}>
                  {activeStep === 1 && <NumberOne />}
                  {activeStep === 2 && <NumberTwo />}
                </Paper>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 3,
                    m: '24px auto',
                    width: '100%',
                    justifyContent: 'center'
                  }}
                >
                  <Button variant="outlined" type="button" onClick={handlePrevButton}>
                    {activeStep === 1 ? 'Cancel' : 'Sebelumnya'}
                  </Button>
                  <Button variant="contained" type="submit">
                    {activeStep === 2 ? 'Submit' : 'Selanjutnya'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </form>
        )}
      />
    </Box>
  );
}

function Image({ img, alt }) {
  return <img src={img} alt={alt} width="100%" height="300px" style={{ objectFit: 'cover' }} />;
}

function NumberOne() {
  return (
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 800, mb: 2 }}>
        Ayo cocokan jenis penyebab dan bencana dibawah ini!
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1">Penyebab</Typography>
          <Image img={pa} alt="Impact 1" />
          <Typography variant="body1">A. Cuaca Ekstrim</Typography>
          <Image img={pb} alt="Impact 2" />
          <Typography variant="body1">B. Curah Hujan Tinggi</Typography>
          <Image img={pc} alt="Impact 3" />
          <Typography variant="body1">C. Hutan Gundul</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1">Jenis Bencana</Typography>
          <Image img={j1} alt="Disaster 1" />
          <Typography variant="body1">1. Bencana Tanah Longsor</Typography>
          <Image img={j2} alt="Disaster 2" />
          <Typography variant="body1">2. Bencana Angin Kencang</Typography>
          <Image img={j3} alt="Disaster 3" />
          <Typography variant="body1">3. Bencana Banjir</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q1" validate={required}>
        <CustomRadio name="q1" value="1" label="A-1, B-2, C-3" />
        <CustomRadio name="q1" value="2" label="A-3, B-1, C-2" />
        <CustomRadio name="q1" value="3" label="B-1, A-2, C-3" />
        <CustomRadio name="q1" value="4" label="C-1, B-3, A-2" />
      </CustomRadioGroup>
    </Box>
  );
}

function NumberTwo() {
  return (
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 800, mb: 2 }}>
        Ayo cocokan jenis penyebab dan bencana dibawah ini!
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1">Penyebab</Typography>
          <Image img={pd} alt="Impact 1" />
          <Typography variant="body1">D. Gempa Bawah Laut</Typography>
          <Image img={pe} alt="Impact 2" />
          <Typography variant="body1">E. Pergeseran Lempeng</Typography>
          <Image img={pf} alt="Impact 3" />
          <Typography variant="body1">F. Erupsi Gunung Api</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1">Jenis Bencana</Typography>
          <Image img={j4} alt="Disaster 1" />
          <Typography variant="body1">4. Bencana Erupsi Gunung Api</Typography>
          <Image img={j5} alt="Disaster 2" />
          <Typography variant="body1">5. Bencana Tanah Longsor</Typography>
          <Image img={j6} alt="Disaster 3" />
          <Typography variant="body1">6. Bencana Gempa Bumi</Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q2" validate={required}>
        <CustomRadio name="q2" value="1" label="D-4, E-5, F-6" />
        <CustomRadio name="q2" value="2" label="D-5, E-6, F-4" />
        <CustomRadio name="q2" value="3" label="E-5, F-4, D-6" />
        <CustomRadio name="q2" value="4" label="F-5, D-4, E-6" />
      </CustomRadioGroup>
    </Box>
  );
}

// Custom Component
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

function CustomRadioGroup({ name, validate, children }) {
  return (
    <Box>
      <RadioGroup>{children}</RadioGroup>
      <Field
        name={name}
        validate={validate}
        render={({ meta }) => <FormControl>{showError(meta)}</FormControl>}
      />
    </Box>
  );
}

export default JenisBencana;
