import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Field, useField } from 'react-final-form';

// Assets
import q3a from 'assets/images/img/3a.jpg';
import q3b from 'assets/images/img/3b.jpg';
import q3c from 'assets/images/img/3c.jpg';
import q3d from 'assets/images/img/3d.jpg';
import q10a from 'assets/images/img/10a.jpg';
import q10b from 'assets/images/img/10b.jpg';
import q10c from 'assets/images/img/10c.jpg';
import q10d from 'assets/images/img/10d.jpg';
import q18a from 'assets/images/img/18a.png';
import q18b from 'assets/images/img/18b.png';
import q18c from 'assets/images/img/18c.jpg';
import q18d from 'assets/images/img/18d.png';

// Validation
const required = (value) =>
  value ? undefined : 'Pilih salah satu opsi diatas sebelum beralih ke pertanyaan selanjutnya';

// Functions
function showError(meta) {
  const { touched, error } = meta;

  return (
    <Box>
      {touched && error && (
        <Typography variant="caption" color="red">
          {error}
        </Typography>
      )}
    </Box>
  );
}

// Custom Component
// eslint-disable-next-line react/prop-types
function CustomRadio({ name, value, label }) {
  const { input } = useField(name, { type: 'radio', value });
  return (
    <FormControlLabel
      label={label}
      value={input.value}
      checked={input.checked}
      control={<Radio />}
      {...input}
    />
  );
}

// eslint-disable-next-line react/prop-types
function CustomRadioGroup({ name, validate, children }) {
  return (
    <Box>
      <RadioGroup>{children}</RadioGroup>
      <Field
        name={name}
        validate={validate}
        render={({ meta }) => <FormControl>{showError(meta)}</FormControl>}
      />
    </Box>
  );
}

export function One() {
  return (
    <Box>
      <Typography variant="body1">
        Indonesia adalah negara dengan potensi bencana yang sangat besar dan beragam. Hal ini
        dikarenakan Indonesia adalah negara yang memiliki gunungapi aktif terbanyak di dunia yaitu
        127 buah, dimana beberapa diantaranya memiliki letusan terkuat yang pernah terjadi di dunia.
        Selain itu berbagai macam bencana juga telah terjadi di Indonesia, mulai dari gempabumi,
        tsunami, kekeringan, letusan gunungapi hingga banjir bandang. Dari berbagai ragam bencana
        yang ada di Indonesia, jenis bencana yang memiliki terbesar potensi terbesar terjadi di
        lingkungan sekolah adalah……
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q1" validate={required}>
        <CustomRadio name="q1" value="1" label="Tsunami" />
        <CustomRadio name="q1" value="2" label="Letusan Gunungapi" />
        <CustomRadio name="q1" value="3" label="Gempa Tektonik" />
        <CustomRadio name="q1" value="4" label="Banjir Bandang" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Two() {
  const rows = [
    {
      left: 'A. Tanah Longsor',
      right: '1. Bencana Alam'
    },
    {
      left: 'B. Tsunami',
      right: '2. Bencana Non Alam'
    },
    {
      left: 'C. Banjir',
      right: '3. Bencana Sosial'
    },
    {
      left: 'D. Gempa Bumi'
    },
    {
      left: 'E. Kekeringan'
    },
    {
      left: 'F. Erupsi Gunung Api'
    },
    {
      left: 'G. Kegagalan Teknologi'
    },
    {
      left: 'H. Pandemi Covid-19'
    },
    {
      left: 'I. Kebakaran Hutan dan Lahan'
    },
    {
      left: 'J. Kebakaran Gedung dan Permukiman'
    },
    {
      left: 'K. Konflik Sosial'
    },
    {
      left: 'L. Angin Kencang'
    }
  ];
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Berikut disajikan jenis-¡enis bencana dan penggolongan bencana!
        </Typography>
        <Table size="small" sx={{ my: 3 }}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.left}>
                <TableCell>{row?.left}</TableCell>
                <TableCell>{row?.right}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="body1">
          Dari beberapa data diatas, maka pernyataan yang tepat adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q2" validate={required}>
        <CustomRadio
          name="q2"
          value="1"
          label="Tsunami, Covid-19, dan tanah longsor termasuk bencana alam."
        />
        <CustomRadio
          name="q2"
          value="2"
          label="Pandemi Covid-19 , gempa bumi, dan kegagalan teknologi termasuk bencana non alam."
        />
        <CustomRadio
          name="q2"
          value="3"
          label="Gempa bumi, angin kencang, dan tsunami termasuk bencana alam."
        />
        <CustomRadio
          name="q2"
          value="4"
          label="Konflik sosial dan Tsunami termasuk bencana sosial."
        />
      </CustomRadioGroup>
    </Box>
  );
}

export function Three() {
  return (
    <Box>
      <Typography variant="body1">
        Perhatikan dampak, bencana erupsi gunung api, pada gambar berikut!
      </Typography>
      <ImageList cols={2}>
        <img src={q3a} alt="one" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <img src={q3b} alt="two" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        <img src={q3c} alt="three" style={{ width: '100%', height: '100%', objectFit: 'cover ' }} />
        <img src={q3d} alt="four" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </ImageList>
      <Typography variant="body1">
        Dari gambar diatas, pernataan yang tepat sesuai dengan gambar adalah
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q3" validate={required}>
        <CustomRadio name="q3" value="1" label="Erupsi gunung api mengakibatkan jalan ditutup." />
        <CustomRadio
          name="q3"
          value="2"
          label="Erupsi gunung api mengakibatkan tidak bisa bersekolah."
        />
        <CustomRadio
          name="q3"
          value="3"
          label="Erupsi gunung api mengakibatkan korban jiwa, kerusakan sarana dan prasarana, kerusakan lingkungan, dan mengungsi."
        />
        <CustomRadio
          name="q3"
          value="4"
          label="Erupsi gunung api mengakibatkan dampak pada hewan ternak."
        />
      </CustomRadioGroup>
    </Box>
  );
}

export function Four() {
  const rows = [
    {
      left: 'A. Manusia',
      right: '1. Sekolah tidak memiliki pendanaan kebencanaan'
    },
    {
      left: 'B. Alam dan lingkungan',
      right: '2. Sekolah berada di area dekat dengan Gunung Merapi'
    },
    {
      left: 'C. Fisik/Infrastruktur',
      right: '3. Sekolah tidak memiliki peraturan terkait penanggulangan bencana'
    },
    {
      left: 'D. Sosial/Politik',
      right: '4. Guru dan siswa tidak memahami cara yang harus dilakukan ketika terjadi bencana'
    },
    {
      left: 'E. Finansial',
      right:
        '5. Peralatan Kesehatan sekolah tidak lengkap, sekolah tidak memiliki titik kumpul, sekolah tidak memilii arah jalur evakuasi'
    }
  ];
  return (
    <Box>
      <Box>
        <Typography variant="body1">Berikut disajikan kerentanan (kelemahan) bencana!</Typography>
        <Table size="small" sx={{ my: 3 }}>
          <TableHead>
            <TableRow>
              <TableCell>Jenis Kerentanan</TableCell>
              <TableCell>Contoh Kerentanan di Sekolah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.left}>
                <TableCell>{row?.left}</TableCell>
                <TableCell>{row?.right}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="body1">
          Dari jenis dan contoh kerentanan bencana di atas, pengelompokan yang tepat adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q4" validate={required}>
        <CustomRadio name="q4" value="1" label="A-1, B-2, C-3, D-4, E-5" />
        <CustomRadio name="q4" value="2" label="A-1, B-4, C-3, D-2, E-2" />
        <CustomRadio name="q4" value="3" label="A-4, B-3, C-1, D-5, E-2" />
        <CustomRadio name="q4" value="4" label="A-4, B-2, C-5, D-3, E-1" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Five() {
  const rows = [
    {
      left: 'A. Manusia',
      right:
        '1. Sekolah memiliki peralatan kesehatan yang lengkap, titik, kumpul, dan memiliki, arah jalur evakuasi.'
    },
    {
      left: 'B. Alam dan lingkungan',
      right: '2. Sekolah memiliki peraturan terkait kebencanaan.'
    },
    {
      left: 'C. Fisik/Infrastruktur',
      right: '3. Sekolah berada di area yang jauh dengan gunung Merapi'
    },
    {
      left: 'D. Sosial/Politik',
      right:
        '4. Guru dan siswa memahami cara yang harus dilakukan ketika teriadi, bencana erupsi gunung Merapi.'
    },
    {
      left: 'E. Finansial',
      right: '5. Sekolah memiliki perencanaan anggaran kebencanaan'
    }
  ];
  return (
    <Box>
      <Box>
        <Typography variant="body1">Berikut disajikan kapasitas bencana!</Typography>
        <Table size="small" sx={{ my: 3 }}>
          <TableHead>
            <TableRow>
              <TableCell>Jenis Kerentanan</TableCell>
              <TableCell>Contoh Kapasitas di Sekolah</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.left}>
                <TableCell>{row?.left}</TableCell>
                <TableCell>{row?.right}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="body1">
          Dari jenis dan contoh kapasitas bencana di atas, pengelompokan yang tepat adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q5" validate={required}>
        <CustomRadio name="q5" value="1" label="A-1, B-2, C-3, D-4, E-5" />
        <CustomRadio name="q5" value="2" label="A-1, B-4, C-3, D-2, E-2" />
        <CustomRadio name="q5" value="3" label="A-4, B-3, C-1, D-2, E-5" />
        <CustomRadio name="q5" value="4" label="A-4, B-2, C-5, D-3, E-1" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Six() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Perhatikan beberapa kondisi berikut ini!</Typography>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>Sekolah dekat dengan gunung Merapi.</li>
          <li>Siswa belum tahu apa yang harus dilakukan ketika terjadi erupsi.</li>
          <li>Sekolah belum memiliki peralatan Kesehatan yang lengkap.</li>
        </ol>
        <Typography variant="body1">
          Berdasarkan kondisi diatas, risiko bencana di sekolah adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q6" validate={required}>
        <CustomRadio
          name="q6"
          value="1"
          label="Risiko tinggi mengalami dampak korban jiwa, kerusakan sarana dan prasarana."
        />
        <CustomRadio
          name="q6"
          value="2"
          label="Risiko rendah karena sekolah tidak mengalami dampak."
        />
        <CustomRadio
          name="q6"
          value="3"
          label="Risiko tinggi karena sekolah tidak memiliki dampak."
        />
        <CustomRadio
          name="q6"
          value="4"
          label="Risiko rendah karena sekolah mengalami dampak korban jiwa dan kerusakan sarana dan prasarana."
        />
      </CustomRadioGroup>
    </Box>
  );
}

export function Seven() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Berikut merupakan status ancaman bahaya erupsi gunungapi
        </Typography>
        <ol style={{ listStyleType: 'upper-alpha' }}>
          <li>Awas</li>
          <li>Normal</li>
          <li>Siaga</li>
          <li>Waspada</li>
        </ol>
        <Typography variant="body1">
          Urutan status ancaman, bahaya erupsi gunung api yang tepat vaitu
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q7" validate={required}>
        <CustomRadio name="q7" value="1" label="B-C-D-A" />
        <CustomRadio name="q7" value="2" label="B-D-C-A" />
        <CustomRadio name="q7" value="3" label="B-A-D-C" />
        <CustomRadio name="q7" value="4" label="B-D-A-C" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Eight() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Berikut in merupakan contoh gejala-gejala alam sebelum teriadinya bencana erupsi Gunung
          api.
        </Typography>
        <ol>
          <li>Suhu panas</li>
          <li>Gunung api mengeluarkan suara gemuruh</li>
          <li>Terjadi hujan es</li>
          <li>Tumbuhan disekitar gunung api layu</li>
        </ol>
        <Typography variant="body1">
          Dari beberapa contoh diatas yang bukan tanda-tanda gejala-gejala alam sebelum terjadinya
          bencana erupsi Gunung api adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q8" validate={required}>
        <CustomRadio name="q8" value="1" label="1" />
        <CustomRadio name="q8" value="2" label="2" />
        <CustomRadio name="q8" value="3" label="3" />
        <CustomRadio name="q8" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Nine() {
  return (
    <Box>
      {/* Guru dan Siswa Berbeda */}
      <Typography variant="body1">
        Sebagai siswa yang menguasai kesiapsiagaan bencana, kita harus bisa mengenali tanda-tanda
        bencana atau musibah yang akan terjadi dengan menggunakan panca indera karena tanda-tanda
        tersebut dapat berupa suara, pergerakan, perubahan warna dan tanda alam lainnya. Berikut
        merupakan tanda alam yang menunjukkan adanya bahaya bencana erupsi gunung api kecuali…
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q9" validate={required}>
        <CustomRadio name="q9" value="1" label="Gempa disekitar gunung api" />
        <CustomRadio name="q9" value="2" label="Suhu area rawan bencana menjadi panas" />
        <CustomRadio name="q9" value="3" label="Hewan-hewan turun" />
        <CustomRadio name="q9" value="4" label="Surutnya air laut" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Ten() {
  return (
    <Box>
      <Typography variant="body1">Perhatikan gambar berikut ini!</Typography>
      <ImageList
        cols={4}
        sx={{
          textAlign: 'center',

          '.MuiImageListItemBar': {
            '&-root': { backgroundColor: 'transparent', position: 'relative', color: '#000' },
            '&-titleWrap': {
              color: '#000'
            }
          }
        }}
      >
        <ImageListItem>
          <ImageListItemBar position="top" title="1" />
          <img src={q10a} alt="one" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="2" />
          <img src={q10b} alt="two" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="3" />
          <img
            src={q10c}
            alt="three"
            style={{ width: '100%', height: '100%', objectFit: 'cover ' }}
          />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="4" />
          <img
            src={q10d}
            alt="four"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </ImageListItem>
      </ImageList>
      <Typography variant="body1">
        Berdasarkan gambar diatas, alat yang dapat digunakan untuk memperingatkan jika terjadi
        bencana di sekolah adalah
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q10" validate={required}>
        <CustomRadio name="q10" value="1" label="1, 2, 4" />
        <CustomRadio name="q10" value="2" label="2, 3, 4" />
        <CustomRadio name="q10" value="3" label="1, 2, 3" />
        <CustomRadio name="q10" value="4" label="1, 4, 3" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Eleven() {
  return (
    <Box>
      <Typography variant="body1">
        Alat untuk mengukur Gerakan tanah dan gelombang seismik yang dihasilkan oleh gempa bumi dan
        letusan gunungapi yaitu...
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q11" validate={required}>
        <CustomRadio name="q11" value="1" label="Tiltmeter" />
        <CustomRadio name="q11" value="2" label="Thermometer" />
        <CustomRadio name="q11" value="3" label="Dinamometer" />
        <CustomRadio name="q11" value="4" label="Seismometer" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Twelve() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Perhatikan data siapa yang dapat membunyikan alat peringatan dini berikut!
        </Typography>
        <ol>
          <li>Bapak/Ibu guru</li>
          <li>Wali murid</li>
          <li>Siswa</li>
          <li>Petugas kantin</li>
        </ol>
        <Typography variant="body1">
          Ketika teriadi bencana, orang yang bertugas membunyikan alat peringatan tanda bahaya
          bencana adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q12" validate={required}>
        <CustomRadio name="q12" value="1" label="1" />
        <CustomRadio name="q12" value="2" label="2" />
        <CustomRadio name="q12" value="3" label="3" />
        <CustomRadio name="q12" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Thirdteen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Perhatikan pernyataan berikut ini!</Typography>
        <ol>
          <li>Tidak perduli dan tetap mengajar di kelas.</li>
          <li>Panik dan bingung harus berbuat apa.</li>
          <li>Tetap tenang dan membimbing siswa untuk evakuasi ke area yang aman.</li>
          <li>Berlari dan berteriak bersama dengan siswa mencari pertolongan.</li>
        </ol>
        <Typography variant="body1">
          Jika mendengar suara tanda peringatan bahaya erupsi gunung api, yang dapat Bapak/Ibu Guru
          lakukan adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q13" validate={required}>
        <CustomRadio name="q13" value="1" label="1" />
        <CustomRadio name="q13" value="2" label="2" />
        <CustomRadio name="q13" value="3" label="3" />
        <CustomRadio name="q13" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Fourteen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Perhatikan pernyataan berikut ini!</Typography>
        <ol>
          <li>Tetap di kelas.</li>
          <li>Berlari ke halaman sekolah.</li>
          <li>
            Mengarahkan siswa melewati arah jalur evakuasi menuju ke bangunan yang aman sebagai
            titik kumpul.
          </li>
          <li>Mengarahkan siswa melewati arah jalur evakuasi menuju ke dekat gunung Merapi.</li>
        </ol>
        <Typography variant="body1">
          Pada saat dengan belaiar di kelas, tiba-tiba terdengar tanda peringatan bahaya bencana
          erupsi gunung api. Saya harus melakukan...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q14" validate={required}>
        <CustomRadio name="q14" value="1" label="1" />
        <CustomRadio name="q14" value="2" label="2" />
        <CustomRadio name="q14" value="3" label="3" />
        <CustomRadio name="q14" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Fifteen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Berikut ini beberapa lokasi di sekolah!</Typography>
        <ol>
          <li>Halaman</li>
          <li>Toilet</li>
          <li>Kantin sekolah</li>
          <li>Bagunan yang aman dan disepakati untuk titik kumpul.</li>
        </ol>
        <Typography variant="body1">
          Lokasi yang aman ketika teriadi bencana erupsi gunung api adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q15" validate={required}>
        <CustomRadio name="q15" value="1" label="1" />
        <CustomRadio name="q15" value="2" label="2" />
        <CustomRadio name="q15" value="3" label="3" />
        <CustomRadio name="q15" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Sixteen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Berikut disajikan beberapa daftar lokasi</Typography>
        <ol>
          <li>Bukit</li>
          <li>Lapangan terbuka</li>
          <li>Daerah aliran sungai</li>
          <li>Ruang kelas</li>
          <li>Daerah dengan rambu titik kumpul</li>
          <li>Lokasi pengungsian</li>
        </ol>
        <Typography variant="body1">
          Lokasi yang aman saat terjadi bencana gunung api yakni...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q16" validate={required}>
        <CustomRadio name="q16" value="1" label="1-2-3" />
        <CustomRadio name="q16" value="2" label="1-2-5" />
        <CustomRadio name="q16" value="3" label="3-5-6" />
        <CustomRadio name="q16" value="4" label="4-5-6" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Seventeen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Perhatikan beberapa lokasi berikut ini!</Typography>
        <ol>
          <li>Area terbuka seperti halaman sekolah.</li>
          <li>Ruangan yang luas dan tertutup.</li>
          <li>Depan ruang guru.</li>
          <li>Toilet.</li>
        </ol>
        <Typography variant="body1">
          Titik kumpul ketika terjadi erupsi gunung api adalah
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q17" validate={required}>
        <CustomRadio name="q17" value="1" label="1" />
        <CustomRadio name="q17" value="2" label="2" />
        <CustomRadio name="q17" value="3" label="3" />
        <CustomRadio name="q17" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Eighteen() {
  return (
    <Box>
      <Typography variant="body1">Perhatikan beberapa tanda peringatan berikut ini!</Typography>
      <ImageList
        cols={4}
        rowHeight="100px"
        sx={{
          textAlign: 'center',

          '.MuiImageListItemBar': {
            '&-root': { backgroundColor: 'transparent', position: 'relative', color: '#000' },
            '&-titleWrap': {
              color: '#000'
            }
          }
        }}
      >
        <ImageListItem>
          <ImageListItemBar position="top" title="1" />
          <img src={q18a} alt="one" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="2" />
          <img src={q18b} alt="two" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="3" />
          <img
            src={q18c}
            alt="three"
            style={{ width: '100%', height: '100%', objectFit: 'cover ' }}
          />
        </ImageListItem>
        <ImageListItem>
          <ImageListItemBar position="top" title="4" />
          <img
            src={q18d}
            alt="four"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </ImageListItem>
      </ImageList>
      <Typography variant="body1">
        Berikut ini merupakan rambu titik kumpul yang aman saat teriadi bencana yaitu
      </Typography>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q18" validate={required}>
        <CustomRadio name="q18" value="1" label="1" />
        <CustomRadio name="q18" value="2" label="2" />
        <CustomRadio name="q18" value="3" label="3" />
        <CustomRadio name="q18" value="4" label="4" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Nineteen() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Perhatikan beberapa tindakan berikut ini!</Typography>
        <Table size="small" sx={{ my: 3 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">A</TableCell>
              <TableCell align="center">1</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Sebelum terjadi bencana</TableCell>
              <TableCell>
                <ul>
                  <li>Tidak berada di lokasi yang direkomendasikan untuk dikosongkan.</li>
                  <li>Tidak berada di lembah atau daerah aliran sungai.</li>
                  <li>Hindari tempat terbuka. Lindungi diri dari abu letusan gunungapi.</li>
                  <li>Gunakan kacamata pelindung.</li>
                  <li>Jangan memakai lensa kontak.</li>
                  <li>Gunakan masker atau kain basah untuk menutup mulut dan hidung.</li>
                  <li>
                    Kenakan pakaian tertutup yang melindungi tubuh seperti, baju lengan panjang,
                    celana paniang, dan topi.
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell align="center">B</TableCell>
              <TableCell align="center">2</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Pada saat terjadi bencana</TableCell>
              <TableCell>
                <ul>
                  <li>Perhatikan arahan dari PVMBG dan perkembangan aktivitas gunungapi.</li>
                  <li>Siapkan masker dan kacamata pelindung untuk mengatasi debu vulkanik.</li>
                  <li>
                    Mengetahui jalur evakuasi dan shelter yang telah disiapkan oleh pihak berwenang.
                  </li>
                  <li>
                    Menyiapkan skenario evakuasi lain jika dampak letusan meluas di luar prediksi
                    ahli.
                  </li>
                  <li>
                    Siapkan dukungan logistik, antara lain makanan siap saji, lampu senter dan
                    baterai cadangan, uang tunai yang cukup serta obat-obatan khusus sesuai pemakai.
                  </li>
                  <li>
                    Jika mendengar peringatan tanda bahaya erupsi gunung api, segera melakukan
                    evakuasi ke lokasi yang aman.
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell align="center">C</TableCell>
              <TableCell align="center">3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Setelah terjadi bencana</TableCell>
              <TableCell>
                <ul>
                  <li>Kurangi terpapar dari abu vulkanik.</li>
                  <li>
                    Hindari mengendarai mobil di daerah yang terkena hujan abu vulkanik sebab bisa
                    merusak mesin kendaraan.
                  </li>
                  <li>
                    Bersihkan atap dari timbunan debu vulkanik karena beratnya bisa merobohkan dan
                    merusak atap rumah atau bangunan.
                  </li>
                  <li>
                    Waspadai wilayah aliran sungai yang berpotensi terlanda bahaya lahar pada musim
                    hujan.
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Typography variant="body1">
          Berdasarkan daftar peralatan, tersebut di atas, urutan yang benar adalah...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q19" validate={required}>
        <CustomRadio name="q19" value="1" label="A-1, B-2, C-3" />
        <CustomRadio name="q19" value="2" label="A-2, B-3, C-1" />
        <CustomRadio name="q19" value="3" label="A-2, B-1, C-3" />
        <CustomRadio name="q19" value="4" label="A-3, B-1, C-2" />
      </CustomRadioGroup>
    </Box>
  );
}

export function Twenty() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">Berikut in merupakan daftar peralatan-peralatan</Typography>
        <ol>
          <li>Lampu senter</li>
          <li>Obat P3K</li>
          <li>Peluit</li>
          <li>Sarung tangan</li>
          <li>Pakaian</li>
          <li>Makanan dan minuman</li>
          <li>Selimut</li>
          <li>Foto keluarga</li>
          <li>Dokumen Penting</li>
          <li>Sejumlah uang</li>
        </ol>
        <Typography variant="body1">
          Berdasarkan daftar peralatan tersebut di atas, yang harus kalian persiapkan dalam tas
          siaga bencana yaitu...
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <CustomRadioGroup name="q20" validate={required}>
        <CustomRadio name="q20" value="1" label="1-3-5-7-9" />
        <CustomRadio name="q20" value="2" label="1-2-4-7-10" />
        <CustomRadio name="q20" value="3" label="2-5-6-7-9" />
        <CustomRadio name="q20" value="4" label="Semua benar" />
      </CustomRadioGroup>
    </Box>
  );
}
