import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, List, ListItemButton, Typography } from '@mui/material';

// Assets
import { Colors } from 'utils/theme';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function PengertianBencana() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Pengertian Bencana
      </Typography>
      {contentId === '2' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <Typography variant="body1" component="p">
              Bencana adalah peristiwa atau rangkaian peristiwa yang mengancam dan mengganggu
              kehidupan dan penghidupan masyarakat yang disebabkan, baik oleh faktor alam dan/atau
              faktor nonalam maupun faktor manusia sehingga mengakibatkan timbulnya korban jiwa
              manusia, kerusakan lingkungan, kerugian harta benda, dan dampak psikologis
              (Undang-undang Nomor 24 Tahun 2007).
            </Typography>
            <br />
          </Box>
          <Box sx={{ flex: 1, borderLeft: '1px solid #ccc', paddingLeft: '24px' }}>
            <Typography variant="h6">Bahan Pembelajaran</Typography>
            <List>
              {[
                {
                  id: 1,
                  title: 'UU 24 Tahun 2007',
                  url: 'https://drive.google.com/file/d/1lESluLyhDwmr_D2n-K-Wz9V6qaQhILux/view?usp=sharing'
                }
              ].map((item) => (
                <Link key={item.id} href={item.url} sx={{ textDecoration: 'none' }}>
                  <ListItemButton
                    sx={{
                      background: Colors.primary.main,
                      color: Colors.white,
                      fontWeight: 800,
                      '&:hover': {
                        background: Colors.primary.dark
                      }
                    }}
                  >
                    {item.title}
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {contentId === '3' && (
        <YouTubeEmbed
          embedId="yAJxZADUtxA"
          preVideoText="Untuk menambah pengetahuan tentang bencana, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 2."
          caption="(Sumber: youtube.com. Kedaruratan Logistik. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '4' && (
        <YouTubeEmbed
          embedId="qjtEmeOYi30"
          preVideoText="Untuk menambah pengetahuan tentang bencana, Bapak/Ibu Guru dapat melihat video 2 berikut ini."
          postVideoText="Setelah melihat video 2, Bapak/Ibu Guru dapat melanjutkan ke Video 3."
          caption="(Sumber: youtube.com. Geography Channel. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '5' && (
        <YouTubeEmbed
          embedId="1Ess9QudIEE"
          preVideoText="Untuk menambah pengetahuan tentang bencana, Bapak/Ibu Guru dapat melihat video 3 berikut ini."
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah melihat video 1,2,3, Bapak/Ibu Guru dapat menyimpulkan :
              <ol>
                <li>Bencana yaitu...</li>
                <li>Bencana dibagi menjadi 3 yaitu bencana ..., ..., dan ...</li>
                <li>Siklus penanggulangan bencana yaitu...</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. Direktorat Mitigasi Bencana. diinput dalam
            website SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default PengertianBencana;
