import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Paper } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Delete } from '@mui/icons-material';

import dataGridLocalizationID from 'utils/localization';
import { handleDeleteUser, handleGetUsers } from 'redux/actions/users.actions';

function DeleteButton(props) {
  const dispatch = useDispatch();
  const { hasFocus, value } = props;

  const buttonElement = React.useRef(null);
  const rippleRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      rippleRef.current.stop({});
    }
  }, [hasFocus]);

  const deleteSchool = async () => {
    await dispatch(handleDeleteUser(value));
    window.location.reload();
  };

  return (
    <IconButton
      ref={buttonElement}
      touchRippleRef={rippleRef}
      onClick={deleteSchool}
      tabIndex={hasFocus ? 0 : -1}
    >
      <Delete sx={{ color: '#ef4d4d' }} />
    </IconButton>
  );
}

DeleteButton.propTypes = {
  hasFocus: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

const columns = [
  { field: 'id', headerName: '#', type: 'number', align: 'left', headerAlign: 'left' },
  { field: 'firstName', headerName: 'Nama Depan', width: 150 },
  { field: 'lastName', headerName: 'Nama Belakang', width: 150 },
  {
    field: 'fullName',
    headerName: 'Nama Lengkap',
    width: 250,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
  },
  {
    field: 'role',
    type: 'singleSelect',
    valueOptions: ['Guru', 'Siswa'],
    headerName: 'Role',
    width: 70
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250
  },
  {
    field: 'username',
    headerName: 'Username',
    width: 120
  },
  {
    field: 'uid',
    headerName: 'Aksi',
    sortable: false,
    hideable: false,
    filterable: false,
    renderCell: DeleteButton,
    width: 70
  }
];

const csvOptions = { delimiter: ';', fileName: 'SIPENA - Data Pengguna' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function UserList() {
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.users, shallowEqual);

  const [rows, setRows] = useState([]);

  const fetchRef = React.useRef(null);

  React.useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    dispatch(handleGetUsers());
  }, []);

  React.useEffect(() => {
    if (users.length !== 0) {
      setRows(users);
    }
  }, [users]);

  return (
    <Paper sx={{ mb: 4, p: 3, borderRadius: 2 }}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          localeText={dataGridLocalizationID}
          slots={{
            toolbar: CustomToolbar
          }}
        />
      </Box>
    </Paper>
  );
}

export default UserList;
