import React from 'react';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Siswa from '../Content/Siswa';
import Guru from '../Content/Guru';

import './MainContent.scss';

function CourseContent() {
  const user = getLocalStorage('userLogin');

  return (
    <Box
      component="main"
      sx={{
        marginLeft: '300px',
        marginBottom: '70px',
        p: 6,
        width: '100%'
      }}
    >
      {user?.role === 'guru' ? (
        <React.Fragment>
          <Guru />
        </React.Fragment>
      ) : (
        <Siswa />
      )}
    </Box>
  );
}

export default CourseContent;
