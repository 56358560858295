import {
  SET_ERROR,
  SET_LOADING,
  SET_PROFILE,
  SET_USERS_LIST,
  SET_TOTAL_USERS
} from '../actions/users.actions';

const initialState = {
  error: { isError: false, message: '' },
  isLoading: false,
  profile: {},
  users: [],
  totalUsers: {}
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_ERROR:
      return {
        ...state,
        error: payload
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: payload
      };
    case SET_USERS_LIST:
      return {
        ...state,
        users: payload
      };
    case SET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: payload
      };

    default:
      return state;
  }
};
