import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Colors } from 'utils/theme';
import { Link, useParams } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { setSelectedMenu } from 'redux/actions/class.actions';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { teacherMenus, studentMenus } from './menus';

const drawerWidth = 300;

function CourseSidebar() {
  const dispatch = useDispatch();
  const user = getLocalStorage('userLogin');

  const { contentId } = useParams();

  const { selectedMenu } = useSelector((state) => state.classes, shallowEqual);

  const [menus, setMenus] = useState([]);

  useEffect(() => {
    dispatch(setSelectedMenu(`${contentId}`));
  }, [contentId]);

  useEffect(() => {
    if (user.role === 'guru') {
      setMenus(teacherMenus);
    } else {
      setMenus(studentMenus);
    }
  }, []);

  return (
    <Box
      sx={{
        maxWidth: drawerWidth,
        width: '100%',
        height: `calc(100vh - 65px)`,
        borderRight: '1px solid black',
        overflowY: 'scroll',
        position: 'fixed',
        background: Colors.white
      }}
    >
      <List>
        {menus.map((item) => (
          <React.Fragment key={item.id}>
            <ListItemButton
              // disableRipple
              disabled={!item.completed}
              // disabled={() => checkMaterial(item)}
              selected={selectedMenu === item.id}
              onClick={() => dispatch(setSelectedMenu(item.id))}
              sx={{
                p: 0
              }}
            >
              <Link
                to={item.id}
                style={{
                  color: Colors.black,
                  textDecoration: 'none',
                  width: '100%',
                  height: '100%',
                  padding: '10px 25px'
                }}
              >
                <ListItemText primary={item.title} />
              </Link>
            </ListItemButton>
            {item?.childs?.map((child) => (
              <ListItemButton
                key={child.id}
                // disableRipple
                disabled={!child.completed}
                selected={selectedMenu === child.id}
                onClick={() => dispatch(setSelectedMenu(child.id))}
                sx={{
                  p: 0
                }}
              >
                <Link
                  to={child.id}
                  style={{
                    color: Colors.black,
                    textDecoration: 'none',
                    width: '100%',
                    height: '100%',
                    padding: '10px 25px'
                  }}
                >
                  <ListItemText primary={child.title} />
                </Link>
              </ListItemButton>
            ))}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export default CourseSidebar;
