import React from 'react';
import { useParams } from 'react-router-dom';

import PrePostTest from './PrePostTest';
import LaguKebencanaan from './LaguKebencanaan';
import PengertianBencana from './PengertianBencana';
import JenisBencana from './JenisBencana';
import ErupsiGunungMerapi from './ErupsiGunungMerapi';
import SistemPeringatanDini from './SistemPeringatanDini';
import RencanaEvakuasi from './RencanaEvakuasi';
import ProsedurTetap from './ProsedurTetap';

function Siswa() {
  const { contentId } = useParams();

  return (
    <React.Fragment>
      {contentId === '1' ? <PrePostTest /> : null}
      {contentId === '2' ? <LaguKebencanaan /> : null}
      {['3', '4', '5', '6'].includes(contentId) ? <PengertianBencana /> : null}

      {contentId === '7' ? <JenisBencana /> : null}
      {['8', '9', '10', '11', '12', '13', '14', '15', '16'].includes(contentId) ? (
        <ErupsiGunungMerapi />
      ) : null}
      {['17', '18', '19', '20'].includes(contentId) ? <SistemPeringatanDini /> : null}
      {['21', '22'].includes(contentId) ? <RencanaEvakuasi /> : null}
      {['23', '24', '25', '26', '27', '28'].includes(contentId) ? <ProsedurTetap /> : null}
      {contentId === '29' ? <PrePostTest isPost /> : null}
    </React.Fragment>
  );
}

export default Siswa;
