import {
  serviceDeleteTopic,
  serviceDetailTopic,
  serviceEditTopic,
  serviceGetTopics,
  serviceNewComment,
  serviceNewTopic,
  serviceTopicsByUser
} from 'utils/api';

export const SET_LOADING = 'discussion/SET_LOADING';
export const SET_QUESTIONS = 'discussion/SET_QUESTIONS';
export const SET_QUESTION_DETAILS = 'discussion/SET_QUESTION_DETAILS';
export const SET_SELECTED_QUESTION = 'discussion/SET_SELECTED_QUESTION';
export const SET_NEW_QUESTION = 'discussion/SET_NEW_QUESTION';
export const SET_COMMENTS = 'discussion/SET_COMMENTS';
export const SET_SELECTED_MENU = 'discussion/SET_SELECTED_MENU';
export const SET_QUESTION_POPUP = 'discussion/SET_QUESTION_POPUP';
export const SET_IS_EDIT = 'discussion/SET_IS_EDIT';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setQuestions = (payload) => ({
  type: SET_QUESTIONS,
  payload
});

export const setQuestionDetails = (payload) => ({
  type: SET_QUESTION_DETAILS,
  payload
});

export const setSelectedQuestion = (payload) => ({
  type: SET_SELECTED_QUESTION,
  payload
});

export const setNewQuestion = (payload) => ({
  type: SET_NEW_QUESTION,
  payload
});

export const setQuestionPopup = (payload) => ({
  type: SET_QUESTION_POPUP,
  payload
});

export const setComments = (payload) => ({
  type: SET_COMMENTS,
  payload
});

export const setSelectedMenu = (payload) => ({
  type: SET_SELECTED_MENU,
  payload
});

export const setIsEdit = (payload) => ({
  type: SET_IS_EDIT,
  payload
});

export const getAllQuestions = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetTopics();
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setQuestions(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const handleDeleteQuestions = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceDeleteTopic(payload);
    const { data } = await serviceGetTopics();
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setQuestions(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const handleEditQuestions = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceEditTopic(payload);
    if (status === 200) {
      dispatch(setLoading(false));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getQuestionDetails = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setQuestionDetails({}));
    const { data, status } = await serviceDetailTopic({ topicUrl: payload });
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setQuestionDetails(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const getTopicsByUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceTopicsByUser(payload);
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setQuestions(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const handleNewQuestion = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceNewTopic(payload);
    if (status === 201) {
      dispatch(setNewQuestion(data));
      dispatch(setLoading(false));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};

export const handleNewComment = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceNewComment(payload);
    if (status === 200) {
      dispatch(setLoading(false));
    }
  } catch (error) {
    dispatch(setLoading(false));
  }
};
