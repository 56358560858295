import {
  serviceDeleteUser,
  serviceGetTotalUsers,
  serviceGetUserProfile,
  serviceGetUsers,
  serviceUpdateProfile
} from 'utils/api';
import { removeLocalStorage, setLocalStorage } from 'utils/helpers/localStorage';

export const SET_LOADING = 'loginAction/SET_LOADING';
export const SET_ERROR = 'loginAction/SET_ERROR';
export const SET_PROFILE = 'loginAction/SET_PROFILE';
export const SET_USERS_LIST = 'loginAction/SET_USERS_LIST';
export const SET_TOTAL_USERS = 'loginAction/SET_TOTAL_USERS';
export const SET_DELETE_USER = 'loginAction/SET_DELETE_USER';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
});

export const setProfile = (payload) => ({
  type: SET_PROFILE,
  payload
});

export const setUsersList = (payload) => ({
  type: SET_USERS_LIST,
  payload
});

export const setTotalUsers = (payload) => ({
  type: SET_TOTAL_USERS,
  payload
});

export const handleGetUsers = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetUsers();

    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setUsersList(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleDeleteUser = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceDeleteUser({ userId: payload });

    if (status === 200) {
      dispatch(setLoading(false));
      window.location.reload();
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleGetProfile = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetUserProfile();

    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setProfile(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleUpdateProfile = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // const user = getLocalStorage('userLogin');
    const { data, status } = await serviceUpdateProfile(payload);
    // console.warn('Data', { data, status });
    if (status === 200) {
      removeLocalStorage('users');
      setLocalStorage('users', data);
      window.location.assign('/profile');
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleGetTotalUsers = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetTotalUsers();
    console.warn('Total Users', { data, status });
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setTotalUsers(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};
