import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Typography } from '@mui/material';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function SistemPeringatanDini() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Sistem Peringatan Dini
      </Typography>
      {contentId === '16' && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <Box sx={{ flex: 3 }}>
            <h6>Pengertian</h6>
            <p>
              Peringatan dini adalah serangkaian kegiatan pemberian peringatan sesegera mungkin
              kepada masyarakat tentang kemungkinan terjadinya bencana pada suatu tempat oleh
              lembaga yang berwenang (PP No.21 Tahun 2008)
            </p>
            <br />
            <h6>Syarat</h6>
            <ul>
              <li>Adanya informasi resmi yang dapat dipercaya</li>
              <li>Adanya alat dan tanda bahaya yang disepakati</li>
              <li>Ada cara/mekanisme untuk menyebarluaskan peringatan tersebut</li>
            </ul>
          </Box>
          <Box sx={{ flex: 1 }} />
        </Box>
      )}

      {contentId === '17' && (
        <YouTubeEmbed
          embedId="tsiZUgW7PuE"
          preVideoText="Untuk memperdalam pengetahuan tentang sistem peringatan dini, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText="Setelah melihat video 1, Bapak/Ibu Guru dapat melanjutkan ke Video 2."
          caption="(Sumber: youtube.com. IndonesiaBaikID. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '18' && (
        <YouTubeEmbed
          embedId="42eATChRzyE"
          preVideoText="Untuk memperdalam pengetahuan tentang sistem peringatan dini, Bapak/Ibu Guru dapat melihat video 2 berikut ini."
          postVideoText="Setelah melihat video 2, Bapak/Ibu Guru dapat melanjutkan ke Video 3."
          caption="(Sumber: youtube.com. Halo Edukasi. diinput dalam website SIPENA
November 2022)"
        />
      )}

      {contentId === '19' && (
        <YouTubeEmbed
          embedId="mwYAVGGfgHI"
          preVideoText="Untuk menambah pengetahuan tentang sistem peringatan dini, Bapak/Ibu Guru dapat melihat video 3 berikut ini."
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah melihat video 1,2 dan 3, Bapak/Ibu Guru dapat menyimpulkan :
              <ol>
                <li>Sistem Peringatan Dini adalah...</li>
                <li>Tanda-tanda akan terjadinya erupsi gunung api yaitu...</li>
                <li>4 tingkat status gunung api yaitu</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. KOMPASTV. diinput dalam website
            SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default SistemPeringatanDini;
