import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Typography } from '@mui/material';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';

function RencanaEvakuasi() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Rencana Evakuasi
      </Typography>
      {contentId === '20' && (
        <Box sx={{ maxWidth: '70%' }}>
          <h6>Pengertian</h6>
          <p>
            Masyarakat di kawasan rawan bencana wajib memiliki rencana evakuasi untuk penyelamatan
            diri beserta harta bendanya ketempat lebih aman sebelum datang ancaman. Undang-undang PB
            No 24/2007 pada Pasal 45, ayat 2, butir e berbunyi “Kesiapsiagaan sebagaimana dimaksud
            pada ayat (1)dilakukan melalui:” e. penyiapan lokasi evakuasi.
          </p>
          <p>
            Rencana evakuasi di sekolah berhubungan dengan alat yang digunakan untuk evakuasi, apa
            saja yang perlu diselamatkan, dan bagaimana strategi penyelamatan warga sekolah dan
            asset penting sekolah.
          </p>
        </Box>
      )}

      {contentId === '21' && (
        <YouTubeEmbed
          embedId="C7EOB92A8P4"
          preVideoText="Untuk menambah pengetahuan tentang rencana evakuasi, Bapak/Ibu Guru dapat melihat video 1 berikut ini."
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah melihat video 1, Bapak/Ibu Guru dapat menyimpulkan :
              <ol>
                <li>Rencana Evakuasi yaitu...</li>
                <li>Yang harus diselamatkan ketika terjadi bencana di sekolah antara lain...</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. KKN Salaman Magelang 2021. diinput dalam
            website SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default RencanaEvakuasi;
