import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, TextField, Button, IconButton } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSchools } from 'redux/actions/login.actions';
import { handleDeleteSchool, handleNewSchool } from 'redux/actions/schools.action';
import { Delete } from '@mui/icons-material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import dataGridLocalizationID from 'utils/localization';

function createData(id, name, address, schoolId) {
  return { id, name, address, schoolId };
}

function DeleteButton(props) {
  const dispatch = useDispatch();
  const { hasFocus, value } = props;

  const buttonElement = React.useRef(null);
  const rippleRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      const input = buttonElement.current?.querySelector('input');
      input?.focus();
    } else if (rippleRef.current) {
      // Only available in @mui/material v5.4.1 or later
      rippleRef.current.stop({});
    }
  }, [hasFocus]);

  const deleteSchool = async () => {
    await dispatch(handleDeleteSchool(value));
    window.location.reload();
  };

  return (
    <IconButton
      ref={buttonElement}
      touchRippleRef={rippleRef}
      onClick={deleteSchool}
      tabIndex={hasFocus ? 0 : -1}
    >
      <Delete sx={{ color: '#ef4d4d' }} />
    </IconButton>
  );
}

DeleteButton.propTypes = {
  hasFocus: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

const columns = [
  {
    field: 'id',
    headerName: '#',
    type: 'number'
  },
  {
    field: 'name',
    headerName: 'Nama Sekolah',
    flex: 1
  },
  {
    field: 'address',
    headerName: 'Alamat',
    flex: 1
  },
  {
    field: 'schoolId',
    headerName: 'Aksi',
    sortable: false,
    hideable: false,
    filterable: false,
    renderCell: DeleteButton,
    width: 70
  }
];

const csvOptions = { delimiter: ';', fileName: 'SIPENA - Data Sekolah' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

function Schools() {
  const dispatch = useDispatch();

  const { schools } = useSelector((state) => state.login, shallowEqual);

  const [rows, setRows] = React.useState([]);
  const [newSchool, setNewSchool] = React.useState({
    name: '',
    address: ''
  });

  const fetchRef = React.useRef(null);

  React.useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;
    dispatch(getSchools());
  }, []);

  React.useEffect(() => {
    if (schools) {
      const newRows = schools.map((item, index) =>
        createData(index + 1, item.name, item.address, item.school_id)
      );
      setRows(newRows);
    }
  }, [schools]);

  const createNewSchool = () => {
    dispatch(handleNewSchool(newSchool));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ mb: 4, p: 3, borderRadius: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Nama"
                fullWidth
                onChange={(e) => setNewSchool({ ...newSchool, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Alamat"
                fullWidth
                multiline
                rows={3}
                onChange={(e) => setNewSchool({ ...newSchool, address: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" size="large" onClick={createNewSchool}>
                Tambahkan
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ p: 3, borderRadius: 2, width: '100%' }}>
          <DataGrid
            autoHeight
            columns={columns}
            rows={rows}
            pageSizeOptions={[10, 25, 50, 100]}
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            localeText={dataGridLocalizationID}
            slots={{
              toolbar: CustomToolbar
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Schools;
