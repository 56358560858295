import landMapImage from 'assets/images/img/land.png';
import merapiImage from 'assets/images/img/merapi.jpg';
import jalurEvakuasiImage from 'assets/images/img/jalur-evakuasi.png';
import kentonganImage from 'assets/images/img/kentongan.jpg';
import sirineImage from 'assets/images/img/sirine.jpg';
import loncengImage from 'assets/images/img/lonceng.jpg';
import tasSiagaImage from 'assets/images/img/tas-siaga.png';

const content = [
  {
    title: 'Pre-Test',
    path: '0',
    content: `
    <p>Sebelum mengakses materi silahkan mengerjakan Pretest dengan menekan link berikut : <a href="https://docs.google.com/forms/d/1kk4SeeaHd46c-Fm7NdEdBzvG3NbzM7xuSjl50Ka53dY/edit#" target='blank'>Pre-Test</a></p>
    `
  },
  {
    title: 'Pengertian Bencana',
    path: '1',
    content: `
    <strong>Indonesia merupakan negara yang rawan bencana.</strong>
    <p>Hal ini disebabkan oleh letak geografis  Indonesia Yang berada dalam jalur ring of fire alias cincin api.</p>
    <img src=${landMapImage} alt="Gambar Peta" width="100%" />
    <p>Ring of fire adalah deretan gunung berapi di kawasan Pasifik.</p>
    <p>
      <strong>Bahkan dari 500 gunung api di seluruh dunia, 129 di antaranya berada di
      Indonesia.</strong>
    </p>

    <p>
      Sedangkan menurut risiko bencananya, Indonesia ada di peringkat 1 untuk
      risiko tsunami dan tanah longsor, dan peringkat 3 untuk risiko gempa.
    </p>

    <p>
      Itu berarti, kita yang berada di Indonesia akan terus berhadapan dengan
      risiko bencana.
    </p>

    <p>
      Oleh sebab itu, penting bagi kita untuk mengetahui apa yang harus
      dilakukan sebelum, saat , dan setelah terjadi bencana.
    </p>
    `,
    child: [
      {
        id: 'jabeu',
        subtitle: 'Video 1',
        embedId: 'yAJxZADUtxA'
      },
      {
        id: 'jandu',
        subtitle: 'Video 2',
        embedId: 'qjtEmeOYi30'
      },
      {
        id: 'oaasd',
        subtitle: 'Video 3',
        embedId: '1Ess9QudIEE'
      }
    ]
  },
  {
    title: 'Jenis Bencana',
    path: '2',
    content: `
      <div>
      <p>Ayo bermain Penyebab dan Jenis bencana! Yuk..cocokan jenis penyebab dan bencananya dibawah ini!</p>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSe6uR6Kp_Wy-K26ekozDQDXj-ZsdJJyYRV4SiFaQGKdFdQklQ/viewform" target="blank">Klik disini untuk mulai bermain</a>
      </div>
    `
  },
  {
    title: 'Erupsi Gunung Merapi',
    path: '4',
    content: `
    <div>
    <img src=${merapiImage} alt="Gambar Peta" width="100%" />
    <p>Gunung Merapi merupakan gunung api tipe strato, dengan ketinggian 2.980 meter. Secara administratif terletak pada 4 wilayah Kabupaten yaitu Kabupaten Sleman, DIY, dan Kabupaten Magelang, Boyolali, dan Klaten Provinsi Jawa Tengah.
    </p>
    </div>`,
    attachment: [
      {
        id: 'das',
        title: '1. Pengenalan Gunung Api',
        url: 'https://www.esdm.go.id/assets/media/content/Pengenalan_Gunung_Api.pdf'
      },
      {
        id: 'das',
        title: '2. Data Dasar Gunung Api - Gunung Merapi',
        url: 'https://vsi.esdm.go.id/index.php/gunungapi/data-dasar-gunungapi/542-g-merapi'
      }
    ],
    child: [
      {
        id: 'asinuw',
        subtitle: 'Video 1',
        embedId: '3tuZ7Y_TpMw'
      },
      {
        id: 'asdui',
        subtitle: 'Video 2',
        embedId: 'GlzpBn0CSOY'
      },
      {
        id: 'nius',
        subtitle: 'Video 3',
        embedId: 'iPZlsZrm5Dw'
      },
      {
        id: 'alsd',
        subtitle: 'Video 4',
        embedId: '6lshF9ZjOhQ'
      },
      {
        id: 'asufe',
        subtitle: 'Video 5',
        embedId: 'UcbENUL6G9o'
      },
      {
        id: 'cayue',
        subtitle: 'Video 6',
        embedId: 'y0FVhINZAaI'
      },
      {
        id: 'asdwe',
        subtitle: 'Video 7',
        embedId: '6IOzoKFU7-Q'
      },
      {
        id: 'ewsa',
        subtitle: 'Video 8',
        embedId: 'TN0oEVqAgcU'
      }
    ]
  },
  {
    title: 'Sistem Peringatan Dini',
    path: '5',
    content: `
    <p>Sistem peringatan dini : sistem peringatan tanda bahaya  sebelum terjadi bencana.</p>
    <br />
    <h6>Kriteria :</h6>
    <ol>
      <li>Ada alat</li>
      <li>Ada yang mengoperasikan alat</li>
      <li>Ada informasi bunyi yang disepakati</li>
    </ol>
    <div>
      <img src=${kentonganImage} alt="Kentongan" width="30%" />
      <img src=${sirineImage} alt="Sirine" width="30%" />
      <img src=${loncengImage} alt="Sirine" width="30%" />
    </div>
    `,
    child: [
      {
        id: 'uawyb',
        subtitle: 'Video 1',
        embedId: 'tsiZUgW7PuE'
      },
      {
        id: 'asdjk',
        subtitle: 'Video 2',
        embedId: '42eATChRzyE'
      },
      {
        id: 'asjdk',
        subtitle: 'Video 3',
        embedId: 'mwYAVGGfgHI'
      }
    ]
  },
  {
    title: 'Rencana Evakuasi',
    path: '6',
    content: `<h6>Pengertian</h6>
    <p>Rencana evakuasi di sekolah berhubungan dengan alat yang digunakan untuk evakuasi, apa saja yang perlu diselamatkan, dan bagaimana strategi penyelamatan warga sekolah dan asset penting sekolah. 
    </p>
    <img src=${jalurEvakuasiImage} alt="gambar jalur evakuasi" width="100%" />
    `,
    child: [
      {
        id: 'wuygq',
        subtitle: 'Video 1',
        embedId: 'C7EOB92A8P4'
      }
    ]
  },
  {
    title: 'Prosedur Tetap',
    path: '7',
    content: `
    <p>Apa yang harus dilakukan <strong>JIKA TERJADI ERUPSI GUNUNGAPI DI SEKOLAH!</strong></p>
    <p>Ketika sedang di kelas dan mendengar suara peringatan dini tanda bahaya yang dilakukan adalah tetap tenang dan mengikuti instruksi/ petunjuk bapak/ibu guru untuk melakukan evakuasi menuju ke lokasi yang aman/ titik kumpul.
    </p>
    <p>Kegiatan Pembelajaran &rarr; peringatan dini &rarr; tetap tenang dan evakuasi &rarr; titik kumpul</p>
    <div>
      <p>Tanda-Tanda:</p>
      <ol>
        <li>Ada peringatan dini dari pemerintah.</li>
        <li>Keluar asap yang tinggi dari gunung.</li>
        <li>Adanya suara letusan.</li>
        <li>Ada getaran karena letusan.</li>
        <li>Muncul lahar dari puncak gunung.</li>
        <li>Keluarnya awan panas.</li>
        <li>Hewan-hewan turun ke pemukiman penduduk.</li>
      </ol>
    </div>
    <br />
    <div>
      <h6>Apa yang harus dilakukan pada saat:</h6>
      <p>Sebelum Terjadinya Bencana Erupsi</p>
      <ol>
        <li>
          Perhatikan arahan dari PVMBG dan perkembangan aktivitas gunungapi.
        </li>
        <li>
          Siapkan masker dan kacamata pelindung untuk mengatasi debu vulkanik.
        </li>
        <li>
          Mengetahui jalur evakuasi dan shelter yang telah disiapkan oleh pihak
          berwenang.
        </li>
        <li>
          Menyiapkan skenario evakuasi lain jika dampak letusan meluas di luar
          prediksi ahli.
        </li>
        <li>
          Siapkan dukungan logistik, antara lain makanan siap saji, lampu senter
          dan baterai cadangan, uang tunai yang cukup serta obat-obatan khusus
          sesuai pemakai.
        </li>
        <li>
          Jika mendengar peringatan tanda bahaya erupsi gunung api, segera
          melakukan evakuasi ke lokasi yang aman.
        </li>
      </ol>
      <br />
      <p>Saat Terjadinya Bencana Erupsi Gunung Api</p>
      <ol>
        <li>Tidak berada di lokasi yang direkomendasikan untuk dikosongkan.</li>
        <li>Tidak berada di lembah atau daerah aliran sungai.</li>
        <li>
          Hindari tempat terbuka. Lindungi diri dari abu letusan gunungapi.
        </li>
        <li>Gunakan kacamata pelindung.</li>
        <li>Jangan memakai lensa kontak.</li>
        <li>Gunakan masker atau kain basah untuk menutup mulut dan hidung.</li>
        <li>
          Kenakan pakaian tertutup yang melindungi tubuh seperti, baju lengan
          panjang, celana panjang, dan topi.
        </li>
      </ol>
      <br />
      <p>Setelah Terjadinya Bencana Erupsi Gunung Api</p>
      <ol>
        <li>Kurangi terpapar dari abu vulkanik.</li>
        <li>
          Hindari mengendarai mobil di daerah yang terkena hujan abu vulkanik
          sebab bisa merusak mesin kendaraan.
        </li>
        <li>
          Bersihkan atap dari timbunan debu vulkanik karena beratnya bisa
          merobohkan dan merusak atap rumah atau bangunan.
        </li>
        <li>
          Waspadai wilayah aliran sungai yang berpotensi terlanda bahaya lahar
          pada musim hujan.
        </li>
      </ol>
    </div>
    <br />
    <p>
    Tas Siaga Bencana (TSB) merupakan tas yang dipersiapkan untuk berjaga-jaga apabila terjadi suatu bencana atau kondisi darurat lain. Tujuan TSB sebagai persiapan untuk bertahan hidup saat bantuan belum datang dan memudahkan kita saat evakuasi menuju tempat aman.
    </p>
    <br />
    <img src=${tasSiagaImage} alt="Tas Siaga untuk 3 Hari" width="100%" />
    <br />
    <h6>Pilihlah dengan cermat dan masukkanlah 12 benda yang menurutmu penting kedalam tas siagamu!</h6>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfHZDQGXeTfSP9wugM5M7Umm0Klb_6sdxpJnV5FaB_kxEni5w/viewform" target="blank">Latihan tas siaga bencana</a>
    `,
    child: [
      {
        id: 'awuf',
        subtitle: 'Video 1',
        embedId: 'K_b_LwVpehc'
      },
      {
        id: 'daniu',
        subtitle: 'Video 2',
        embedId: 'DdTX2Or_UxY'
      },
      {
        id: 'liwee',
        subtitle: 'Video 3',
        embedId: 'wGm-imRsioA'
      },
      {
        id: 'daswe',
        subtitle: 'Video 4',
        embedId: '73_zCmA5yWg'
      }
    ]
  },
  {
    title: 'Post-Test',
    path: '8',
    content: `
    <p>Setelah mempelajari materi SIPENA sekarang kamu bisa cek pengetahuan kesiapsiagaanmu! <br />
    Klik link <a href="https://docs.google.com/forms/d/1ItV_MGZwWf-ylBGxQeXhCa48v3pQBXXWPu_s_m2sK9U/edit" target='blank'>Post-Test</a></p>
    `
  }
];

export default content;
