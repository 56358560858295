import React, { useEffect } from 'react';
import { Avatar, Box, Container, Grid } from '@mui/material';
// import profileImage from 'assets/images/img/man1.jpg';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { useDispatch } from 'react-redux';
import { handleGetProfile } from 'redux/actions/users.actions';
import TeacherProfile from './components/TeacherProfile';
import StudentProfile from './components/StudentProfile';

function ProfilePage() {
  const dispatch = useDispatch();

  const user = getLocalStorage('userLogin');
  const isTeacher = user.role === 'guru';

  const fetchRef = React.useRef();

  useEffect(() => {
    if (fetchRef.current) return;
    fetchRef.current = true;

    dispatch(handleGetProfile());
  }, []);
  return (
    <Box py={6}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Avatar
              alt="Deni Saputra"
              // src={profileImage}
              sx={{ width: 128, height: 128, margin: '0px auto' }}
            />
          </Grid>
          <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {isTeacher ? <TeacherProfile /> : <StudentProfile />}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProfilePage;
