import { serviceGetScores } from 'utils/api';

export const SET_ERROR = 'loginAction/SET_ERROR';
export const SET_LOADING = 'loginAction/SET_LOADING';
export const SET_QUIZ_SCORES = 'loginAction/SET_QUIZ_SCORES';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
});

export const setQuizScores = (payload) => ({
  type: SET_QUIZ_SCORES,
  payload
});

export const handleGetScores = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetScores();
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setQuizScores(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};
