// pages
import { Landing, Login, Register } from 'pages';

const router = [
  {
    path: '/',
    element: <Landing />
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'register',
    element: <Register />
  }
];

export default router;
