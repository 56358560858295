import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@mui/material';

function Button({ children, ...props }) {
  return <MuiButton {...props}>{children}</MuiButton>;
}

Button.propTypes = {
  children: PropTypes.node
};

Button.defaultProps = {
  children: null
};

export default Button;
