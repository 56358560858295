import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { Colors } from 'utils/theme';
import { ArrowBack } from '@mui/icons-material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getQuestionDetails,
  handleDeleteQuestions,
  handleEditQuestions,
  handleNewComment,
  setIsEdit,
  setQuestionPopup
} from 'redux/actions/discussion.actions';
import moment from 'moment';
import Loader from 'components/molecules/Loader';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { AskQuestion } from '../DiscussionHome/components/StyledComponent';

function CommentItem({ dataset }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      sx={{
        borderBottom: '1px solid #00000020',
        '&:last-child': {
          border: 'none'
        }
      }}
    >
      <Box display="flex" gap={1}>
        {/* <Avatar>{user.image}</Avatar> */}
        <Box>
          <Typography variant="body2" sx={{ mb: 1, fontSize: 14, color: '#0007', fontWeight: 400 }}>
            {dataset.fullname} - {moment(dataset?.created_at).fromNow()}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>
            {dataset.comment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CommentItem.propTypes = {
  dataset: PropTypes.object.isRequired
};

function DiscussionDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { questionId } = useParams();
  const user = getLocalStorage('userLogin');

  const { questionDetails, isLoading, openQuestionPopup } = useSelector(
    (state) => state.discussion,
    shallowEqual
  );

  const fetchQuestionDetails = useRef(false);

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [payload, setPayload] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const showMenu = user.userId === questionDetails.userId;
  // const showMenu = true;

  useEffect(() => {
    if (fetchQuestionDetails.current) return;
    fetchQuestionDetails.current = true;

    dispatch(getQuestionDetails(questionId));
  }, [questionId]);

  useEffect(() => {
    setComments(questionDetails.comments);
    setPayload({
      topicId: questionDetails.topicId,
      title: questionDetails.title,
      description: questionDetails.description
    });
  }, [questionDetails]);

  const handleAddComment = async () => {
    await dispatch(
      handleNewComment({
        topicId: questionDetails?.topicId,
        userId: user.userId,
        comment: newComment
      })
    );
    window.location.reload();
  };

  const keyPressed = {};
  const handleKeyDown = (event) => {
    keyPressed[event.key] = true;
    if ((keyPressed.Control || keyPressed.Meta) && event.key === 'Enter') handleAddComment();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleMenuItem = (item) => {
    if (item === 'edit') {
      dispatch(setQuestionPopup(true));
    } else {
      dispatch(handleDeleteQuestions({ topicId: questionDetails.topicId }));
      navigate('/discussion');
    }
  };

  const handleChangeQuestion = (event) => {
    const { name, value } = event.target;
    setPayload({
      ...payload,
      [name]: value
    });
  };

  const handleSendQuestion = async () => {
    await dispatch(handleEditQuestions(payload));
    window.location.reload();
    dispatch(setQuestionPopup(false));
  };

  const handleCancelAddQuestion = () => {
    dispatch(setQuestionPopup(false));
    setPayload({});
    setTimeout(() => {
      dispatch(setIsEdit(false));
    }, 500);
  };

  return (
    <Container maxWidth="lg">
      <Loader isLoading={isLoading} />
      <Box my={5}>
        <Button
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => {
            navigate(-1);
          }}
          sx={{ mb: 2 }}
        >
          Kembali
        </Button>
        <Paper variant="outlined" sx={{ borderRadius: '8px' }}>
          <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4" component="h1">
                  {questionDetails?.title}
                </Typography>
                {showMenu ? (
                  <Box>
                    <IconButton
                      id="more-button"
                      onClick={handleOpenMenu}
                      aria-controls={open ? 'more-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Box>
                ) : null}
                <Menu
                  id="more-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuItem('edit');
                      handleCloseMenu();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuItem('delete');
                      handleCloseMenu();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
              <Divider />
              <Box style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                <Typography variant="body2" sx={{ color: '#00000055' }}>
                  Dibuat oleh
                </Typography>
                <Typography variant="body2" color="primary">
                  {questionDetails?.fullName}
                </Typography>
                <Typography variant="body2" sx={{ color: '#00000055' }}>
                  - {moment(questionDetails?.createdAt).fromNow()}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowWrap: 'break-word',
                hyphens: 'auto'
              }}
            >
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {questionDetails?.description}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ background: '#00000004', p: 4 }}>
            <Box mb={4} display="flex" alignItems="center" gap={2}>
              <TextField
                fullWidth
                multiline
                placeholder="Tambahkan komentar"
                color="primary"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ background: '#fff' }}
              />
              <div>
                <IconButton
                  size="large"
                  disabled={newComment === ''}
                  sx={{
                    background: Colors.primary.main,
                    color: Colors.white,
                    transition: 'all 0.4s ease-in-out',
                    '&:hover': {
                      background: Colors.primary.dark
                    },
                    '&:disabled': {
                      border: '1px solid #00000004'
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  onClick={handleAddComment}
                >
                  <SendIcon />
                </IconButton>
              </div>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {comments && comments.map((item) => <CommentItem dataset={item} />)}
            </Box>
          </Box>
        </Paper>
        <AskQuestion
          open={openQuestionPopup}
          onClose={handleCancelAddQuestion}
          onSend={handleSendQuestion}
          onChange={handleChangeQuestion}
          value={payload}
          isEdit
        />
      </Box>
    </Container>
  );
}

export default DiscussionDetails;
