import { serviceDeleteSchool, serviceGetSchools, serviceNewSchools } from 'utils/api';
import { setLocalStorage, removeLocalStorage } from 'utils/helpers/localStorage';

export const SET_ERROR = 'loginAction/SET_ERROR';
export const SET_LOADING = 'loginAction/SET_LOADING';
export const SET_SCHOOL_LIST = 'loginAction/SET_SCHOOL_LIST';

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload
});

export const setSchoolList = (payload) => ({
  type: SET_SCHOOL_LIST,
  payload
});

export const getSchools = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data, status } = await serviceGetSchools();
    if (status === 200) {
      dispatch(setLoading(false));
      dispatch(setSchoolList(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleNewSchool = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceNewSchools(payload);
    if (status === 201) {
      dispatch(setLoading(false));
      window.location.reload();
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};

export const handleDeleteSchool = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { status } = await serviceDeleteSchool({ schoolId: payload });
    if (status === 201) {
      dispatch(setLoading(false));
      window.location.reload();
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setError({
        isError: true,
        message: error
      })
    );
  }
};
