import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material';

function LabelItem({ label, value }) {
  return (
    <Box>
      <Typography variant="subtitle2" component="p" style={{ color: '#aaa' }}>
        {label}
      </Typography>
      <Typography variant="body1" component="p">
        {value}
      </Typography>
    </Box>
  );
}

LabelItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

LabelItem.defaultProps = {
  label: '',
  value: ''
};

function StudentProfile() {
  const { profile } = useSelector((state) => state.users, shallowEqual);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <LabelItem
          label="Nama Lengkap"
          value={`${profile?.firstName} ${
            profile?.lastName === null || profile?.lastName === undefined ? '' : profile?.lastName
          }`}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelItem
          label="Jenis Kelamin"
          value={`${profile?.gender === 'M' ? 'Laki-Laki' : 'Perempuan'}`}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelItem label="Alamat" value={`${profile?.address || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Sekolah" value={`${profile?.school || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Kelas" value={`${profile?.grade || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="No. Absen" value={`${profile?.absentNumber || '-'}`} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Email" value={`${profile?.email || '-'}`} />
      </Grid>
      <Grid item xs={6}>
        <LabelItem label="Username" value={`${profile?.username || '-'}`} />
      </Grid>
    </Grid>
  );
}

export default StudentProfile;
