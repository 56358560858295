import React, { useEffect } from 'react';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Students } from 'assets/images/img/Student.svg';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Colors } from 'utils/theme';
import RegisterForm from './components/RegisterForm';

function RegisterPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const { isRegistered } = useSelector((state) => state.register, shallowEqual);

  useEffect(() => {
    if (isRegistered) navigate('/login');
  }, [isRegistered]);

  return (
    <Box>
      <Grid container height="100vh">
        <Grid
          item
          md={6}
          sx={{ background: Colors.primary.light }}
          style={{
            display: isLarge ? 'none' : 'flex',
            justifyContent: 'center'
          }}
        >
          <Students width="70%" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <Button
            variant="text"
            disableRipple
            startIcon={<KeyboardArrowLeftIcon />}
            sx={{ textTransform: 'inherit' }}
            onClick={() => navigate('/')}
          >
            Kembali ke Halaman Utama
          </Button>
          <RegisterForm />
        </Grid>
      </Grid>
    </Box>
  );
}

export default RegisterPage;
