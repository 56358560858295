import {
  SET_ERROR,
  SET_LOADING,
  SET_REGISTER_USER,
  SET_USER_IS_REGISTERED
} from '../actions/register.actions';

const initialState = {
  error: { isError: false, message: '' },
  isLoading: false,
  registeredUser: {},
  isRegistered: false
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload
      };
    case SET_REGISTER_USER:
      return {
        ...state,
        registeredUser: payload
      };
    case SET_USER_IS_REGISTERED:
      return {
        ...state,
        isRegistered: payload
      };
    default:
      return state;
  }
};
