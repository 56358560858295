import React from 'react';
import { useParams } from 'react-router-dom';

// components
import { Box, Link, Typography } from '@mui/material';

// Assets
import merapiImage from 'assets/images/img/merapi.jpg';
import diagram from 'assets/images/img/stu.jpg';

import YouTubeEmbed from 'pages/Class/components/YouTubeEmbed';
import './style.scss';

function ErupsiGunungMerapi() {
  const { contentId } = useParams();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h4" component="h1">
        Erupsi Gunung Api
      </Typography>
      {contentId === '8' && (
        <Box sx={{ maxWidth: '70%', display: 'flex', gap: '18px', flexDirection: 'column' }}>
          <img src={merapiImage} alt="Gambar Peta" width="100%" />
          <Typography variant="body1" component="p">
            Gunung Merapi merupakan gunung api tipe strato, dengan ketinggian 2.980 meter. Secara
            administratif terletak pada 4 wilayah Kabupaten yaitu Kabupaten Sleman, DIY, dan
            Kabupaten Magelang, Boyolali, dan Klaten Provinsi Jawa Tengah.
          </Typography>
          <Typography variant="body1" component="p">
            Gunung Merapi dikenal sebagai gunung api teraktif di dunia. Karakteristik erupsinya
            bersifat aktif permanen, yaitu guguran kubah lava atau lava pijar, membentuk aliran
            piroklastika (awan panas) atau &apos;nuee ardentes&apos; yang dalam bahasa setempat
            dikenal dengan sebutan “wedhus gembel”. Kejadian ini dapat terjadi setiap saat, baik
            yang dipicu oleh tekanan dari dalam pipa kepundannya ataupun akibat gaya gravitasi yang
            bekerja pada kubah lava yang berada dalam posisi tidak stabil (pada dasar kawah lama
            yang miring) (Pratomo, Indyo, 2006).
          </Typography>
          <Typography variant="body1" component="p">
            Sejarah erupsi Gunung Merapi yang mengakibatkan dampak besar terjadi pada tahun 1957,
            1672, 1768, 1822, 1849, 1872, dan 2010. Pada tahun 2010 erupsi Gunung Merapi
            mengakibatkan dampak korban jiwa sebanyak 277 orang meninggal di wilayah Daerah Istimewa
            Yogyakarta dan 109 orang meninggal di wilayah Jawa Tengah. Erupsi Gunung Merapi juga
            mengakibatkan kerusakan pada rumah, jalan, jembatan, sekolah, dan lain-lain. Jumlah
            rumah rusak berat di Daerah Istimewa Yoyakarta sebanyak 2.682, dan di Jawa Tengah
            sejumlah 174 rumah. Jumlah kerusakan dan kerugian akibat erupsi Gunung Merapi sebesar
            Rp. 3,62 Triliun (BNPB, 2011).
          </Typography>
          <Typography variant="body1" component="p">
            Adik-adik dapat melakukan pengecekan posisi Merapi dengan{' '}
            <Link
              href="https://www.google.com/maps/d/viewer?mid=1x_yr195HxNUSN_yQLTYlhEvErT2V4TXv&ll=-7.623895855856888%2C110.438938&z=11"
              target="_blank"
            >
              klik disini
            </Link>{' '}
            atau melihat peta berikut
          </Typography>
          <figure>
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1x_yr195HxNUSN_yQLTYlhEvErT2V4TXv&ehbc=2E312F"
              width="100%"
              height="480"
              title="Posisi Gunung Merapi"
            />
            <figcaption>
              (Sumber : Cek Posisi Merapi, diinput dalam website SIPENA Februari 2023)
            </figcaption>
          </figure>
        </Box>
      )}

      {contentId === '9' && (
        <YouTubeEmbed
          embedId="3tuZ7Y_TpMw"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 1 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Memasuki tahun 2010 Gunung Merapi mengalami peningkatan aktivitas, sehingga status
                Gunung Merapi ditingkatkan dari waspada menjadi siaga pada tanggal 20 Oktober 2010
                pukul 17.00 WIB. Tingginya kadar CO2 membuktikan adanya kenaikan magma menuju ke
                puncak gunung Merapi. Suara gemuruh, gempa terjadi melebihi 400 x per hari. Pada
                tanggal 25 Oktober 2010 pukul 06.00 WIB status ditingkatkan dari Siaga menjadi Awas.
                Terjadi erupsi Gunung Merapi tanggal 26 Oktober 2010 pukul 17.00 WIB. Semburan gas
                terjadi secara mendatar, dan terjadi hujan abu terutama pada Dusun Kinahrejo
                aktibatnya 39 orang meninggal termasuk juru kunci Merapi “Mbah Marijan”. Tanggal 29
                Oktober 2010 kembali terjadi erupsi, dan erupsi terus meneurs terjadi sampai tanggal
                5 November 2010 merupakan puncak erupsi Gunung Merapi. Radius aman dinaiikan menjadi
                20 km untuk Kabupaten Sleman, 15 km untuk Kabupaten Magelang, dan 10 km untuk
                Kabupaten Boyolali. Akibat adanya isu erupsi akan menjadi lebih besar, jumlah
                pengungsi meningkat dari 200.00 orang menjadi 400.000 orang. 10 sungai menjadi jalur
                aliran lahar yang merusak 22 jembatan dan memutus jalur transportasi Magelang dan
                Yogyakarta.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 1 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 1, adik-adik dapat melanjutkan melihat video 2."
          caption="(Sumber: youtube.com. Kementrian ESDM. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '10' && (
        <YouTubeEmbed
          embedId="GlzpBn0CSOY"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 2 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 2 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Letusan gunung berapi terjadi akibat endapan magma diperut bumi yang didorong keluar
                oleh gas yang bertekanan tinggi. Saat erupsi Gunung berapi akan memuntahkan material
                hingga radius puluhan km. material yang keluar antara lain lava, lahar, gas
                vulkanik, abu, dan awan panas. Material akan berdampak buruk masyakarat di sekitar
                gunung. Saat terjadi erupsi ikuti perintah evakuasi menuju ke lokasi yang aman.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 2 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 2, adik-adik dapat melanjutkan melihat video 3."
          caption="(Sumber: youtube.com. Pusat Krisis Kesehatan Kemenkes. diinput
            dalam website SIPENA November 2022)"
        />
      )}

      {contentId === '11' && (
        <YouTubeEmbed
          embedId="iPZlsZrm5Dw"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 3 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 3 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Indonesia merupakan Negeri Seribu Pulau selain memiliki beragam budaya keindahan
                alam dan hasil bumi, Negeri Kita Tercinta juga kaya akan gunung api. Gunung-gunung
                ini tersebar di sepanjang pulau Sumatera, Jawa, Bali, Sumbawa, Lombok, Flores,
                Sulawesi, hingga Maluku. Rangkaian gunung api yang membentang di sepanjang Tanah Air
                ini dikenal dunia internasional sebagai Ring of fire dengan total gunung berapi
                sebanyak 127 atau 13% dari jumlah gunung api aktif di dunia. Indonesia memiliki
                banyak gunung api karena Indonesia terletak pada pertemuan tiga lempeng tektonik
                besar yaitu Eurasia, Indo-Australia, dan Pasifik. Setiap tahun lempeng
                Indo-Australia dan Pasifik bergeser beberapa senti meter. Pergerakan ini menyebabkan
                magma dapat naik dan membentuk kerucut kerucut gunung api.
              </Typography>
              <Typography variant="body1" component="p">
                Erupsi gunung api tersebut dapat mengakibatkan bencana. Contohnya peristiwa erupsi
                Gunung Tambora di Pulau Sumbawa tepatnya di Kabupaten Bima Nusa Tenggara Barat pada
                April 1815. Erupsi ini merupakan erupsi gunung api terdahsyat yang pernah melanda
                Indonesia dan mengakibatkan lebih dari 92.000 korban jiwa. Gunung Krakatau yang
                berada di Selat Sunda dan meletus pada Agustus 1883. Erupsi itu mengakibatkan
                gelombang tsunami dan mengakibatkan lebih dari 36.000 orang meninggal. Tidak hanya
                dua kasus erupsi gunung api yang terjadi di Indonesia masih banyak contoh erupsi
                gunung api seperti Gunung Merapi, Gunung Kelud dan gunung Sinabung. Material yang
                berbahaya akibat erupsi adalah :
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>
                    Aliran piroklastik atau awan panas masyarakat lokal di kawasan Gunung Merapi
                    sering menyebutnya sebagai wedus gembel. Awan panas dari puncak gunung ke
                    dataran rendah atau lembah ini memiliki kecepatan hingga ratusan kilometer per
                    jam dengan jangkauan aliran mencapai puluhan kilometer, dan suhu udara yang
                    dihembuskan oleh awan panas dapat mencapai ratusan derajat Celcius
                  </li>
                  <li>
                    Lava merupakan bahan vulkanik cair yang panasnya juga mencapai ratusan derajat
                    Celcius dan membuat semua yang dilaluinya akan hangus terbakar.
                  </li>
                  <li>
                    Piroklastik berupa lontaran material vulkanik sampai hujan abu. Jika tidak
                    diantisipasi lontaran material vulkanik ini dapat mengakibatkan korban jiwa,
                    sementara itu hujan abu dapat menimbulkan sakit mata dan infeksi saluran
                    pernapasan, kerusakan lahan, dan mengubah cuaca bagi dunia penerbangan hujan abu
                    ini juga bisa merusak mesin pesawat yang berakibat pada tertundanya jadwal
                    penerbangan.
                  </li>
                  <li>
                    Lahar berupa air lumpur pasir dan kerikil ini akan mengalir kelereng hingga
                    lembah.
                  </li>
                  <li>
                    Gas vulkanik beracun pada umumnya gas beracun yang dikeluarkan antara lain
                    karbon monoksida atau CEO karbondioksida atau CO2 hidrogen sianida atau hcn
                    hidrogen sulfida atau h2s dan belerang dioksida alias so2.
                  </li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Bahaya tidak langsung dari erupsi gunung api adalah lahar hujan. Lahar hujan terjadi
                apabila material hasil erupsi gunung api yang diendapkan di sekitar Puncak dan
                lereng terangkut oleh hujan atau air permukaan melalui lembah atau Sungai aliran
                lahar berupa lumpur yang sangat pekat ini dapat menyapu apa saja yang dilaluinya.
                Banyak faktor yang bisa mengancam keselamatan jiwa saat terjadi erupsi gunungapi.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 3 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 3, adik-adik dapat melanjutkan melihat video 4."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '12' && (
        <YouTubeEmbed
          embedId="6lshF9ZjOhQ"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 4 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 4 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Tipe letusan Gunung Api dibagi menjadi Fase membangun dan fase penghancuran yang
                terdiri dari Tipe Hawai, Tipe Sromboli, Tipe Volkano, Tipe Saint Vincent.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 4 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 4, adik-adik dapat melanjutkan melihat video 5."
          caption="(Sumber: youtube.com. MAIPARK Learning. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '13' && (
        <YouTubeEmbed
          embedId="UcbENUL6G9o"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 5 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 5 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Berikut ini Tingkat Status Erupsi Gunung Api:
                <table className="gunung-api">
                  <caption>(BNPB, 2019)</caption>
                  <thead>
                    <tr>
                      <th>Tingkat Status (Level)</th>
                      <th>Istilah dalam Bahasa</th>
                      <th>Penjelasan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="awas">
                      <td>IV</td>
                      <td>Awas</td>
                      <td>
                        Tingkatan yang menunjukkan jelang letusan utama, letusan awal mulai terjadi
                        berupa abu atau asap. Berdasarkan analisis data pengamatan, segera akan
                        diikuti letusan utama.
                      </td>
                    </tr>
                    <tr className="siaga">
                      <td>III</td>
                      <td>Siaga</td>
                      <td>
                        Peningkatan semakin nyata hasil pengamatan visual atau pemeriksaan kawah,
                        kegempaan dan metode lain saling mendukung. Berdasarkan analisis, perubahan
                        kegiatan enderung diikuti letusan.
                      </td>
                    </tr>
                    <tr className="waspada">
                      <td>II</td>
                      <td>Waspada</td>
                      <td>
                        Peningkatan kegiatan berupa kelainan yang tampak secara visual atau hasil
                        pemeriksaan kawah, kegempaan dan gejala vulkanik lain.
                      </td>
                    </tr>
                    <tr className="normal">
                      <td>I</td>
                      <td>Normal</td>
                      <td>
                        Aktivitas gunungapi, berdasarkan pengamatan hasil visual, kegempaan, dan
                        gejala vulkanik lain, tidak memperlihatkan adanya kelainan.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
              <Typography variant="body1" component="p">
                Berikut ini Kawasan Rawan Bencana (KRB) Erupsi Gunung Api :
                <table className="gunung-api">
                  <caption>(BNPB, 2019)</caption>
                  <thead>
                    <tr>
                      <th style={{ width: '15%' }}>Tingkat</th>
                      <th>Penjelasan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="awas">
                      <td>KRB III</td>
                      <td>
                        KRB III adalah kawasan yang sangat berpotensi terlanda awan panas, aliran
                        lava, guguran lava, lontaran batu (pijar), dan/atau gas beracun. Kawasan ini
                        meliputi daerah puncak dan sekitar
                      </td>
                    </tr>
                    <tr className="siaga">
                      <td>KRB II</td>
                      <td>
                        KRB II adalah kawasan yang berpotensi terlanda awan panas, aliran lava,
                        lontaran batu (pijar) dan/atau guguran lava, hujan abu lebat, hujan lumpur
                        panas, aliran lahar, dan gas beracun. Kawasan ini dibedakan menjadi dua,
                        yaitu:
                        <ol style={{ listStylePosition: 'outside' }}>
                          <li>
                            Kawasan rawan terhadap awan panas, aliran lava, guguran lava, aliran
                            lahar, dan gas beracun terutama daerah hulu.
                          </li>
                          <li>
                            Kawasan rawan terhadap hujan abu lebat, lontaran batu (pijar) dan/atau
                            hujan lumpur panas.
                          </li>
                        </ol>
                      </td>
                    </tr>
                    <tr className="waspada">
                      <td>KRB I</td>
                      <td>
                        KRB I adalah kawasan yang berpotensi terlanda lahar, tertimpa material
                        jatuhan berupa hujan abu, dan/atau air dengan keasaman tinggi. Apabila
                        letusan membesar, kawasan ini berpotensi terlanda perluasan awan panas dan
                        tertimpa material jatuhan berupa hujan abu lebat, serta lontaran batu
                        (pijar).
                        <br />
                        Kawasan ini dibedakan menjadi dua, yaitu:
                        <ol style={{ listStylePosition: 'outside' }}>
                          <li>
                            Kawasan rawan terhadap lahar. Kawasan ini terletak di sepanjang lembah
                            dan bantaran sungai, terutama yang berhulu di daerah puncak.
                          </li>
                          <li>
                            Kawasan rawan terhadap hujan abu tanpa memperhitungkan arah tiupan
                            angin.
                          </li>
                        </ol>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 5 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 5, adik-adik dapat melanjutkan melihat video 6."
          caption="(Sumber: youtube.com. IFmagine. diinput dalam website SIPENA
            November 2022)"
        />
      )}

      {contentId === '14' && (
        <YouTubeEmbed
          embedId="y0FVhINZAaI"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 6 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 6 erupsi gunung api, baca baik-baik materi berikut ini ya!
                <br />
                <br />
                Proses Erupsi Gunung Api Baru
              </Typography>
              <Typography variant="body1" component="p">
                Magma mencari jalan untuk keluar kemudian akan muncul gunung-gunung baru. Pergerakan
                antar lempeng akan menimbulkan empat busur gunungapi berbeda :
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>
                    Pemekaran kerak benua, lempeng bergerak saling menjauh sehingga memberikan
                    kesempatan magma bergerak ke permukaan, kemudian membentuk busur gunungapi
                    tengah samudera.
                  </li>
                  <li>
                    Tumbukan antar kerak, dimana kerak samudera menunjam di bawah kerak benua.
                    Akibat gesekan antar kerak tersebut terjadi peleburan batuan dan lelehan batuan
                    ini bergerak ke permukaan melalui rekahan kemudian membentuk busur gunungapi di
                    tepi benua.
                  </li>
                  <li>
                    Kerak benua menjauh satu sama lain secara horizontal, sehingga menimbulkan
                    rekahan atau patahan. Patahan atau rekahan tersebut menjadi jalan ke permukaan
                    lelehan batuan atau magma sehingga membentuk busur gunungapi tengah benua atau
                    banjir lava sepanjang rekahan.
                  </li>
                  <li>
                    Penipisan kerak samudera akibat pergerakan lempeng memberikan kesempatan bagi
                    magma menerobos ke dasar samudera, terobosan magma ini merupakan banjir lava
                    yang membentuk deretan gunungapi perisai.
                  </li>
                </ol>
              </Typography>
              <img alt="Diagram" src={diagram} width="50%" />
              <Typography variant="body1" component="p">
                <i>
                  Penampang diagram yang memper lihatkan bagaimana gunungapi te bentuk di permukaan
                  melalui kerak benua dan kerak samudera serta mekanisme peleburan batuan yang
                  menghasilkan busur gunungapi, busur gunungapi tengah samudera, busur gunungapi
                  tengah benua dan busur gunungapi dasar samudera.
                </i>
                <br />
                (Kementrian ESDM, 2016)
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 6 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 6, adik-adik dapat melanjutkan melihat video 7."
          caption="(Sumber: youtube.com. 3 STY. diinput dalam website SIPENA
            November 2022)"
        />
      )}

      {contentId === '15' && (
        <YouTubeEmbed
          embedId="6IOzoKFU7-Q"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 7 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 7 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Tanda-tanda erupsi Gunung Api:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Terdengar suara gemuruh</li>
                  <li>Tercium bau belerang</li>
                  <li>Terlhat titik api di puncak gunung</li>
                  <li>Perubahan warna asap dan kawah danau di Gunung Api</li>
                  <li>Terjadi hujan abu</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Jika sudah ada tanda-tanda maka harus tetap tenang dan mengikuti instruksi petugas
                untuk menyelamatkan diri ke tempat yang aman.
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 7 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText="Setelah selesai melihat, mengamati, dan memperhatikan video 7, adik-adik dapat melanjutkan melihat video 8."
          caption="(Sumber: youtube.com. BNPB Indonesia. diinput dalam website
            SIPENA November 2022)"
        />
      )}

      {contentId === '16' && (
        <YouTubeEmbed
          embedId="TN0oEVqAgcU"
          // preVideoText="Mari kita melihat, mengamati, dan memperhatikan  video 8 di bawah ini dengan cermat ya!!"
          preVideoText={
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
              <Typography variant="h6" component="p">
                Sebelum melihat video 8 erupsi gunung api, baca baik-baik materi berikut ini ya!
              </Typography>
              <Typography variant="body1" component="p">
                Kawasan Rawan Bencana (KRB) - KRB 1, KRB 2, dan KRB 3. <br />
                Tingkat Status Erupsi Gunung Api adalah:
                <ol
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    listStylePosition: 'outside',
                    marginLeft: '32px'
                  }}
                >
                  <li>Tingkat Normal</li>
                  <li>Tingkat Waspada</li>
                  <li>Tingkat Siaga</li>
                  <li>Tingkat Awas</li>
                </ol>
              </Typography>
              <Typography variant="body1" component="p">
                Mari kita melihat, mengamati, dan memperhatikan video 8 di bawah ini dengan cermat
                ya!!
              </Typography>
            </Box>
          }
          postVideoText={
            <Typography variant="body1" component="p">
              Setelah selesai melihat, mengamati, dan memperhatikan video 1 sampai dengan 8 Erupsi
              Gunung Api, tuliskan kesimpulan pada buku catatanmu!
              <ol>
                <li>Erupsi Gunung Api adalah</li>
                <li>Tanda-tanda erupsi gunung api antara lain :</li>
              </ol>
            </Typography>
          }
          caption="(Sumber: youtube.com. Balai Pengembangan Pendidikan Anak Usia
            Dini. diinput dalam website SIPENA November 2022)"
        />
      )}
    </Box>
  );
}

export default ErupsiGunungMerapi;
