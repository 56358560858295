import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material';
import { Text } from 'components/atomics';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { useNavigate, Link } from 'react-router-dom';

// Assets
// import profileImage from 'assets/images/img/man1.jpg';
import logoSipena from 'assets/logo-sipena.svg';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Colors } from 'utils/theme';

function Appbar({ onLogout, children, elevation }) {
  const navigate = useNavigate();
  const user = getLocalStorage('userLogin');

  const [anchorEl, setAnchorEll] = useState(null);
  const open = Boolean(anchorEl);

  const handleAccountClick = (event) => {
    setAnchorEll(event.currentTarget);
  };

  const handleCloseAccount = () => {
    setAnchorEll(null);
  };

  const handleMenuItem = (menuName) => {
    if (menuName === 'profile') {
      navigate('profile');
    } else if (menuName === 'settings') {
      navigate('settings');
    } else if (menuName === 'logout') {
      onLogout();
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color="inherit" elevation={elevation}>
        <Container>
          <Toolbar>
            <Link
              to={user ? '/home' : '/'}
              style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}
            >
              <Box flexGrow={1} display="flex" gap="10px">
                <img src={logoSipena} alt="Logo SIPENA" loading="lazy" width={32} />
                <Typography variant="h5">SIPENA</Typography>
              </Box>
            </Link>
            <Box flexGrow={0} sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {user && user.role === 'guru' && (
                <Link
                  to="curriculum"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                >
                  <Text
                    variant="body2"
                    sx={{
                      '&:hover': {
                        color: Colors.primary.main
                      }
                    }}
                  >
                    Kurikulum
                  </Text>
                </Link>
              )}
              <Link
                to="discussion"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  margin: '0px 16px'
                }}
              >
                <Text
                  variant="body2"
                  sx={{
                    '&:hover': {
                      color: Colors.primary.main
                    }
                  }}
                >
                  Diskusi
                </Text>
              </Link>
              {/* <IconButton size="large" color="inherit">
                <Badge badgeContent={4} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                aria-controls={open ? 'account-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                color="inherit"
                sx={{ padding: 0 }}
                onClick={handleAccountClick}
              >
                <Avatar alt="Profile Image" />
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseAccount}
              onClick={handleCloseAccount}
              MenuListProps={{ onMouseLeave: handleCloseAccount }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
            >
              <MenuItem onClick={() => handleMenuItem('profile')}>
                <ListItemIcon>
                  <Avatar alt="Profile Image" />
                </ListItemIcon>
                Profil
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => handleMenuItem('settings')}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Pengaturan
              </MenuItem>
              <MenuItem onClick={() => handleMenuItem('logout')}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                Keluar
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </Box>
  );
}

Appbar.propTypes = {
  onLogout: PropTypes.func,
  elevation: PropTypes.number,
  children: PropTypes.node
};

Appbar.defaultProps = {
  onLogout: () => {},
  elevation: 2,
  children: null
};

export default Appbar;
