export const studentMenus = [
  {
    id: '1',
    title: 'Pre-Test',
    completed: true
  },
  {
    id: '2',
    title: 'Lagu-lagu Kebencanaan',
    completed: true
  },
  {
    id: '3',
    title: 'Pengertian Bencana',
    completed: true
  },
  {
    id: '4',
    title: 'Video 1',
    completed: true
  },
  {
    id: '5',
    title: 'Video 2',
    completed: true
  },
  {
    id: '6',
    title: 'Video 3',
    completed: true
  },
  {
    id: '7',
    title: 'Jenis Bencana',
    completed: true
  },
  {
    id: '8',
    title: 'Erupsi Gunung Api',
    completed: true
  },
  {
    id: '9',
    title: 'Video 1',
    completed: true
  },
  {
    id: '10',
    title: 'Video 2',
    completed: true
  },
  {
    id: '11',
    title: 'Video 3',
    completed: true
  },
  {
    id: '12',
    title: 'Video 4',
    completed: true
  },
  {
    id: '13',
    title: 'Video 5',
    completed: true
  },
  {
    id: '14',
    title: 'Video 6',
    completed: true
  },
  {
    id: '15',
    title: 'Video 7',
    completed: true
  },
  {
    id: '16',
    title: 'Video 8',
    completed: true
  },
  {
    id: '17',
    title: 'Sistem Peringatan Dini',
    completed: true
  },
  {
    id: '18',
    title: 'Video 1',
    completed: true
  },
  {
    id: '19',
    title: 'Video 2',
    completed: true
  },
  {
    id: '20',
    title: 'Video 3',
    completed: true
  },
  {
    id: '21',
    title: 'Rencana Evakuasi',
    completed: true
  },
  {
    id: '22',
    title: 'Video 1',
    completed: true
  },
  {
    id: '23',
    title: 'Prosedur Tetap',
    completed: true
  },
  {
    id: '24',
    title: 'Video 1',
    completed: true
  },
  {
    id: '25',
    title: 'Video 2',
    completed: true
  },
  {
    id: '26',
    title: 'Video 3',
    completed: true
  },
  {
    id: '27',
    title: 'Video 4',
    completed: true
  },
  {
    id: '28',
    title: 'Video 5',
    completed: true
  },
  {
    id: '29',
    title: 'Post-Test',
    completed: true
  }
];

export const teacherMenus = [
  {
    id: '1',
    title: 'Pre-Test',
    completed: true
  },
  {
    id: '2',
    title: 'Pengertian Bencana',
    completed: true
  },
  {
    id: '3',
    title: 'Video 1',
    completed: true
  },
  {
    id: '4',
    title: 'Video 2',
    completed: true
  },
  {
    id: '5',
    title: 'Video 3',
    completed: true
  },
  {
    id: '6',
    title: 'Jenis Bencana',
    completed: true
  },
  {
    id: '7',
    title: 'Video 1',
    completed: true
  },
  {
    id: '8',
    title: 'Jenis Ancaman DIY',
    completed: true
  },
  {
    id: '9',
    title: 'Video 1',
    completed: true
  },
  {
    id: '10',
    title: 'Video 2',
    completed: true
  },
  {
    id: '11',
    title: 'Video 3',
    completed: true
  },
  {
    id: '12',
    title: 'Erupsi Gunung Api',
    completed: true
  },
  {
    id: '13',
    title: 'Video 1',
    completed: true
  },
  {
    id: '14',
    title: 'Video 2',
    completed: true
  },
  {
    id: '15',
    title: 'Video 3',
    completed: true
  },
  {
    id: '16',
    title: 'Sistem Peringatan Dini',
    completed: true
  },
  {
    id: '17',
    title: 'Video 1',
    completed: true
  },
  {
    id: '18',
    title: 'Video 2',
    completed: true
  },
  {
    id: '19',
    title: 'Video 3',
    completed: true
  },
  {
    id: '20',
    title: 'Rencana Evakuasi',
    completed: true
  },
  {
    id: '21',
    title: 'Video 1',
    completed: true
  },
  {
    id: '22',
    title: 'Prosedur Tetap',
    completed: true
  },
  {
    id: '23',
    title: 'Video 1',
    completed: true
  },
  {
    id: '24',
    title: 'Video 2',
    completed: true
  },
  {
    id: '25',
    title: 'Video 3',
    completed: true
  },
  {
    id: '26',
    title: 'Video 4',
    completed: true
  },
  {
    id: '27',
    title: 'Video 5',
    completed: true
  },
  {
    id: '28',
    title: 'Video 6',
    completed: true
  },
  {
    id: '29',
    title: 'Video 7',
    completed: true
  },
  {
    id: '30',
    title: 'Video 8',
    completed: true
  },
  {
    id: '31',
    title: 'Post-Test',
    completed: true
  }
];
