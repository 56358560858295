/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Colors from 'utils/theme/colors';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { Footer } from 'components/organisms';
import Hero from './components/Hero';
import AppBar from './components/AppBar';
import About from './components/About';
import FAQ from './components/FAQ';
import Features from './components/Features';

function Landing() {
  const user = getLocalStorage('userLogin');
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const aboutRef = useRef(null);

  useEffect(() => {
    document.title = 'SIPENA - Siaga Pendidikan Bencana';
    if (user) {
      setIsAuthenticate(true);
    } else {
      setIsAuthenticate(false);
    }
  }, [user]);

  const handleClickMenu = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Box sx={{ background: Colors.white }}>
      <AppBar user={isAuthenticate} clickMenu={handleClickMenu} />
      <Hero user={isAuthenticate} />
      <div ref={aboutRef}>
        <About />
      </div>
      <Features />
      <FAQ />
      <Footer isPublic />
    </Box>
  );
}

export default Landing;
