import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  Typography
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { Link, useNavigate } from 'react-router-dom';
import { Colors } from 'utils/theme';
import { getLocalStorage } from 'utils/helpers/localStorage';
import { AppbarContainer } from './StyledComponent';

function AppBarMobile({ clickMenu }) {
  const navigate = useNavigate();
  const user = getLocalStorage('userLogin');
  const [offset, setOffset] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = React.useState(true);

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleList = (path) => () => {
    switch (path) {
      case 'login':
        return navigate('/login');
      case 'register':
        return navigate('/register');
      default:
        return '';
    }
  };

  return (
    <AppBar
      component="nav"
      sx={{ background: offset > 88 ? 'white' : 'transparent' }}
      elevation={offset > 88 ? 2 : 0}
    >
      <AppbarContainer>
        <Link to="/" style={{ textDecoration: 'none', marginLeft: '1rem' }}>
          <Typography variant="h6" sx={{ color: Colors.primary.main }}>
            SIPENA
          </Typography>
        </Link>
        <IconButton size="large" onClick={toggleDrawer(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{ width: 300 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '120px'
              }}
            >
              <Typography variant="h4" sx={{ color: Colors.primary.main }}>
                SIPENA
              </Typography>
            </Box>
            <List>
              <ListItemButton onClick={clickMenu}>
                <ListItemText primary="Tentang" />
              </ListItemButton>
              <ListItemButton onClick={handleClick}>
                <ListItemText primary="Panduan" />
                {openMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    href="https://drive.google.com/drive/u/5/folders/1oTG4eLiLr9t6mEF4cm4Ttu0do-djhybf"
                    target="_blank"
                  >
                    <ListItemText primary="Panduan SIPENA Guru" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    href="https://drive.google.com/drive/u/5/folders/13OkNuM2GkvJ9RoRtj9M4KMtzwImJa8Ti"
                    target="_blank"
                  >
                    <ListItemText primary="Panduan SIPENA Siswa" />
                  </ListItemButton>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    href="https://youtu.be/mVX0yOZ1ctc"
                    target="_blank"
                  >
                    <ListItemText primary="Panduan SIPENA (Video)" />
                  </ListItemButton>
                </List>
              </Collapse>
              {!user ? (
                <>
                  <ListItem sx={{ mt: 4 }} onClick={handleList('login')}>
                    <ListItemButton
                      sx={{
                        background: Colors.primary.main,
                        color: Colors.white,
                        borderRadius: '8px',
                        '&:hover': { background: Colors.primary.dark }
                      }}
                    >
                      <ListItemText primary="Login" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem onClick={handleList('register')}>
                    <ListItemButton
                      sx={{
                        background: Colors.primary.main,
                        color: Colors.white,
                        borderRadius: '8px',
                        '&:hover': { background: Colors.primary.dark }
                      }}
                    >
                      <ListItemText primary="Daftar" />
                    </ListItemButton>
                  </ListItem>
                </>
              ) : (
                <ListItem sx={{ mb: 1, mt: 5 }} onClick={handleList('home')}>
                  <ListItemButton
                    sx={{
                      background: Colors.primary.main,
                      color: Colors.white,
                      borderRadius: '8px',
                      '&:hover': { background: Colors.primary.dark }
                    }}
                  >
                    <ListItemText primary="Beranda" />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Box>
        </SwipeableDrawer>
      </AppbarContainer>
    </AppBar>
  );
}

AppBarMobile.propTypes = {
  clickMenu: PropTypes.func.isRequired
};

export default AppBarMobile;
